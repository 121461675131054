import React, { FC, useState, SetStateAction } from 'react';
import AuthProps from '../common/AuthProps';
import { Member } from '../../store/memberSlice';
import { selectMemberStatusError, clearError, 
    MemberStatus, MemberStatusParams, editMemberStatus} from '../../store/memberStatusSlice';
import { grayedOutButtonClass, enabledButtonClass } from '../../shared/Utils';
import { useAppSelector, useAppDispatch } from '../common/hooks';
import Select from '../common/Select';
import useToast from '../../hooks/useToast';

interface Props {
    authProps: AuthProps;
    setShowEditStatus: React.Dispatch<SetStateAction<boolean>>;
    member: Member;
}

const EditMemberStatus: FC<Props> = ({ authProps,
    setShowEditStatus, member }) => {

    const toast = useToast();
    const dispatch = useAppDispatch();

    const memberStatusError = useAppSelector(selectMemberStatusError);
    const [status, setStatus] = useState<string>("");

    const statuses = [
        {'id':'ACTIVE', 'name':'ACTIVE'},
        {'id':'INACTIVE', 'name':'INACTIVE'},
        {'id':'WITHDRAWING', 'name':'WITHDRAWING'},
        {'id':'CEASED_BY_DEATH', 'name':'CEASED BY DEATH'},
        {'id':'CEASED_BY_WITHDRAWAL_OF_SHARES', 'name':'CEASED BY WITHDRAWAL OF SHARES'},
        {'id':'CEASED_BY_TRANSFER_OF_SHARES', 'name':'CEASED BY TRANSFER OF SHARES'},
        {'id':'CEASED_BY_BY_LAWS', 'name':'CEASED BY BY-LAWS'},
        {'id':'CEASED_BY_UNSOUND_MIND', 'name':'CEASED BY UNSOUND MIND'},
        {'id':'EXPELLED_BY_MANAGEMENT', 'name':'EXPELLED BY MANAGEMENT'},
        {'id':'EXPELLED_BY_GENERAL_MEETING', 'name':'EXPELLED BY GENERAL MEETING'},
        {'id':'UNREGISTERED', 'name':'UNREGISTERED'},
        {'id':'UNKNOWN', 'name':'UNKNOWN'},
    ];

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowEditStatus(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updated: MemberStatus = {
            memberId: member.id,
            memberStatus: status,
        }
        const params: MemberStatusParams = {
            authProps: authProps,
            memberStatus: updated,
        }
        //console.log(JSON.stringify(params.memberStatus))
        
        const result = await dispatch(editMemberStatus(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            toast('success', 'Member status changed successfully');
            setShowEditStatus(false);
        }

    }

    const isInvalid =
        status === "";

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                 {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">

                    <div className="sm:flex sm:items-start">
                        <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                            <div className="max-w-md w-full space-y-4">
                                <h2 className="text-center text-1xl font-normal text-gray-900">
                                    {`Change Status for: ${member.surname}, ${member.firstName} ${member.middleName}`}
                                </h2>

                                <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                    <div className="rounded-md shadow-sm -space-y-px">

                                        <span
                                            className="text-red-500"
                                            style={{ display: memberStatusError ? "block" : "none" }}>
                                            {memberStatusError}
                                        </span>

                                        <Select
                                            id="statusId"
                                            placeHolder="Select Status"
                                            className="shadow-md border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            selectList={statuses?.map(status => {
                                                return { id: status.id, name: status.name }})}
                                            required={true}
                                            onChange={(e) => {
                                                dispatch(clearError(null));
                                                setStatus(e.target.value);
                                            }} />
                                    </div>

                                    <div className="flex items-center justify-between">
                                        <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="flex items-center">
                                                    Change
                                                </span>
                                            </button>
                                        </div>
                                        <div className="text-sm">
                                            <button
                                                type="submit"
                                                onClick={cancel}
                                                className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>     
    );
};

export default EditMemberStatus;