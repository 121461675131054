import { LoanStatusReason } from './loanStatusReasonSlice';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { Category } from './categorySlice';
import { Member } from './memberSlice';

export type LoanParams = {
    authProps: AuthProps;
    loan?: Loan;
    loanId?: string;
    memberId?: string;
}

export type Schedule = {
    id: string;
    calculatorId: string;
    dueDate: string;
    payment: number;
    interest: number;
    principal: number;
    balance: number;
}

export type Calculator = {
    id: string;
    memberId: string;
    amount: number;
    repaymentPeriod: number;
    firstPaymentDate: string;
    paymentFrequency: string;
    creationDate: string;
    schedule: Schedule[];
}

export type Guarantor = {
    loanId: string;
    guarantorId: string;
    guarantor?: Member;
    amount: number;
    creationDate?: string;
    modifiedDate?: string;
    edit?: void;
    delete?: void;
}

export type Loan = {
    id: string;
    ref: string;
    name: string;
    memberId: string;
    member?: Member;
    categoryId: string;
    category?: Category;
    explanation?: string;
    amount: number;
    repaymentPeriod: number;
    interestChargeable: number;
    creditLifeInsurance: number;
    adminFee: number;
    loanStatusReasonId: string;
    loanStatusReason?: LoanStatusReason;
    calculatorId?: string;
    guarantors?: Guarantor[];
    schedule?: Schedule[];
    accountId: string;
    makerId: string;
    creationDate?: string;
    modifiedDate?: string;
    edit?: void;
    delete?: void;
}

interface LoanState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: Loan[];
}

interface FetchErrors {
    message: string;
}

const initialState: LoanState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchLoans = 
    createAsyncThunk<Loan[], AuthProps, { rejectValue: FetchErrors }>(
    "loans/fetchByAccount",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.LOANS_URL +
                "/loans/" + params.config.ACCOUNT_ID, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch loans",
            });
        }
        return response.json();
    }
);

export const fetchMemberLoans = 
    createAsyncThunk<Loan[], LoanParams, { rejectValue: FetchErrors }>(
    "loans/fetchByMember",
    async (params: LoanParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.MEMBERLOAN_URL +
                "/" + params.memberId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member loans",
            });
        }
        return response.json();
    }
);

export const addLoan =
    createAsyncThunk<Loan, LoanParams, { rejectValue: FetchErrors }>(
    "loan/add",
    async (params: LoanParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.LOAN_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.loan),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editLoan =
    createAsyncThunk<Loan, LoanParams, { rejectValue: FetchErrors }>(
    "loan/edit",
    async (params: LoanParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.LOAN_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.loan),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editLoanStatus =
    createAsyncThunk<Loan, LoanParams, { rejectValue: FetchErrors }>(
    "loan/editStatus",
    async (params: LoanParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.LOAN_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.loan),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteLoan =
    createAsyncThunk<Loan, LoanParams, { rejectValue: FetchErrors }>(
    "loan/delete",
    async (params: LoanParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.LOAN_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.loan),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const loanSlice = createSlice({
    name: 'loan',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearLoanError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchLoans.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(fetchMemberLoans.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addLoan.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editLoan.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(editLoanStatus.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteLoan.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchLoans.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(fetchMemberLoans.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addLoan.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editLoan.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(editLoanStatus.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteLoan.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchLoans.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(fetchMemberLoans.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addLoan.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editLoan.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editLoanStatus.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteLoan.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectLoanStatus = (state: RootState) => state.loans.status;
export const selectLoans = (state: RootState) => state.loans.list;
export const selectLoanError = (state: RootState) => state.loans.error;
export const { clearList, clearLoanError } = loanSlice.actions;
export default loanSlice.reducer;