import React, { FC, useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { Loan, fetchLoans, selectLoanError, selectLoans, 
    selectLoanStatus, clearLoanError, deleteLoan, LoanParams } from '../../../store/loanSlice';
import AuthProps from '../../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import AddEditLoan from './AddEditRegularLoan';
import { ccyFormatFormal } from '../../../shared/Utils';

const RegularLoanPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const loans = useAppSelector(selectLoans);
    const status = useAppSelector(selectLoanStatus);
    const loanError = useAppSelector(selectLoanError);

    const [loan, setLoan] = useState<Loan>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchLoans(authProps));
        return () => controller.abort();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearLoanError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setLoan(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, loan: Loan) => {
    	e.preventDefault();
        dispatch(clearLoanError(null));
    	setLoan(loan);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, loan: Loan) => {
		e.preventDefault();
        dispatch(clearLoanError(null));
    	setLoan(loan);
        console.log(`loan ${JSON.stringify(loan)}`);
    	const params: LoanParams = {
        	authProps: authProps,
        	loan: loan,
      	}
    	dispatch(deleteLoan(params))
 	};

    const totalAmount: any = (items: Loan[]) => {
        return items.map( ({ amount }) => Number(amount))
            .reduce((sum, i) => sum + i, 0) || 0;
    }

    const columns: Column<Loan>[] = useMemo(() => [
        {
            Header: "Created",
            accessor: "creationDate"
        },
        {
            Header: "Ref",
            accessor: "ref"
        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Member",
            accessor: mem => { return (`${mem.member.firstName} ${mem.member.middleName} ${mem.member.surname}`) }
        },
        {
            Header: "Type",
            accessor: type => { return (type.category.loanType.name) }
        },
        {
            Header: "Category",
            accessor: cat => { return (`${cat.category.name} ${cat.explanation ? cat.explanation : ""}`)}
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Amount
                </div>
            ),
            accessor: "amount",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        <div className="font-bold">
                            {ccyFormatFormal(Number(row.original.amount), 2)}
                        </div>
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    {ccyFormatFormal(totalAmount(columnProps.data), 2)}
                </div>
            ),
        },
        {
            Header: "Months",
            accessor: "repaymentPeriod"
        },
        {
            Header: "Status",
            accessor: stat => { return (stat.loanStatusReason.loanStatus.name) }
        },
        {
            Header: "Reason",
            accessor: res => { return (res.loanStatusReason.reason) }
        },
        {
            Header: "",
            accessor: "edit",
            Cell: ({ row }) => {
                return (
                    <div className="items-center margin: auto">
                        {authProps.auth.token.features?.map(a => a.id).includes('5uero9dc0b') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>}
                    </div>
                );
            }

        },
        {
            Header: "",
            accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('j0s9mevlf8') &&
                        <button
                            onClick={e => inLineDelete(e, row.original)}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                            </svg>
                        </button>}
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[loans]);

    return status === "loading" ? (<div className="pl-2">Loading...</div>) :
        (
            <React.Fragment>
                {loanError &&
                <span
                    className="text-red-500"
                    style={{ display: loanError ? "block" : "none" }}>
                    {loanError}
                </span>}
                <div>
                    {loans &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<Loan>
                                    name="Loans"
                                    columns={columns}
                                    data={loans}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('poh0git7sh')}
                                /> 
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<Loan>
                                    name="Loans"
                                    columns={columns}
                                    data={loans}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('poh0git7sh')}
                                />
                            </div>
                        </React.Fragment>}
                </div>
                <div>
                    {showAddEdit &&
                        <AddEditLoan 
                            authProps={authProps} 
                            setShowAddEdit={setShowAddEdit}
                            loan={loan}
                        />}
                </div>
            </React.Fragment>
    );
}

export default RegularLoanPage;