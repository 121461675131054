import React, { FC, SetStateAction, useEffect, useState } from 'react';
import AuthProps from '../AuthProps';
import { useAppSelector, useAppDispatch } from '../hooks';
import Select from '../../common/Select';
import { selectMembers, fetchAllMembers, fetchAllMembers2} from '../../../store/memberSlice';


interface Props {
    authProps: AuthProps;
    setMemberId: React.Dispatch<SetStateAction<string>>;
    memberId: string;
    setMemberName?: React.Dispatch<SetStateAction<string>>;
}

const Member: FC<Props> = ({ authProps, setMemberId, memberId, setMemberName }) => {

    const dispatch = useAppDispatch();

    const preSelectMember = [{'id':'1111111111','name':'Select member'}];
    const members = useAppSelector(selectMembers);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchAllMembers2(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div className="grid grid-cols-3 w-full">

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="memberId">
                Member
            </label>
            
            <div className="col-span-2">
                <Select
                    id="memberId"
                    className="p-1"
                    placeHolder={"Select"}
                    selectList={
                        preSelectMember.map(select => {
                            return {id: select.id, name: select.name}
                        }).concat(
                            members.map(member => {
                                return { id: member.id, name: `${member.firstName} ${member.middleName} ${member.surname} (${member.saccoId})`}
                    }))}
                    required={true}
                    onChange={e => {
                        setMemberId(e.target.value);
                        var member = members.find( ({ id }) => id === e.target.value);
                        setMemberName(`${member.firstName} ${member.middleName} ${member.surname}`);
                    }} />
            </div>

        </div>
    );
}

export default Member;