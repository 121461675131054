import React, { FC, useEffect, SetStateAction } from 'react'
import { fetchAllMembers, selectMembers } from '../../store/memberSlice';
import AuthProps from '../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import Select from '../common/Select';

interface Props {
    authProps: AuthProps;
    setMemberId: React.Dispatch<SetStateAction<string>>;
}

const ParamMemberId: FC<Props> = ({ authProps, setMemberId }) => {

    const dispatch = useAppDispatch();

    const members = useAppSelector(selectMembers);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchAllMembers(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div>
            <div className="relative">
                <Select
                    id="sectionId"
                    placeHolder="Select Member"
                    className="shadow-sm border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    selectList={
                        members.map(member => {
                            return {id: member.id, name: `${member.surname} ${member.firstName}, ${member.middleName} ${member.saccoId}`}
                        })}
                    required={true}
                    onChange={e => {
                        setMemberId(e.target.value);
                    }} />
            </div>
        </div>
    );
}

export default ParamMemberId;