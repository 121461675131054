import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type Role = {
    role: string;
}

interface RoleState {
    status: "loading" | "idle";
    error: string | null;
    list: Role[];
}

interface FetchErrors {
    message: string;
}

const initialState: RoleState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchRoles = 
    createAsyncThunk<Role[], AuthProps, { rejectValue: FetchErrors }>(
    "role/fetch",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.SYSTEM_ROLES_URL, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch roles",
            });
        }
        return response.json();
    }
)

export const roleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchRoles.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchRoles.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchRoles.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectRoleStatus = (state: RootState) => state.roles.status;
export const selectRoles = (state: RootState) => state.roles.list;
export const selectRoleError = (state: RootState) => state.roles.error;
export const { clearError } = roleSlice.actions;
export default roleSlice.reducer;