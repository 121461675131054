import React, { FC, useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import AuthProps from '../common/AuthProps';
import { selectAccountStatus, selectAccounts, selectAccountError, 
    fetchAccounts, Account, clearError, AccountParams,
    deleteAccount } from '../../store/accountSlice'; 
import { Column } from 'react-table';
import { WrappedTable } from '../common/Table/WrappedTable';
import IconButton from '../../shared/confirm/IconButton';
import AddEditAccount from './AddEditAccount';
import ConfirmDialog from '../../shared/confirm/Confirm';

const AccountPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const status = useAppSelector(selectAccountStatus);
    const accounts = useAppSelector(selectAccounts);
    const accountError = useAppSelector(selectAccountError);

    const [account, setAccount] = useState<Account>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchAccounts(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setAccount(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, account: Account) => {
    	e.preventDefault();
        dispatch(clearError(null));
    	setAccount(account);
    	setShowAddEdit(true);
  	};

      const columns: Column<Account>[] = useMemo(() => [
        {
            Header: "Code",
            accessor: "code"
          },
        {
          Header: "Name",
          accessor: "name"
        },
        {
            Header: "Type",
            accessor: accType => { return (accType.accountType.name) }
        },
        {
          Header: () => (
              <div style={{ textAlign:"center" }}>
              </div>
          ),
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                    <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('2d6j29uq5m') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                        </button>}
                  </div>
              );
          }

      },
      {
        Header: () => (
            <div style={{ textAlign:"center" }}>
            </div>
        ),
            accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('1s2jf331bp') &&
                            <IconButton aria-label="delete" 
                                onClick={() => {    
                                    setAccount(row.original);
                                    setConfirmOpen(true);
                                }}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </IconButton>}
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[accounts]);

    const columnsSm: Column<Account>[] = useMemo(() => [
        {
            Header: "Account",
            Cell: ({ row }) => {
                return (
                    <React.Fragment>
                        {`${row.original.code} ${row.original.name} ${row.original.accountType.name}`}
                    </React.Fragment>
                );
            },
        },
        {
            Header: () => (
                <div style={{ textAlign:"center" }}></div>
            ),
            accessor: "edit",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('2d6j29uq5m') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>}
                    </div>
              );
          }

      },
      {
        Header: () => (
            <div style={{ textAlign:"center" }}>
            </div>
          ),
        accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('1s2jf331bp') &&
                            <IconButton aria-label="delete" 
                                onClick={() => {    
                                    setAccount(row.original)
                                    setConfirmOpen(true);
                                }}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </IconButton>}
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[accounts]);

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
			<React.Fragment>
                {accountError &&
                <span
                    className="text-red-500"
                    style={{ display: accountError ? "block" : "none" }}>
                    {accountError}
                </span>}
				<div>
                    {accounts &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<Account>
                                    name="Accounts"
                                    columns={columns}
                                    data={accounts}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('hbd5u1f1k7')}
                                    customRowStyle='text-sm text-gray-900 font-normal px-2 py-0' />
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<Account>
                                    name="Accounts"
                                    columns={columnsSm}
                                    data={accounts}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('hbd5u1f1k7')}
                                    customRowStyle='text-sm text-gray-900 font-normal px-2 py-0' />
                            </div>
                        </React.Fragment>}
				</div>
				<div>
					{showAddEdit && 
						<AddEditAccount 
							authProps={authProps} 
							setShowAddEdit={setShowAddEdit}
							account={account}  />}
				</div>
                <div className="text-sm">
                    <ConfirmDialog
                        title={`Are you sure you want to delete account ${account?.name} ?`}
                        open={confirmOpen}
                        onClose={() => setConfirmOpen(false)}
                        onConfirm={() => {
                                const params: AccountParams = {
                                    authProps: authProps,
                                    account: account,
                                };
                                dispatch(deleteAccount(params));
                        }}>
                    </ConfirmDialog>
                </div>
			</React.Fragment>
        );
};

export default AccountPage;