import React, { FC, useState, useEffect, SetStateAction, useMemo } from 'react';
import { selectEmailMessageError, EmailMessage, EmailMessageParams, addEmailMessage,
    editEmailMessage, clearError } from '../../store/emailMessageSlice';
import { EmailRecipient, addVirtualRecipient, deleteVirtualRecipient, selectEmailRecipients } from '../../store/emailRecipientSlice';
import AuthProps from '../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import useToast from '../../hooks/useToast';
import { grayedOutButtonClass, enabledButtonClass, getRandomId } from '../../shared/Utils';
import { fetchUserEmailAddresses, selectUserEmailAddresses } from '../../store/userEmailAddressSlice';
import Select from '../common/Select';
import { WrappedTable } from '../common/Table/WrappedTable';
import { Column } from 'react-table';
import validator from 'validator';

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    emailMessage?: EmailMessage | undefined;
}

const AddEditEmailMessage: FC<Props> = ({ authProps, setShowAddEdit, emailMessage }) => {

    const toast = useToast();
    const dispatch = useAppDispatch();

    const [editMode] = useState<boolean>(!!emailMessage);
    const [emailAddress, setEmailAddress] = useState<string>(editMode ? emailMessage.emailAddress : "");
    const [subject, setSubject] = useState<string>(editMode ? emailMessage.subject : "");
    const [subjectCounter, setSubjectCounter] = useState<number>(0);
    const [message, setMessage] = useState<string>(editMode ? emailMessage.message : "");
    const [messageCounter, setMessageCounter] = useState<number>(0);
    const [recipientEmailAddress, setRecipientEmailAddress] = useState<string>("");

    const emailMessageError = useAppSelector(selectEmailMessageError);

    const userEmailAddresses = useAppSelector(selectUserEmailAddresses);
    const emailRecipients = useAppSelector(selectEmailRecipients);

    useEffect(() => {
        const controller = new AbortController();
        if (authProps.auth.token.features?.map(a => a.id).includes('9bqu02ekui')) {
            dispatch(fetchUserEmailAddresses(authProps));
        }
        return () => controller.abort();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updatedEmailMessage: EmailMessage = {
            id: editMode ? emailMessage.id : "",
            userId: authProps.auth.token.userId,
            emailAddress: emailAddress,
            subject: subject,
            message: message,
            disableResponse: false,
            emailStatus: 'PENDING',
            recipients: emailRecipients,
        }
        const params: EmailMessageParams = {
            authProps: authProps,
            emailMessage: updatedEmailMessage,
        }

        const result = await dispatch(editMode ? editEmailMessage(params) : addEmailMessage(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            toast('success', editMode ? `message edited successfully` : `message created successfully`);

            // clear fields
            setEmailAddress("");
            setSubject("");
            setMessage("");
        }

        if (editMode && !errorCondition) {
            setShowAddEdit(false);
        }
    }

    const deleteSelectedRecipientEmail = (e: React.MouseEvent<HTMLButtonElement>,
        emailRecipient: EmailRecipient) => {
        e.preventDefault();
        dispatch(clearError(null));
        dispatch(deleteVirtualRecipient(emailRecipient));
    }

    const recipientColumns: Column<EmailRecipient>[] = useMemo(() => [
        {
            Header: "Email",
            accessor: "emailAddress",
        },
       
        {
            Header: "",
            accessor: "emailMessageId",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        <button
                            onClick={e => deleteSelectedRecipientEmail(e, row.original)}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[emailRecipients])

    const isInvalid =
        emailAddress === ""
        || subject === ""
        || Number(subject.length) > 256
        || message === ""
        || Number(message.length) > 1024
        || Number(emailRecipients.length) === 0
        || Number(emailRecipients.length) > 20;

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                    <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                                <div className="max-w-md w-full space-y-4">
                                    <h2 className="text-center text-2xl font-normal text-gray-900">
                                        {editMode ? `Edit: ${emailMessage.subject}` : `Create a new email message`}
                                    </h2>

                                    <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                        <div className="rounded-md shadow-sm -space-y-px">

                                            <span
                                                className="text-red-500"
                                                style={{ display: emailMessageError ? "block" : "none" }}>
                                                {emailMessageError}
                                            </span>

                                            <Select
                                                id="emailAddress"
                                                placeHolder="Select Your Email Address"
                                                className="shadow-sm border rounded w-full py-1 px-2 mb-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                selectList={
                                                    userEmailAddresses.map(user => {
                                                        return {id: user.emailAddress, name: user.emailAddress}
                                                    })}
                                                required={true}
                                                onChange={e => {
                                                    e.preventDefault();
                                                    setEmailAddress(e.target.value);
                                                }}
                                                autoFocus />

                                            <label className="text-gray-700 text-sm font-bold" htmlFor="subject">
                                                Subject
                                                <span className="font-normal pl-2">{`(count: ${subjectCounter} / max=256)`}</span>
                                            </label>
                                            <textarea
                                                id="subject"
                                                name="subject"
                                                value={subject}
                                                maxLength={256}
                                                required
                                                className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                placeholder="Subject (max 256)"
                                                onChange={(e) => {
                                                    dispatch(clearError(null));
                                                    setSubject(e.target.value);
                                                    setSubjectCounter(e.target.value.length);
                                                }}
                                            />

                                            <label className="text-gray-700 text-sm font-bold" htmlFor="message">
                                                Message
                                                <span className="font-normal pl-2">{`(count: ${messageCounter} / max=1024)`}</span>
                                            </label>
                                            <textarea
                                                id="message"
                                                name="message"
                                                value={message}
                                                maxLength={1024}
                                                required
                                                className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                placeholder="Message (max 1024)"
                                                onChange={(e) => {
                                                    dispatch(clearError(null));
                                                    setMessage(e.target.value);
                                                    setMessageCounter(e.target.value.length);
                                                }}
                                            />

                                            <div>
                                                <label className="text-gray-700 text-sm font-bold" htmlFor="recipientEmailAddress">
                                                    Recipient
                                                </label>
                                                <input
                                                    id="recipientEmailAddress"
                                                    name="recipientEmailAddress"
                                                    type="text"
                                                    disabled={emailAddress === ""}
                                                    value={recipientEmailAddress}
                                                    maxLength={60}
                                                    className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                    placeholder="Recipient email (max 60)"
                                                    onChange={e => {
                                                        dispatch(clearError(null));
                                                        setRecipientEmailAddress(e.target.value.toLowerCase());
                                                    }}
                                                />
                                                <button
                                                    className={recipientEmailAddress === ""  ? grayedOutButtonClass :
                                                        enabledButtonClass}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        if (validator.isEmail(recipientEmailAddress)) {
                                                            if (emailAddress.toLowerCase() !== recipientEmailAddress) {
                                                                const newRecipientEmail: EmailRecipient = {
                                                                    emailMessageId: emailMessage === undefined ? "xxxxxxxxxx" : emailMessage.id,
                                                                    userId: getRandomId(10).toString(),
                                                                    emailRecipientType: 'PRIMARY',
                                                                    emailAddress: recipientEmailAddress,
                                                                }
                                                                dispatch(addVirtualRecipient(newRecipientEmail));
                                                                // clear recipient email
                                                                setRecipientEmailAddress("");
                                                            } else {
                                                                toast('error', `Can't send email to self`);
                                                            }
                                                        } else {
                                                            toast('error', `Invalid email address: ${recipientEmailAddress}`);
                                                        }
                                                    }}>
                                                    Add Recipient
                                                </button>

                                                <div>
                                                    {<WrappedTable<EmailRecipient>
                                                            name=""
                                                            columns={recipientColumns}
                                                            data={emailRecipients}
                                                            addonHooks={[]} />}
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="flex items-center">
                                                    {editMode ? "Edit" : "Send"}
                                                </span>
                                            </button>
                                            </div>
                                            <div className="text-sm">
                                                <button
                                                    type="submit"
                                                    onClick={cancel}
                                                    className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AddEditEmailMessage;