import React, { FC, useEffect } from 'react';
import { DeductionParams, fetchMonthlyDeduction, selectMonthlyDeduction } from '../../../store/monthlyDeductionSlice';
import AuthProps from '../../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { ccyFormat } from '../../../shared/Utils';

interface Props {
    authProps: AuthProps;
    memberId: string;
}

const MonthlyDeduction: FC<Props> = ({ authProps, memberId }) => {

    const dispatch = useAppDispatch();

    const deduction = useAppSelector(selectMonthlyDeduction);

    useEffect(() => {
        const controller = new AbortController();
        const params: DeductionParams = {
            authProps: authProps,
            memberId: memberId,
        }
        dispatch(fetchMonthlyDeduction(params));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[memberId]);

    return (
        <div className="flex w-full md:w-1/4">
            <div className="text-xs flex items-center space-x-1 space-y-1">
                <div className="grid grid-cols-3 gap-1">
                    <div className="font-bold">
                        Monthly Deduction:
                    </div>
                    <div className="font-normal col-span-2">
                        {ccyFormat(Number(deduction && deduction.deduction), 2)}
                    </div>
                </div>
            </div>
        </div>   
    );
};

export default MonthlyDeduction;