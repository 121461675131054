import React, { FC, useState, SetStateAction, useMemo } from 'react';
import { selectGeneralLedgerEntryError, GeneralLedgerEntry, GeneralLedgerEntryParams, addGeneralLedgerEntry,
    editGeneralLedgerEntry, clearGeneralLedgerEntryError } from '../../../store/generalLedgerEntrySlice';
import AuthProps from '../../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import useToast from '../../../hooks/useToast';
import { grayedOutButtonClass, enabledButtonClass, getRandomId } from '../../../shared/Utils';
import Date from '../../common/Transactions/Date';
import Memo from '../../common/Transactions/Memo';
import SubsidiaryAccount from '../../common/Transactions/SubsidiaryAccount';
import Amount from '../../common/Transactions/Amount';
import { addVirtualGeneralLedgerTransaction, clearGeneralLedgerTransactionError,
     deleteVirtualGeneralLedgerTransaction, GeneralLedgerTransaction,
      selectGeneralLedgerTransactions } from '../../../store/generalLedgerTransactionSlice';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import { ccyFormat } from '../../../shared/Utils';

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    generalLedgerEntry?: GeneralLedgerEntry | undefined;
}

const AddEditGeneralLedgerEntry: FC<Props> = ({ authProps, setShowAddEdit, generalLedgerEntry }) => {

    const toast = useToast();
    const dispatch = useAppDispatch();

    const [editMode] = useState<boolean>(!!generalLedgerEntry);
    const [date, setDate] = useState<string>(editMode ? generalLedgerEntry.date : "");
    const [dateError, setDateError] = useState<string>("");
    const [memo, setMemo] = useState<string>(editMode ? generalLedgerEntry.memo : "");
    const [memoError, setMemoError] = useState<string>("");

    const generalLedgerEntryError = useAppSelector(selectGeneralLedgerEntryError);

    const [subsidiaryAccountId, setSubsidiaryAccountId] = useState<string>("");
    const [subsidiaryAccountCode, setSubsidiaryAccountCode] = useState<string>("");
    const [subsidiaryAccountName, setSubsidiaryAccountName] = useState<string>("");
    const [primaryAccountCode, setPrimaryAccountCode] = useState<string>("");
    const [primaryAccountName, setPrimaryAccountName] = useState<string>("");

    const [amount, setAmount] = useState<string>("");
    const [amountError, setAmountError] = useState<string>("");

    const generalLedgerTransactions = useAppSelector(selectGeneralLedgerTransactions);

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updatedGeneralLedgerEntry: GeneralLedgerEntry = {
            id: editMode ? generalLedgerEntry.id : "",
            date: date,
            memo: memo,
            makerId: authProps.auth.token.userId,
            accountId: authProps.config.ACCOUNT_ID,
            transactions: generalLedgerTransactions,
        }
        const params: GeneralLedgerEntryParams = {
            authProps: authProps,
            generalLedgerEntry: updatedGeneralLedgerEntry,
        }

        const result = await dispatch(editMode ? editGeneralLedgerEntry(params) : addGeneralLedgerEntry(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            toast('success', editMode ? `General ledger entry edited successfully` : `General ledger entry saved successfully`);
        }

        if (!errorCondition) {
            setShowAddEdit(false);
        }
    }

    const addNewGeneralLedgerTransaction = (e: React.MouseEvent<HTMLButtonElement>,
        subsidiaryAccountId: string, amount: string,
        subsidiaryAccountCode: string, subsidiaryAccountName: string,
        primaryAccountCode: string, primaryAccountName: string) => {
        
        const newGeneralLedgerTransaction: GeneralLedgerTransaction = {
            id: getRandomId(10),
            subsidiaryAccountId: subsidiaryAccountId,
            amount: Number(amount),
            code: subsidiaryAccountCode,
            name: subsidiaryAccountName,
            primaryCode: primaryAccountCode,
            primaryName: primaryAccountName,
        }
        dispatch(addVirtualGeneralLedgerTransaction(newGeneralLedgerTransaction));
        // clear fields
        setSubsidiaryAccountId("1111111111");
        setAmount("");
    }

    const deleteSelectedGeneralLedgerTransaction = (e: React.MouseEvent<HTMLButtonElement>,
        generalLedgerTransaction: GeneralLedgerTransaction) => {
        e.preventDefault();
        dispatch(clearGeneralLedgerTransactionError(null));
        dispatch(deleteVirtualGeneralLedgerTransaction(generalLedgerTransaction));
    }

    const totalAmount: any = (items: GeneralLedgerTransaction[]) => {
        return items.map( ({ amount }) => Number(amount))
            .reduce((sum, i) => sum + i, 0) || 0;
    }

    const generalLedgerTransactionColumns: Column<GeneralLedgerTransaction>[] = useMemo(() => [
        {
            Header: "Account",
            accessor: acct => { return( `${acct.primaryCode}-${acct.code} ${acct.name} (${acct.primaryName})` ) }
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Amount
                </div>
            ),
            accessor: "amount",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        <div className="font-bold">
                            {ccyFormat(Number(row.original.amount), 2)}
                        </div>
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    {ccyFormat(Number(totalAmount(columnProps.data)), 2)}
                </div>
            ),
        },
        {
            Header: "",
            accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        <button
                            onClick={e => deleteSelectedGeneralLedgerTransaction(e, row.original)}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[generalLedgerTransactions]);

    const isInvalid =
        date === ""
        || generalLedgerTransactions.length === 0
        || Number(totalAmount(generalLedgerTransactions)) !== 0;

    const isInvalidItem = subsidiaryAccountId === ""
        || amount === "";

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">

                    <div className="flex items-center md:px-4 py-2">
                        <div className="relative sm:w-full p-2 mx-auto bg-white rounded-md shadow-lg">
                            
                            <div className="w-full block inset bg-[#fffbe6] rounded-md border-2">
                                <h2 className="text-center text-2xl font-normal text-gray-900">
                                    {editMode ? `Edit: ${generalLedgerEntry.ref}` : `Create a new journal entry`}
                                </h2>
                            </div>

                            <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                <div className="rounded-md shadow-sm -space-y-px">

                                    <span
                                        className="text-red-500"
                                        style={{ display: generalLedgerEntryError ? "block" : "none" }}>
                                        {generalLedgerEntryError}
                                    </span>

                                    <Date
                                        setDate={setDate}
                                        date={date}
                                        setDateError={setDateError}
                                        dateError={dateError}
                                        label="Date"
                                        isInvalid={false}
                                        defaultFocus={true} />

                                    <Memo
                                        setMemo={setMemo}
                                        memo={memo}
                                        memoName={'[Memo]'}
                                        placeholder={'max 100 (optional)'}
                                        maxLength={100} />
                                    
                                </div>

                                <div className="block bg-slate-100 inset-1 p-2 mt-2">
                                    
                                    <SubsidiaryAccount
                                        authProps={authProps}
                                        setSubsidiaryAccountId={setSubsidiaryAccountId}
                                        subsidiaryAccountId={subsidiaryAccountId}
                                        setSubsidiaryAccountCode={setSubsidiaryAccountCode}
                                        setSubsidiaryAccountName={setSubsidiaryAccountName}
                                        setPrimaryAccountCode={setPrimaryAccountCode}
                                        setPrimaryAccountName={setPrimaryAccountName} />

                                    <Amount
                                        setAmount={setAmount}
                                        amount={amount}
                                        setAmountError={setAmountError}
                                        amountError={amountError}
                                        placeholderText="+ (debit) | - (credit)"
                                        maxLengthValue={9} />

                                    <div className="p-1 w-auto">
                                        <button
                                            disabled={isInvalidItem} 
                                            className={isInvalidItem ? grayedOutButtonClass : enabledButtonClass}
                                            onClick={(e) => {
                                                clearGeneralLedgerTransactionError(null);
                                                addNewGeneralLedgerTransaction(e, 
                                                    subsidiaryAccountId, amount,
                                                    subsidiaryAccountCode, subsidiaryAccountName,
                                                    primaryAccountCode, primaryAccountName);
                                            }}>
                                            <span className="flex items-center">
                                                {subsidiaryAccountId === "" ? "Add Transaction" : `Add`}
                                            </span>
                                        </button>
                                    </div>

                                    <div>
                                        {<WrappedTable<GeneralLedgerTransaction>
                                            name=""
                                            columns={generalLedgerTransactionColumns}
                                            data={generalLedgerTransactions}
                                            addonHooks={[]}
                                            includeFooter />}
                                    </div>

                                </div>

                                <div className="flex items-center justify-between">
                                    <div>
                                    <button
                                        type="submit"
                                        disabled={isInvalid}
                                        className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                        <span className="flex items-center">
                                            {editMode ? "Edit" : "Save"}
                                        </span>
                                    </button>
                                    </div>
                                    <div className="text-sm">
                                        <button
                                            type="submit"
                                            onClick={cancel}
                                            className="transition-colors hover:text-gray-900 font-medium duration-200">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                           
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AddEditGeneralLedgerEntry;