import React, { FC, SetStateAction, useEffect, useState } from 'react';
import AuthProps from '../AuthProps';
import { useAppSelector, useAppDispatch } from '../hooks';
import Select from '../Select';
import { selectLoans, fetchMemberLoans, LoanParams } from '../../../store/loanSlice';
import { fetchAllMembers, fetchAllMembers2, selectMembers } from '../../../store/memberSlice';


interface Props {
    authProps: AuthProps;
    setMemberId: React.Dispatch<SetStateAction<string>>;
    memberId: string;
    setMemberName: React.Dispatch<SetStateAction<string>>;
    setLoanId: React.Dispatch<SetStateAction<string>>;
    loanId: string;
    setLoanRef?: React.Dispatch<SetStateAction<string>>;
    setLoanName?: React.Dispatch<SetStateAction<string>>;
}

const MemberLoan: FC<Props> = ({ authProps, setMemberId, memberId, setMemberName, setLoanId, 
    loanId, setLoanRef, setLoanName }) => {

    const dispatch = useAppDispatch();

    const preSelectMember = [{'id':'1111111111','name':'Select member'}];
    const members = useAppSelector(selectMembers);

    const preSelectLoan = [{'id':'1111111111','name':'Select loan'}];
    const loans = useAppSelector(selectLoans);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchAllMembers2(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div className="grid grid-cols-3 w-full">

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="memberId">
                Member
            </label>
            
            <div className="col-span-2">
                <Select
                    id="memberId"
                    className="p-1"
                    placeHolder={"Select"}
                    selectList={
                        preSelectMember.map(select => {
                            return {id: select.id, name: select.name}
                        }).concat(
                            members.map(member => {
                                return { id: member.id, name: `${member.firstName} ${member.middleName} ${member.surname} (${member.saccoId})`}
                    }))}
                    required={true}
                    onChange={e => {
                        setMemberId(e.target.value);
                        var member = members.find( ({ id }) => id === e.target.value);
                        setMemberName(`${member.firstName} ${member.middleName} ${member.surname}`);
                        const params: LoanParams = {
                            authProps: authProps,
                            memberId: e.target.value,
                        }
                        dispatch(fetchMemberLoans(params));
                    }} />
            </div>

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="loanId">
                Loan
            </label>
            
            <div className="col-span-2">
                <Select
                    id="loanId"
                    className="p-1"
                    placeHolder={"Select"}
                    selectList={
                        preSelectLoan.map(select => {
                            return {id: select.id, name: select.name}
                        }).concat(
                            loans.map(loan => {
                                return { id: loan.id, name: `${loan.name} (Ref: ${loan.ref})`}
                    }))}
                    required={true}
                    disabled={memberId === "" || memberId === "1111111111"}
                    onChange={e => {
                        setLoanId(e.target.value);
                        var loan = loans.find( ({ id }) => id === e.target.value);
                        setLoanRef(loan.ref);
                        setLoanName(loan.name);
                    }} />
            </div>

        </div>
    );
}

export default MemberLoan;