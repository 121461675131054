import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { SubsidiaryAccount } from './subsidiaryAccountSlice';
import { TransactionCode } from './transactionCodeSlice';

export type TransactionCodeAccountParams = {
    authProps: AuthProps;
    transactionCodeAccount?: TransactionCodeAccount;
}

export type TransactionCodeAccount = {
    id: string;
    transactionCodeId: string;
    transactionCode?: TransactionCode;
    entryOrTransaction: string;
    debitOrCredit: string;
    subsidiaryAccountId: string;
    subsidiaryAccount?: SubsidiaryAccount;
    accountId: string;
    edit?: void;
    delete?: void;
}

interface TransactionCodeAccountState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: TransactionCodeAccount[];
}

interface FetchErrors {
    message: string;
}

const initialState: TransactionCodeAccountState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchTransactionCodeAccounts = 
    createAsyncThunk<TransactionCodeAccount[], AuthProps, { rejectValue: FetchErrors }>(
    "transactionCodeAccounts/fetchByAccount",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.TRANSACTIONCODE_ACCOUNT_URL +
                "/" + params.config.ACCOUNT_ID, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch transaction code accounts",
            });
        }
        return response.json();
    }
);

export const addTransactionCodeAccount =
    createAsyncThunk<TransactionCodeAccount, TransactionCodeAccountParams, { rejectValue: FetchErrors }>(
    "transactionCodeAccount/add",
    async (params: TransactionCodeAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.TRANSACTIONCODE_ACCOUNT_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.transactionCodeAccount),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editTransactionCodeAccount =
    createAsyncThunk<TransactionCodeAccount, TransactionCodeAccountParams, { rejectValue: FetchErrors }>(
    "transactionCodeAccount/edit",
    async (params: TransactionCodeAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.TRANSACTIONCODE_ACCOUNT_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.transactionCodeAccount),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteTransactionCodeAccount =
    createAsyncThunk<TransactionCodeAccount, TransactionCodeAccountParams, { rejectValue: FetchErrors }>(
    "transactionCodeAccount/delete",
    async (params: TransactionCodeAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.TRANSACTIONCODE_ACCOUNT_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.transactionCodeAccount),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const transactionCodeAccountSlice = createSlice({
    name: 'transactionCodeAccount',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchTransactionCodeAccounts.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addTransactionCodeAccount.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editTransactionCodeAccount.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteTransactionCodeAccount.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchTransactionCodeAccounts.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addTransactionCodeAccount.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editTransactionCodeAccount.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteTransactionCodeAccount.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchTransactionCodeAccounts.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addTransactionCodeAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editTransactionCodeAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteTransactionCodeAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectTransactionCodeAccountStatus = (state: RootState) => state.transactionCodeAccounts.status;
export const selectTransactionCodeAccounts = (state: RootState) => state.transactionCodeAccounts.list;
export const selectTransactionCodeAccountError = (state: RootState) => state.transactionCodeAccounts.error;
export const { clearList, clearError } = transactionCodeAccountSlice.actions;
export default transactionCodeAccountSlice.reducer;