import React, { FC, useState } from 'react';
import AuthProps from '../../common/AuthProps';
import TransactionCodeTypePage from './TransactionCodeTypePage';
import ControlAccountPage from '../../Accounts/ControlAccountPage';
import TransactionCodePage from './TransactionCodePage';

const MemberAccountsMain: FC<AuthProps> = (authProps) => {

    const [openTab, setOpenTab] = useState(1);

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <ul
                        className="flex mb-3 list-none flex-wrap pt-1 pb-2 flex-row"
                        role="tablist">
                        {authProps.auth.token.features?.map(a => a.id).includes('y9xaevs4cg') &&
                            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                                
                                <a className={
                                    "text-xs font-bold px-5 py-2 shadow-sm rounded-xs block leading-normal " +
                                    (openTab === 1
                                        ? "text-grey-300 bg-[#fff0cc]"
                                        : "text-[#850000] bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(1);
                                    }}
                                    data-toggle="tab"
                                    href="#link1"
                                    role="tablist"
                                >
                                    Account Types
                                </a>
                            </li>}
                        {authProps.auth.token.features?.map(a => a.id).includes('bp538a2myg') &&
                            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                                
                                <a className={
                                    "text-xs font-bold px-5 py-2 shadow-sm rounded-xs block leading-normal " +
                                    (openTab === 2
                                        ? "text-grey-300 bg-[#fff0cc]"
                                        : "text-[#850000] bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(2);
                                    }}
                                    data-toggle="tab"
                                    href="#link2"
                                    role="tablist"
                                >
                                    Transaction Codes
                                </a>
                            </li>}
                        {authProps.auth.token.features?.map(a => a.id).includes('bgna5dy6j3') &&
                            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                                
                                <a className={
                                    "text-xs font-bold px-5 py-2 shadow-sm rounded-xs block leading-normal " +
                                    (openTab === 3
                                        ? "text-grey-300 bg-[#fff0cc]"
                                        : "text-[#850000] bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(3);
                                    }}
                                    data-toggle="tab"
                                    href="#link3"
                                    role="tablist"
                                >
                                    Control Accounts
                                </a>
                            </li>}
                        {authProps.auth.token.features?.map(a => a.id).includes('j8465r3qzn') &&
                            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                                
                                <a className={
                                    "text-xs font-bold px-5 py-2 shadow-sm rounded-xs block leading-normal " +
                                    (openTab === 4
                                        ? "text-grey-300 bg-[#fff0cc]"
                                        : "text-[#850000] bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(4);
                                    }}
                                    data-toggle="tab"
                                    href="#link4"
                                    role="tablist"
                                >
                                    Members
                                </a>
                            </li>}
                            
                    </ul>
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-sm">
                        <div className="px-0 py-0 flex-auto">
                            <div className="tab-content tab-space">
                                <div className={openTab === 1
                                    && authProps.auth.token.features?.map(a => a.id).includes('y9xaevs4cg') ? "block" : "hidden"} id="link1">
                                   <TransactionCodeTypePage authProps={authProps} book={'MEMBER'} />
                                </div>
                                <div className={openTab === 2
                                    && authProps.auth.token.features?.map(a => a.id).includes('bp538a2myg') ? "block" : "hidden"} id="link2">
                                    <TransactionCodePage authProps={authProps} book={'MEMBER'} />
                                </div>
                                <div className={openTab === 3
                                    && authProps.auth.token.features?.map(a => a.id).includes('bgna5dy6j3') ? "block" : "hidden"} id="link3">
                                    <ControlAccountPage authProps={authProps} book={'MEMBER'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default MemberAccountsMain;