import React, { FC, useEffect } from 'react';
import AuthProps from '../../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { Member, selectMember } from '../../../store/memberSlice';
import { selectMemberStatus, fetchMemberById,
    selectMemberError, MemberParams } from '../../../store/memberSlice';

interface Props {
    authProps: AuthProps;
    member: Member;
}

const Personal: FC<Props> = ({ authProps, member }) => {

    const dispatch = useAppDispatch();

    const status = useAppSelector(selectMemberStatus);
    const memberData = useAppSelector(selectMember);
    const memberError = useAppSelector(selectMemberError);

    useEffect(() => {
        const controller = new AbortController();
        const params: MemberParams = {
            authProps: authProps,
            memberId: member.id,
        }
        dispatch(fetchMemberById(params));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[member]);

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
            <React.Fragment>
                {memberError &&
                    <span
                        className="text-red-500"
                        style={{ display: memberError ? "block" : "none" }}>
                        {memberError}
                    </span>}
                    <div className="flex w-full md:w-1/4">
                        <div className="text-xs flex items-center space-x-1 space-y-1">
                            <div className="grid grid-cols-3 gap-1">
                                <div className="font-bold">
                                    Gender:
                                </div>
                                <div className="font-normal col-span-2">
                                    {memberData?.gender.substring(0,1)}
                                </div>
                                <div className="font-bold">
                                    Date of Birth:
                                </div>
                                <div className="font-normal col-span-2">
                                    {memberData?.dateOfBirth}
                                </div>
                                <div className="font-bold">
                                    Marital Status:
                                </div>
                                <div className="font-normal col-span-2">
                                    {memberData?.maritalStatus.substring(0,1)}
                                </div>
                            </div>
                        </div>
                    </div>
            </React.Fragment>
    );
};

export default Personal;