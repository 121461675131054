import React, { FC } from 'react';
import { useAppSelector } from '../common/hooks';
import { selectCurrentReportingPeriod } from '../../store/reportingPeriodSlice';

const CurrentPeriodDisplay:FC = () => {

    const currentPeriod = useAppSelector(selectCurrentReportingPeriod);

    return (
        <>
             <div className="w-full">
                <div className="text-lg font-thin bg-green-100 m-1 px-2 p-2 py-1 rounded-sm border-2">
                    <div className='flex flex-row'>
                        <h2>
                            Current Period: 
                            <span className='font-bold text-yellow-500 ml-2'>
                                {`${currentPeriod && currentPeriod.reportingPeriodMonth}-${currentPeriod && currentPeriod.financialYear.reportingYear}`}
                            </span>
                        </h2>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CurrentPeriodDisplay;