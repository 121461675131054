import React, { FC, useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { ContentHeading, fetchContentHeadings, selectContentHeadingError,
    selectContentHeadings, selectContentHeadingStatus, clearError,
     deleteContentHeading, ContentHeadingParams } from '../../../store/contentHeadingSlice';
import AuthProps from '../../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import AddEditContentHeading from './AddEditContentHeading';

const ContentHeadingPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const contentHeadings = useAppSelector(selectContentHeadings);
    const status = useAppSelector(selectContentHeadingStatus);
    const contentHeadingError = useAppSelector(selectContentHeadingError);

    const [contentHeading, setContentHeading] = useState<ContentHeading>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchContentHeadings(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setContentHeading(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, contentHeading: ContentHeading) => {
    	e.preventDefault();
        dispatch(clearError(null));
    	setContentHeading(contentHeading);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, contentHeading: ContentHeading) => {
		e.preventDefault();
        dispatch(clearError(null));
    	setContentHeading(contentHeading);
    	const params: ContentHeadingParams = {
        	authProps: authProps,
        	contentHeading: contentHeading,
      	}
    	dispatch(deleteContentHeading(params))
 	};

    const columns: Column<ContentHeading>[] = useMemo(() => [
        {
          Header: "Heading",
          accessor: "heading"
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Priority
                </div>
            ),
            accessor: "priority",
            Cell: (props) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        {Number(props.value)}
                    </div>
                );
            },
        },
        {
            Header: "Public",
            accessor: "showPublic",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        <input
                            type="checkbox"
                            checked={row.original.showPublic}/>
                    </div>
                );
            }
        },
        {
            Header: "Private",
            accessor: "showPrivate",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        <input
                            type="checkbox"
                            checked={row.original.showPrivate}/>
                    </div>
                );
            }
        },
        {
            Header: "",
            accessor: "edit",
            Cell: ({ row }) => {
                return (
                    <div className="items-center margin: auto">
                        {authProps.auth.token.features?.map(a => a.id).includes('85jwhs5jwi') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>}
                    </div>
                );
            }

        },
        {
            Header: "",
            accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('zuskwtw3pz') &&
                        <button
                            onClick={e => inLineDelete(e, row.original)}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                            </svg>
                        </button>}
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[contentHeadings,authProps]);

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
            <React.Fragment>
                {contentHeadingError &&
                <span
                    className="text-red-500"
                    style={{ display: contentHeadingError ? "block" : "none" }}>
                    {contentHeadingError}
                </span>}
                <div>
                    {contentHeadings &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<ContentHeading>
                                    name="Headings"
                                    columns={columns}
                                    data={contentHeadings}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('jhxgbkf9ut')}
                                /> 
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<ContentHeading>
                                    name="Headings"
                                    columns={columns}
                                    data={contentHeadings}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('jhxgbkf9ut')}
                                />
                            </div>
                        </React.Fragment>}
                </div>
                <div>
                    {showAddEdit &&
                        <AddEditContentHeading 
                            authProps={authProps} 
                            setShowAddEdit={setShowAddEdit}
                            contentHeading={contentHeading}
                        />}
                </div>
            </React.Fragment>
    );
}

export default ContentHeadingPage;