import React, { FC, useEffect } from 'react';
import { fetchMemberBranch, MemberBranchParams, selectMemberBranch } from '../../../store/memberBranchSlice';
import AuthProps from '../../common/AuthProps';
import { useAppSelector, useAppDispatch } from '../../common/hooks';

interface Props {
    authProps: AuthProps;
    memberId: string;
}

const Employer: FC<Props> = ({ authProps, memberId }) => {

    const dispatch = useAppDispatch();

    const memberBranchData = useAppSelector(selectMemberBranch);

    useEffect(() => {
        const controller = new AbortController();
        const params: MemberBranchParams = {
            authProps: authProps,
            memberId: memberId,
        }
        dispatch(fetchMemberBranch(params));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[memberId]);

    return (
        <div className="flex w-full md:w-1/4">
            {memberBranchData && memberBranchData.memberBranch &&
                <div className="text-xs flex items-center space-x-1 space-y-1">
                    <div className="grid grid-cols-3 gap-1">
                        <div className="font-bold">
                            Employer:
                        </div>
                        <div className="font-normal col-span-2">
                            {memberBranchData.memberBranch.branch.employer}
                        </div>
                        <div className="font-bold">
                            Town:
                        </div>
                        <div className="font-normal col-span-2">
                            {memberBranchData.memberBranch.branch.town}
                        </div>
                        <div className="font-bold">
                            Branch:
                        </div>
                        <div className="font-normal col-span-2">
                            {memberBranchData.memberBranch.branch.name}
                        </div>
                    </div>
            </div>}
        </div>   
    );
};

export default Employer;