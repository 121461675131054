import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type LoanTypeParams = {
    authProps: AuthProps;
    loanType?: LoanType;
    loanTypeId?: string;
}

export type LoanType = {
    id: string;
    name: string;
    accountId: string;
    edit?: void;
    delete?: void;
}

interface LoanTypeState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: LoanType[];
}

interface FetchErrors {
    message: string;
}

const initialState: LoanTypeState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchLoanTypes = 
    createAsyncThunk<LoanType[], AuthProps, { rejectValue: FetchErrors }>(
    "loanTypes/fetchByAccount",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.LOANTYPE_URL +
                "/" + params.config.ACCOUNT_ID, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch loanTypes",
            });
        }
        return response.json();
    }
);

export const addLoanType =
    createAsyncThunk<LoanType, LoanTypeParams, { rejectValue: FetchErrors }>(
    "loanType/add",
    async (params: LoanTypeParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.LOANTYPE_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.loanType),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editLoanType =
    createAsyncThunk<LoanType, LoanTypeParams, { rejectValue: FetchErrors }>(
    "loanType/edit",
    async (params: LoanTypeParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.LOANTYPE_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.loanType),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteLoanType =
    createAsyncThunk<LoanType, LoanTypeParams, { rejectValue: FetchErrors }>(
    "loanType/delete",
    async (params: LoanTypeParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.LOANTYPE_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.loanType),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const loanTypeSlice = createSlice({
    name: 'loanType',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchLoanTypes.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addLoanType.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editLoanType.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteLoanType.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchLoanTypes.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addLoanType.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editLoanType.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteLoanType.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchLoanTypes.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addLoanType.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editLoanType.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteLoanType.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectLoanTypeStatus = (state: RootState) => state.loanTypes.status;
export const selectLoanTypes = (state: RootState) => state.loanTypes.list;
export const selectLoanTypeError = (state: RootState) => state.loanTypes.error;
export const { clearList, clearError } = loanTypeSlice.actions;
export default loanTypeSlice.reducer;