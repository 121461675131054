class Config {

    ACCOUNT_ID: string = "dptex4mb3r";
    
    PRESIGNUP_URL: string = process.env.REACT_APP_BASE_URL_AUTH + "/presignup";
    SIGNUP_URL: string = process.env.REACT_APP_BASE_URL_AUTH + "/signup";
    LOGIN_URL: string = process.env.REACT_APP_BASE_URL_AUTH + "/token";
    FORGOTPASSWORD_URL: string = process.env.REACT_APP_BASE_URL_AUTH + "/forgotpassword";
    VALIDATE_AUTHTOKEN_URL: string = process.env.REACT_APP_BASE_URL_AUTH + "/validateauthtoken";
    MEMBER_REGISTRATION_URL: string = process.env.REACT_APP_BASE_URL_AUTH + "/member/registration";

    RESETPASSWORD_URL: string = process.env.REACT_APP_BASE_URL_AUTH + "/resetpassword";
    FEEDBACK_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/feedback";
    ACCESS_TOKEN: string = "accessToken";
    EXPIRATION: string = "expiration";

    MEMBERS_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/members/" + this.ACCOUNT_ID;
    MEMBERS2_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/members/all/" + this.ACCOUNT_ID;
    DELETE_UNREGISTERED_MEMBER_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/13/delete/member/unregistered";
    REGISTRATIONSTATUS_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/member/registrationstatus";
    MEMBERSTATUS_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/member/status";

    LOANTYPE_URL: string = process.env.REACT_APP_BASE_URL_LOANS + "/categories/loantypes";
    CATEGORY_URL: string = process.env.REACT_APP_BASE_URL_LOANS + "/categories";

    MEMBERIDENTITY_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/member/identity";
    NEXTOFKIN_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/member/nextofkin";
    BENEFICIARY_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/member/beneficiaries";
    GUARDIAN_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/member/beneficiary/guardian";
    PERSONALPHONE_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/member/contacts/personalphones";
    EMAIL_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/member/contacts/email";
    ADDRESS_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/member/contacts/address";
    MEMBER_BRANCH_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/member/branch";
    MONTHLYCONTRIBUTION_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/member/deduction";

    ACCOUNT_URL: string = process.env.REACT_APP_BASE_URL_ACCOUNTS + "/account";
    PRIMARYACCOUNT_URL: string = process.env.REACT_APP_BASE_URL_ACCOUNTS + "/primary";
    REPORTINGCLASS_URL: string = process.env.REACT_APP_BASE_URL_ACCOUNTS + "/reportingclass/" + this.ACCOUNT_ID;
    SUBSIDIARYACCOUNT_URL: string = process.env.REACT_APP_BASE_URL_ACCOUNTS + "/subsidiary";

    PUBLIC_EMPLOYERS_URL: string = process.env.REACT_APP_BASE_URL_PUBLIC + "/employers";
    PUBLIC_BRANCHES_URL: string = process.env.REACT_APP_BASE_URL_PUBLIC + "/branches";

    BANK_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/11/banks";
    BANKBRANCH_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/12/bank/branches";
    BANKBRANCHES_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/17/bank/branches";

    CONTENT_HEADER_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/content/header";
    CONTENT_BODY_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/content/body";
    CONTENT_ACTION_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/content/action";

    MEMBER_INFO_SECTIONS_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/members/otherinfo/sections";

    SYSTEM_FEATURES_URL: string = process.env.REACT_APP_BASE_URL_SYSTEM + "/1/features";
    SYSTEM_PROCESSES_URL: string = process.env.REACT_APP_BASE_URL_SYSTEM + "/2/processes";
    SYSTEM_FEATUREPROCESSACTIONS_URL = process.env.REACT_APP_BASE_URL_SYSTEM + "/3/featureprocessactions";
    SYSTEM_ROLEFEATURES_URL = process.env.REACT_APP_BASE_URL_SYSTEM + "/4/rolefeatures";
    SYSTEM_ROLES_URL = process.env.REACT_APP_BASE_URL_SYSTEM + "/5/roles";
    SYSTEM_APPMENU_URL = process.env.REACT_APP_BASE_URL_SYSTEM + "/6/appmenu";

    SYSTEM_USEREMAILADDRESS_URL = process.env.REACT_APP_BASE_URL_SYSTEM + "/4/user/emailaddress";

    SYSTEM_AUDIT_URL: string = process.env.REACT_APP_BASE_URL_SYSTEM + "/11/audit";

    NOTIFICATION_EMAILMESSAGE_URL = process.env.REACT_APP_BASE_URL_NOTIFICATION + "/13/email";

    LOANS_URL = process.env.REACT_APP_BASE_URL_LOANS + "/21/admin";
    LOAN_URL = process.env.REACT_APP_BASE_URL_LOANS + "/7/admin/loan";
    MEMBERLOAN_URL = process.env.REACT_APP_BASE_URL_LOANS + "/2/loan";
    LOANSTATEMENT_URL = process.env.REACT_APP_BASE_URL_LOANS + "/21/admin/loans";
    LOANSTATEMENTS_URL = process.env.REACT_APP_BASE_URL_LOANS + "/49/loanstatements";

    MEMBER_PEERGROUP_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/5/peergroup";

    TRANSACTIONCODE_TYPE_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/memberaccounts/transactioncodetypes";
    TRANSACTIONCODE_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/memberaccounts/transactioncodes";
    CONTROLACCOUNT_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/accounts/controlaccounts";
    LAONTYPE_URL: string = process.env.REACT_APP_BASE_URL_ADMIN + "/memberaccounts/loantypes";

    BANKACCOUNT_URL: string = process.env.REACT_APP_BASE_URL_ACCOUNTS + "/bankaccounts";
    MOBILEMONEYACCOUNT_URL: string = process.env.REACT_APP_BASE_URL_ACCOUNTS + "/mobilemoneyaccounts";
    CASHACCOUNT_URL: string = process.env.REACT_APP_BASE_URL_ACCOUNTS + "/cashaccounts";

    FINANCIAL_YEAR_URL: string = process.env.REACT_APP_BASE_URL_ACCOUNTS + "/5/financialyear";
    CURRENT_PERIOD_URL: string = process.env.REACT_APP_BASE_URL_ACCOUNTS + "/19/reportingperiod/current/" + this.ACCOUNT_ID;

    ENTRY_URL: string = process.env.REACT_APP_BASE_URL_ACCOUNTS + "/17/transactions/entries";
    TRANSACTION_POST_URL: string = process.env.REACT_APP_BASE_URL_ACCOUNTS + "/";
    TRIALBALANCE_URL: string = process.env.REACT_APP_BASE_URL_ACCOUNTS + "/17/transactions/trialbalance/" + this.ACCOUNT_ID;

    TRANSACTIONCODE_ACCOUNT_URL: string = process.env.REACT_APP_BASE_URL_ACCOUNTS + "/222/transactioncodeaccounts";

    GENERALLEDGER_ENTRIES_URL: string = process.env.REACT_APP_BASE_URL_TRANSACTIONS + "/generalledger/current/entries/saved";
    MEMBERLOAN_ENTRIES_URL: string = process.env.REACT_APP_BASE_URL_TRANSACTIONS + "/memberloan/current/entries/saved";
    CASHBOOK_ENTRIES_URL: string = process.env.REACT_APP_BASE_URL_TRANSACTIONS + "/cashbook/current/entries/saved";

    defaultHeaders(): any {
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
            app: "AdminWeb",
            accountId: this.ACCOUNT_ID,
        };
    }
  
    headersWithAuthorization(): any {
        return {
            ...this.defaultHeaders(),
            Authorization: localStorage.getItem(this.ACCESS_TOKEN),
        };
    }

    multipartAuthorization(): any {
        return {
            Authorization: localStorage.getItem(this.ACCESS_TOKEN),
        };
    }
  
    tokenExpired() {
        const expDate = Number(localStorage.getItem(this.EXPIRATION));
        if (expDate > Date.now()) {
            return false;
        }
        return true;
    }
  
    storeAccessToken(token: any) {
        localStorage.setItem(this.ACCESS_TOKEN, `Bearer ${token}`);
        localStorage.setItem(this.EXPIRATION, this.getExpiration(token).toString());
    }
  
    getExpiration(token: any): number {
        let encodedPayload = token ? token.split(".")[1] : null;
        if (encodedPayload) {
            encodedPayload = encodedPayload.replace(/-/g, "+").replace(/_/g, "/");
            const payload = JSON.parse(window.atob(encodedPayload));
            return payload?.exp ? payload?.exp * 1000 : 0;
        }
        return 0;
    }
  }
  
  export default Config;
  