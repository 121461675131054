import React, { FC, useState } from 'react';
import AuthProps from '../common/AuthProps';
import ContentHeadingPage from './ContentHeadings/ContentHeadingPage';
import ContentBodyPage from './ContentBody/ContentBodyPage';
import ContentActionPage from './ContentAction/ContentActionPage';

const ContentMain: FC<AuthProps> = (authProps) => {

    const [openTab, setOpenTab] = useState(1);

    return (

        <>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <ul
                        className="flex mb-1 list-none flex-wrap pt-1 pb-2 flex-row"
                        role="tablist"
                    >
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                            <a className={
                                "text-xs font-bold uppercase px-5 py-1 shadow-sm rounded-xs block leading-normal " +
                                (openTab === 1
                                    ? "text-white bg-[#A7CE3C]"
                                    : "text-gray-400 bg-white")
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(1);
                                }}
                                data-toggle="tab"
                                href="#link1"
                                role="tablist"
                            >
                                Headings
                            </a>
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                            {authProps.auth.token.features?.map(a => a.id).includes('2ofde0d3b4') &&
                                <a
                                    className={
                                    "text-xs font-bold uppercase px-5 py-1 shadow-lg rounded-xs block leading-normal " +
                                    (openTab === 2
                                        ? "text-white bg-[#A7CE3C]"
                                        : "text-gray-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(2);
                                    }}
                                    data-toggle="tab"
                                    href="#link2"
                                    role="tablist"
                                >
                                    Body
                                </a>}
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                            {authProps.auth.token.features?.map(a => a.id).includes('9jd8spvfsb') &&
                                <a
                                    className={
                                    "text-xs font-bold uppercase px-5 py-1 shadow-lg rounded-xs block leading-normal " +
                                    (openTab === 3
                                        ? "text-white bg-[#A7CE3C]"
                                        : "text-gray-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(3);
                                    }}
                                    data-toggle="tab"
                                    href="#link3"
                                    role="tablist"
                                >
                                    Action
                                </a>}
                        </li>
                    </ul>
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-sm">
                        <div className="px-0 py-0 flex-auto">
                            <div className="tab-content tab-space">
                                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                    <ContentHeadingPage auth={authProps.auth} config={authProps.config} />
                                </div>
                                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                                    <ContentBodyPage auth={authProps.auth} config={authProps.config} />
                                </div>
                                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                                    <ContentActionPage auth={authProps.auth} config={authProps.config} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  );
};

export default ContentMain;