import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { AccountType } from './accountTypeSlice';

export type AccountParams = {
    authProps: AuthProps;
    account?: Account;
    accountId?: string;
}

export type Account = {
    id: string;
    code: string;
    name: string;
    accountTypeId: string;
    accountType?: AccountType;
    accountId: string;
    creationDate?: string;
    edit?: void;
    delete?: void;
}

interface AccountState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: string | null;
    list: Account[];
}

interface FetchErrors {
    message: string;
};

const initialState: AccountState = {
    list: [],
    error: null,
    status: "idle",
};

export const fetchAccounts = 
    createAsyncThunk<Account[], AuthProps, { rejectValue: FetchErrors }>(
    "accounts/fetchByAccountId",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.ACCOUNT_URL +
                "/" + params.config.ACCOUNT_ID, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch accounts",
            });
        }
        return response.json();
    }
);

export const addAccount =
    createAsyncThunk<Account, AccountParams, { rejectValue: FetchErrors }>(
    "account/add",
    async (params: AccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.ACCOUNT_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.account),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editAccount =
    createAsyncThunk<Account, AccountParams, { rejectValue: FetchErrors }>(
    "account/edit",
    async (params: AccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.ACCOUNT_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.account),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteAccount =
    createAsyncThunk<Account, AccountParams, { rejectValue: FetchErrors }>(
    "account/delete",
    async (params: AccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.ACCOUNT_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.account),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
        clearAccounts: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchAccounts.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addAccount.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editAccount.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteAccount.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchAccounts.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addAccount.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editAccount.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteAccount.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchAccounts.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectAccountStatus = (state: RootState) => state.accounts.status;
export const selectAccounts = (state: RootState) => state.accounts.list;
export const selectAccountCount = (state: RootState) => state.accounts.list.length;
export const selectAccountError = (state: RootState) => state.accounts.error;
export const { clearError, clearAccounts } = accountSlice.actions;
export default accountSlice.reducer;