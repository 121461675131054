import React, { FC, useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { ContentAction, fetchContentActions, selectContentActionError, 
    selectContentActions, selectContentActionStatus,
    clearError, deleteContentAction, ContentActionParams } from '../../../store/contentActionSlice';
import AuthProps from '../../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import AddEditContentAction from './AddEditContentAction';
import { selectContentHeadings, fetchContentHeadings } from '../../../store/contentHeadingSlice';
import Select from '../../common/Select';

const ContentActionPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const contentHeadings = useAppSelector(selectContentHeadings);
    const contentActions = useAppSelector(selectContentActions);
    const status = useAppSelector(selectContentActionStatus);
    const contentActionError = useAppSelector(selectContentActionError);

    const [contentHeadingId, setContentHeadingId] = useState<string>("");
    const [contentAction, setContentAction] = useState<ContentAction>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchContentHeadings(authProps));
        return () => controller.abort();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setContentAction(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, contentAction: ContentAction) => {
    	e.preventDefault();
        dispatch(clearError(null));
    	setContentAction(contentAction);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, contentAction: ContentAction) => {
		e.preventDefault();
        dispatch(clearError(null));
    	setContentAction(contentAction);
        console.log(`contentAction ${JSON.stringify(contentAction)}`);
    	const params: ContentActionParams = {
        	authProps: authProps,
        	contentAction: contentAction,
      	}
    	dispatch(deleteContentAction(params))
 	};

    const columns: Column<ContentAction>[] = useMemo(() => [
        {
          Header: "Action",
          accessor: "action"
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Priority
                </div>
            ),
            accessor: "priority",
            Cell: (props) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        {Number(props.value)}
                    </div>
                );
            },
        },
        {
            Header: "Path",
            accessor: "path"
        },
        {
            Header: "Public",
            accessor: "showPublic",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        <input
                            type="checkbox"
                            checked={row.original.showPublic}/>
                    </div>
                );
            }
        },
        {
            Header: "Private",
            accessor: "showPrivate",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        <input
                            type="checkbox"
                            checked={row.original.showPrivate}/>
                    </div>
                );
            }
        },
        {
            Header: "Disabled",
            accessor: "disabled",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        <input
                            type="checkbox"
                            checked={row.original.disabled}/>
                    </div>
                );
            }
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div className="items-center margin: auto">
                    {authProps.auth.token.features?.map(a => a.id).includes('pcq193807h') &&
                      <button
                          onClick={e => inLineEdit(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg" 
                              className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                      </button>}
                  </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                    {authProps.auth.token.features?.map(a => a.id).includes('pcq193807h') &&
                      <button
                          onClick={e => inLineDelete(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                          </svg>
                      </button>}
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[contentActions]);

    return (
        <div className="flex flex-col w-auto">
            {
                <div className="flex relative p-1 w-auto">
                    <Select
                        id="contentHeadingId"
                        placeHolder="Select Heading"
                        className="shadow-sm border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        selectList={
                            contentHeadings.map(contentHeading => {
                                return {id: contentHeading.id, name: contentHeading.heading}
                            })}
                        required={true}
                        onChange={e => {
                            setContentHeadingId(e.target.value);
                            const params: ContentActionParams = {
                                authProps: authProps,
                                contentHeadingId: e.target.value,
                            }
                            dispatch(fetchContentActions(params));
                        }} />
                </div>
            }
            {status === "loading" ? (<div className="pl-2">Loading...</div>) : (
                <React.Fragment>
                    {contentActionError &&
                    <span
                        className="text-red-500"
                        style={{ display: contentActionError ? "block" : "none" }}>
                        {contentActionError}
                    </span>}
                    <div>
                        {contentActions &&
                            <React.Fragment>
                                <div className="hidden md:block">
                                    <WrappedTable<ContentAction>
                                        name="Action"
                                        columns={columns}
                                        data={contentActions}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('fkr593mi4m')}
                                    /> 
                                </div>
                                <div className="md:hidden">
                                    <WrappedTable<ContentAction>
                                        name="Action"
                                        columns={columns}
                                        data={contentActions}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('fkr593mi4m')}
                                    />
                                </div>
                            </React.Fragment>}
                    </div>
                    <div>
                        {showAddEdit &&
                            contentHeadingId &&
                                <AddEditContentAction 
                                    authProps={authProps} 
                                    setShowAddEdit={setShowAddEdit}
                                    contentHeading={contentHeadings.find( ({ id }) => id === contentHeadingId)}
                                    contentAction={contentAction}
                                />}
                    </div>
                </React.Fragment>)};
        </div>
    );
}

export default ContentActionPage;