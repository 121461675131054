import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { Process } from './processSlice';
import { Feature } from './featureSlice';

export type RoleFeatureParams = {
    authProps: AuthProps;
    role?: string;
}

export type RoleFeature = {
    feature: Feature;
    process: Process;
    action: string;
    path: string;
    app: string;
}

interface RoleFeatureState {
    status: "loading" | "idle";
    error: string | null;
    list: RoleFeature[];
}

interface FetchErrors {
    message: string;
}

const initialState: RoleFeatureState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchRoleFeatures = 
    createAsyncThunk<RoleFeature[], RoleFeatureParams, { rejectValue: FetchErrors }>(
    "roleFeatures/fetchByRole",
    async (params: RoleFeatureParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.SYSTEM_ROLEFEATURES_URL +
               "/" + params.role , {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch role features",
            });
        }
        return response.json();
    }
)

export const roleFeatureSlice = createSlice({
    name: 'roleFeature',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchRoleFeatures.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchRoleFeatures.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchRoleFeatures.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectRoleFeatureStatus = (state: RootState) => state.roleFeatures.status;
export const selectRoleFeatures = (state: RootState) => state.roleFeatures.list;
export const selectRoleFeatureError = (state: RootState) => state.roleFeatures.error;
export const { clearError } = roleFeatureSlice.actions;
export default roleFeatureSlice.reducer;