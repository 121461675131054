import React, { FC, useState, SetStateAction } from 'react';
import { selectLoanError, Loan, LoanParams, addLoan,
    editLoan, clearLoanError } from '../../../store/loanSlice';
import AuthProps from '../../common/AuthProps';
import LoanCategory from '../../common/Transactions/LoanCategory';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import useToast from '../../../hooks/useToast';
import { grayedOutButtonClass, enabledButtonClass } from '../../../shared/Utils';
import Amount from '../../common/Transactions/Amount';
import RepaymentPeriod from '../../common/Transactions/RepaymentPeriod';
import LoanStatus from '../../common/Transactions/LoanStatus';
import Member from '../../common/Transactions/Member';
import Name from '../../common/Transactions/Name';

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    loan?: Loan | undefined;
}

const AddEditRegularLoan: FC<Props> = ({ authProps, setShowAddEdit, loan }) => {

    const toast = useToast();
    const dispatch = useAppDispatch();

    const [editMode] = useState<boolean>(!!loan);

    const [memberId, setMemberId] = useState<string>(editMode ? loan.memberId : "");

    const [name, setName] = useState<string>(editMode ? loan.name : "");
    const [nameError, setNameError] = useState<string>("");

    const [categoryId, setCategoryId] = useState<string>(editMode ? loan.calculatorId : "");

    const [repaymentPeriod, setRepaymentPeriod] = useState<number>(editMode ? loan.repaymentPeriod : 0);
    const [repaymentPeriodError, setRepaymentPeriodError] = useState<string>("");

    const [amount, setAmount] = useState<string>(editMode ? loan.amount.toString() : "0");
    const [amountError, setAmountError] = useState<string>("");

    const [loanStatusReasonId, setLoanStatusReasonId] = useState<string>(editMode ? loan.loanStatusReasonId : "");

    const loanError = useAppSelector(selectLoanError);

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updatedLoan: Loan = {
            id: editMode ? loan.id : "",
            ref: "",
            name: name,
            memberId: memberId,
            categoryId: categoryId,
            amount: Number(amount),
            repaymentPeriod: repaymentPeriod,
            interestChargeable: 0,
            creditLifeInsurance: 0,
            adminFee: 0,
            loanStatusReasonId: loanStatusReasonId,
            accountId: authProps.config.ACCOUNT_ID,
            makerId: authProps.auth.token.userId,
        }
        const params: LoanParams = {
            authProps: authProps,
            loan: updatedLoan,
        }

        const result = await dispatch(editMode ? editLoan(params) : addLoan(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            toast('success', editMode ? `Loan edited successfully` : `Loan added successfully`);
        }

        if (!errorCondition) {
            setShowAddEdit(false);
        }
    }

    const isInvalid =
        name === ""
        || categoryId === ""
        || Number(amount) < 0
        || repaymentPeriod < 0
        || loanStatusReasonId === "";

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                        
                    <div className="flex items-center md:px-4 py-2">
                        <div className="relative sm:w-full p-2 mx-auto bg-white rounded-md shadow-lg">
                                

                            <h2 className="text-center text-2xl font-normal text-gray-900">
                                {editMode ? `Edit: ${loan.name}` : `Create a new loan`}
                            </h2>

                            <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                <div className="rounded-md shadow-sm -space-y-px">

                                    <span
                                        className="text-red-500"
                                        style={{ display: loanError ? "block" : "none" }}>
                                        {loanError}
                                    </span>

                                    <Member
                                        authProps={authProps}
                                        setMemberId={setMemberId}
                                        memberId={memberId} />

                                    <LoanCategory 
                                        authProps={authProps}
                                        setCategoryId={setCategoryId}
                                        categoryId={categoryId} />

                                    <Name
                                        setName={setName}
                                        name={name}
                                        setNameError={setNameError}
                                        nameError={nameError}
                                        maxLength={20} />

                                    <Amount
                                        setAmount={setAmount}
                                        amount={amount}
                                        setAmountError={setAmountError}
                                        amountError={amountError}
                                        placeholderText="loan amount (>= 0)"
                                        maxLengthValue={8} />

                                    <RepaymentPeriod
                                        setRepaymentPeriod={setRepaymentPeriod}
                                        repaymentPeriod={repaymentPeriod}
                                        setRepaymentPeriodError={setRepaymentPeriodError}
                                        repaymentPeriodError={repaymentPeriodError} />

                                    <LoanStatus
                                        authProps={authProps}
                                        setLoanStatusReasonId={setLoanStatusReasonId}
                                        loanStatusReasonId={loanStatusReasonId} />

                                    
                                </div>
                                <div className="flex items-center justify-between">
                                    <div>
                                    <button
                                        type="submit"
                                        disabled={isInvalid}
                                        className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                        <span className="flex items-center">
                                            {editMode ? "Edit" : "Create"}
                                        </span>
                                    </button>
                                    </div>
                                    <div className="text-sm">
                                        <button
                                            type="submit"
                                            onClick={cancel}
                                            className="transition-colors hover:text-gray-900 font-medium duration-200">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>

                        </div>
                        
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AddEditRegularLoan;