import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { ReportingClass } from './reportingClassSlice';

export type PrimaryAccountParams = {
    authProps: AuthProps;
    primaryAccount?: PrimaryAccount;
    accountId?: string;
}

export type PrimaryAccount = {
    id: string;
    code: string;
    name: string;
    accountId: string;
    reportingClassId: string;
    reportingClass?: ReportingClass;
    creationDate?: string;
    edit?: void;
    delete?: void;
}

interface PrimaryAccountState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: string | null;
    list: PrimaryAccount[];
}

interface FetchErrors {
    message: string;
};

const initialState: PrimaryAccountState = {
    list: [],
    error: null,
    status: "idle",
};

export const fetchPrimaryAccountsByAccount = 
    createAsyncThunk<PrimaryAccount[], PrimaryAccountParams, { rejectValue: FetchErrors }>(
    "primaryAccounts/fetchByAccount",
    async (params: PrimaryAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.PRIMARYACCOUNT_URL
                + "/account/" + params.accountId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch primary accounts",
            });
        }
        return response.json();
    }
);

export const addPrimaryAccount =
    createAsyncThunk<PrimaryAccount, PrimaryAccountParams, { rejectValue: FetchErrors }>(
    "primaryAccount/add",
    async (params: PrimaryAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.PRIMARYACCOUNT_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.primaryAccount),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editPrimaryAccount =
    createAsyncThunk<PrimaryAccount, PrimaryAccountParams, { rejectValue: FetchErrors }>(
    "primaryAccount/edit",
    async (params: PrimaryAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.PRIMARYACCOUNT_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.primaryAccount),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deletePrimaryAccount =
    createAsyncThunk<PrimaryAccount, PrimaryAccountParams, { rejectValue: FetchErrors }>(
    "primaryAccount/delete",
    async (params: PrimaryAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.PRIMARYACCOUNT_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.primaryAccount),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const primaryAccountSlice = createSlice({
    name: 'primaryAccount',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
        clearPrimaryAccounts: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchPrimaryAccountsByAccount.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addPrimaryAccount.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editPrimaryAccount.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deletePrimaryAccount.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchPrimaryAccountsByAccount.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addPrimaryAccount.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editPrimaryAccount.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deletePrimaryAccount.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchPrimaryAccountsByAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addPrimaryAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editPrimaryAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deletePrimaryAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectPrimaryAccountStatus = (state: RootState) => state.primaryAccounts.status;
export const selectPrimaryAccounts = (state: RootState) => state.primaryAccounts.list;
export const selectPrimaryAccountCount = (state: RootState) => state.primaryAccounts.list.length;
export const selectPrimaryAccountError = (state: RootState) => state.primaryAccounts.error;
export const { clearError, clearPrimaryAccounts } = primaryAccountSlice.actions;
export default primaryAccountSlice.reducer;