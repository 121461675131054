import React, { FC, SetStateAction, useEffect, useState } from 'react';
import AuthProps from '../AuthProps';
import { useAppSelector, useAppDispatch } from '../hooks';
import Select from '../../common/Select';
import { selectTransactionCodes, fetchTransactionCodesByBookAndType,
    TransactionCodeParams, 
    fetchTransactionCodesByBook} from '../../../store/transactionCodeSlice';


interface Props {
    authProps: AuthProps;
    setTransactionCodeId: React.Dispatch<SetStateAction<string>>;
    transactionCodeId: string;
    book: string;
    transactionCodeTypeId?: string;
    setCode?: React.Dispatch<SetStateAction<string>>;
    setName?: React.Dispatch<SetStateAction<string>>;
    setTypeName?: React.Dispatch<SetStateAction<string>>;
    isInvalid: boolean;
}

const TransactionCode: FC<Props> = ({ authProps, setTransactionCodeId, transactionCodeId,
    book, transactionCodeTypeId, setCode, setName, setTypeName, isInvalid }) => {

    const dispatch = useAppDispatch();

    const preSelectTransactionCode = [{'id':'1111111111','name':'Select code'}];
    const transactionCodes = useAppSelector(selectTransactionCodes);

    useEffect(() => {
        const controller = new AbortController();
        const params: TransactionCodeParams = {
            authProps: authProps,
            book: book,
            transactionCodeTypeId: transactionCodeTypeId,
        }
        if (transactionCodeTypeId !== undefined && transactionCodeTypeId !== "") {
            dispatch(fetchTransactionCodesByBookAndType(params));
        } else {
            dispatch(fetchTransactionCodesByBook(params));
        }
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div className="grid grid-cols-3 w-full">

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="transactionCodeId">
                Transaction Code
            </label>
            
            <div className="col-span-2">
                <Select
                    id="transactionCodeId"
                    className="p-1"
                    placeHolder={"Select"}
                    selectList={
                        preSelectTransactionCode.map(select => {
                            return {id: select.id, name: select.name}
                        }).concat(
                            transactionCodes.map(transactionCode => {
                                return { id: transactionCode.id, name: `${transactionCode.code} ${transactionCode.name} 
                                (${transactionCode.transactionCodeType.name})`}
                    }))}
                    required={true}
                    disabled={isInvalid}
                    onChange={e => {
                        setTransactionCodeId(e.target.value);
                        var tranCode = transactionCodes.find( ({ id }) => id === e.target.value);
                        setCode(tranCode.code);
                        setName(tranCode.name);
                        setTypeName(tranCode.transactionCodeType.name);
                    }} />
            </div>

        </div>
    );
}

export default TransactionCode;