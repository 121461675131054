import React, { FC, useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { Bank, fetchBanks, selectBankError, selectBanks, selectBankStatus,
    clearError, deleteBank, BankParams } from '../../store/bankSlice';
import AuthProps from '../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../common/Table/WrappedTable';
import AddEditBank from './AddEditBank';

const BankPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const banks = useAppSelector(selectBanks);
    const status = useAppSelector(selectBankStatus);
    const bankError = useAppSelector(selectBankError);

    const [bank, setBank] = useState<Bank>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchBanks(authProps));
        return () => controller.abort();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setBank(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, bank: Bank) => {
    	e.preventDefault();
        dispatch(clearError(null));
    	setBank(bank);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, bank: Bank) => {
		e.preventDefault();
        dispatch(clearError(null));
    	setBank(bank);
        console.log(`bank ${JSON.stringify(bank)}`);
    	const params: BankParams = {
        	authProps: authProps,
        	bank: bank,
      	}
    	dispatch(deleteBank(params))
 	};

    const columns: Column<Bank>[] = useMemo(() => [
        {
          Header: "Name",
          accessor: "name"
        },
        {
            Header: "Code",
            accessor: "code"
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div className="items-center margin: auto">
                        {authProps.auth.token.features?.map(a => a.id).includes('lde7b27piq') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>}
                  </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('ig2krpm231') &&
                            <button
                                onClick={e => inLineDelete(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </button>}
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[banks]);

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
            <React.Fragment>
                {bankError &&
                <span
                    className="text-red-500"
                    style={{ display: bankError ? "block" : "none" }}>
                    {bankError}
                </span>}
                <div>
                    {banks &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<Bank>
                                    name="Banks"
                                    columns={columns}
                                    data={banks}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('p2n78me57t')}
                                /> 
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<Bank>
                                    name="Banks"
                                    columns={columns}
                                    data={banks}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('p2n78me57t')}
                                />
                            </div>
                        </React.Fragment>}
                </div>
                <div>
                    {showAddEdit &&
                        <AddEditBank 
                            authProps={authProps} 
                            setShowAddEdit={setShowAddEdit}
                            bank={bank}
                        />}
                </div>
            </React.Fragment>
    );
}

export default BankPage;