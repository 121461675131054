import React, { FC, SetStateAction, useEffect, useState } from 'react';
import AuthProps from '../AuthProps';
import { useAppSelector, useAppDispatch } from '../hooks';
import Select from '../../common/Select';
import { selectBankAccounts, fetchBankAccounts } from '../../../store/bankAccountSlice';


interface Props {
    authProps: AuthProps;
    setBankAccountId: React.Dispatch<SetStateAction<string>>;
    bankAccountId: string;
}

const BankAccount: FC<Props> = ({ authProps, setBankAccountId, bankAccountId }) => {

    const dispatch = useAppDispatch();

    const preSelectBankAccount = [{'id':'1111111111','name':'Select bank account'}];
    const bankAccounts = useAppSelector(selectBankAccounts);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchBankAccounts(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div className="grid grid-cols-3 w-full">

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="bankAccountId">
                Account
            </label>
            
            <div className="col-span-2">
                <Select
                    id="bankAccountId"
                    className="p-1"
                    placeHolder={"Select"}
                    selectList={
                        preSelectBankAccount.map(select => {
                            return {id: select.id, name: select.name}
                        }).concat(
                            bankAccounts.map(bankAccount => {
                                return { id: bankAccount.id, name: `${bankAccount.bankBranch.bank.name} ${bankAccount.bankBranch.name} ${bankAccount.accountNumber}`}
                    }))}
                    required={true}
                    disabled={bankAccountId === "1111111111"}
                    onChange={e => {
                        setBankAccountId(e.target.value);
                    }} />
            </div>

        </div>
    );
}

export default BankAccount;