import React, { FC, useState } from 'react';
import { useNavigate } from "react-router-dom";
import AuthProps from '../common/AuthProps';

import homePageIcon from '../../assets/menu/home-1-svgrepo-com.svg';
import membersIcon from '../../assets/menu/facebook-group-svgrepo-com.svg';
import contentIcon from '../../assets/menu/content-folder-open-folder.svg';
// import defaultIcon from '../../assets/menu/withdraw-dollar-sign-svgrepo-com.svg';
import banksIcon from '../../assets/menu/bank-svgrepo-com.svg'
import systemIcon from '../../assets/menu/system-settings-backup-svgrepo-com.svg';
import loansIcon from '../../assets/menu/loan-interest-time.svg';
import membersAccountsIcon from '../../assets/menu/team-svgrepo-com.svg';
import transactionsIcon from '../../assets/menu/mobile-atm-svgrepo-com.svg';
import accountsIcon from '../../assets/menu/accounts-folders-svgrepo-com.svg';
import financialStatementsIcon from '../../assets/menu/business-financial-chart-svgrepo-com.svg';
import reportsIcon from '../../assets/menu/report-text-svgrepo-com.svg';
import communicationsIcon from '../../assets/menu/communication-contact-e-mail.svg';
import auditorsIcon from '../../assets/menu/audit-check-glass-svgrepo-com.svg';
import guarantorshipsIcon from '../../assets/menu/guarantor-give-guarantee-give-warranty-svgrepo-com.svg';
import vikundiIcon from '../../assets/menu/groups-svgrepo-com.svg';
import lifeInsuranceIcon from '../../assets/menu/family-insurance-symbol-svgrepo-com.svg';

import { enabledButtonClass } from '../../shared/Utils';

const Sidebar: FC<AuthProps> = (authProps) => {

	const navigate = useNavigate();

	const [currentSelected, setCurrentSelected] = useState<number>(0);
	const onChangeCurrentSelected = (e: React.MouseEvent<HTMLButtonElement>, 
		index: number, url: string) => {
		setCurrentSelected(index);
		navigate(url);
	}
	
	//useEffect(() => {
	//	console.log(`..features: ${JSON.stringify(authProps.auth.token.features)}`);
	//},[]);

	const localIcon = (id: string) => {
		switch (id) {
			case "s6p43uxe7r":
				return homePageIcon;
			case "m3kjpy6s7n":
				return membersIcon;
			case "n9qwa53xvm":
				return contentIcon;
			case "j68fek7tyg":
				return banksIcon;
			case "grq2fbsn4u":
				return systemIcon;
			case "p47nrv9ekb":
				return membersAccountsIcon;
			case "gsrvx643tn":
				return transactionsIcon;
			case "c4a3du5g6q":
				return accountsIcon;
			case "ma467nivp5":
				return guarantorshipsIcon;
			case "e1tbhe6tit":
				return vikundiIcon;
			case "hk7cmkj81q":
				return lifeInsuranceIcon;
			case "pfb76jxsk2":
				return financialStatementsIcon;
			case "c5k3s4mb7q":
				return reportsIcon;
			case "q9e8zmhsgb":
				return communicationsIcon;
			case "mugnkfe4yq":
				return auditorsIcon;
			default:
				return "";
		}
	}

	return (
		<div>
			<nav className="bg-[#edf5d6]">
				<React.Fragment>
					{authProps.auth.token.userMenu?.map((menu, index) => {
						const { id, url, feature } = menu;
						return (
							<button
								key={id}
								onClick={(e) => onChangeCurrentSelected(e, index, url)}
								className={`capitalize flex items-center align-bottom px-1 py-1 w-48
								${
									index === currentSelected
										? 'bg-[#b7e8fb] text-white'
										: null
								} ${
									index >= 0
										? 'mt-2 text-blue-900 hover:bg-[#b7e8fb] hover:text-gray-700 transition-colors duration-200 transform'
										: null
								} rounded-sm`}>
								
								<div className="flex items-left justify-between ml-2 w-full shadow-sm">
									<span className="flex flex-row items-center text-xs font-normal mx-1 mt-1">
										<img
											className='h-6 w-6 mr-2'
											src={localIcon(id)}
											alt='' />
										{feature}
									</span>
								</div>
							</button>
						)
					})}

					<div className="text-xs font-light text-center text-wrap pt-3">
						<h2>
							&copy; Sasco Sacco 2014-2024
						</h2>
					</div>
					{Notification.permission !== "granted" && 
						<div className="flex items-center justify-center p-2">
							<button className={enabledButtonClass}
								onClick={() => {
									Notification.requestPermission();
								}}>
								Show notifications
							</button>
						</div>}
				</React.Fragment>
			</nav>
		</div>
	);
};

export default Sidebar;
