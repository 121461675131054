import React, { FC } from 'react';
import { Route, Routes } from "react-router-dom";
import AccountMain from './Accounts/AccountMain';
import BankMain from './Banks/BankMain';
import AuthProps from './common/AuthProps';
import LandingPage from './Landing/LandingPage';
import MemberMain from './Members/MemberMain';
import ReportPage from './Reports/ReportPage';
import ContentMain from './Content/ContentMain';
import SystemMain from './System/SystemMain';
import EmailMain from './Email/EmailMain';
import TransactionMain from './Transactions/TransactionMain';
import FinancialMain from './FinancialStatements/FinancialMain';
import MemberAccountMain from './MemberAccounts/MemberAccountMain';
import GuarantorshipsMain from './Guarantorships/GuarantorshipsMain';
import VikundiMain from './Vikundi/VikundiMain';
import LifeInsuranceMain from './LifeInsurance/LifeInsuranceMain';

const Container: FC<AuthProps> = (authProps) => {

    const landingPageComponent = () => <LandingPage />;

    return (
        <main className="md:ml-48">
            <Routes>
                <Route 
                    path="/" 
                    element={landingPageComponent()} />
                <Route
                    path="*"
                    element={landingPageComponent()} />
                <Route 
                    path="/members" 
                    element={<MemberMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/accounts" 
                    element={<AccountMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/guarantorships" 
                    element={<GuarantorshipsMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/vikundi" 
                    element={<VikundiMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/life-insurance" 
                    element={<LifeInsuranceMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/banks" 
                    element={<BankMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/content" 
                    element={<ContentMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/system" 
                    element={<SystemMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/members-accounts" 
                    element={<MemberAccountMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/email" 
                    element={<EmailMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/transactions" 
                    element={<TransactionMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/financials" 
                    element={<FinancialMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/reports" 
                    element={<ReportPage auth={authProps.auth} config={authProps.config} />} />
            </Routes>
        </main>
    );
}

export default Container;