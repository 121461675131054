import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { Member } from './memberSlice';
import { Loan } from './loanSlice';

export type MemberLoanTransaction = {
    id?: string;
    memberLoanEntryId?: string;
    memberId: string;
    member?: Member;
    loanId: string;
    loan?: Loan;
    amount: number;
    edit?: void;
    delete?: void;
    memberName?: string;
    loanRef?: string;
    loanName?: string;
}

interface MemberLoanTransactionState {
    status: "loading" | "idle";
    error: any;
    list: MemberLoanTransaction[];
}

interface FetchErrors {
    message: string;
}

const initialState: MemberLoanTransactionState = {
    list: [],
    error: null,
    status: "idle",
}

export const memberLoanTransactionSlice = createSlice({
    name: 'memberLoanTransaction',
    initialState,
    reducers: {
        clearMemberLoanTransactionList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearMemberLoanTransactionError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
        addVirtualMemberLoanTransaction: (state, { payload }) => {
            if (!state.list.some( ({ id }) => id === payload.id )) {
                state.list.unshift(payload);
            }
            state.status = "idle";
        },
        editVirtualMemberLoanTransaction: (state, { payload }) => {
            const index: number = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
        },
        deleteVirtualMemberLoanTransaction: (state, { payload }) => {
            const index: number = state.list.findIndex( ({ id }) => id === payload.id );
            state.list = [...state.list.slice(0, index),
                ...state.list.slice(index + 1)];
        }
     },
});

export const selectMemberLoanTransactionStatus = (state: RootState) => state.memberLoanTransactions.status;
export const selectMemberLoanTransactions = (state: RootState) => state.memberLoanTransactions.list;
export const selectMemberLoanTransactionError = (state: RootState) => state.memberLoanTransactions.error;
export const { clearMemberLoanTransactionList, clearMemberLoanTransactionError,
    addVirtualMemberLoanTransaction, editVirtualMemberLoanTransaction, deleteVirtualMemberLoanTransaction } = memberLoanTransactionSlice.actions;
export default memberLoanTransactionSlice.reducer;