import React, { FC, useState, SetStateAction } from 'react';
import AuthProps from '../common/AuthProps';
import { Member } from '../../store/memberSlice';
import { selectMemberRegistrationStatusError, clearError, 
    MemberRegistrationStatus, MemberRegistrationParams, 
    editMemberRegistration} from '../../store/memberRegistrationStatusSlice';
import { grayedOutButtonClass, enabledButtonClass } from '../../shared/Utils';
import { useAppSelector, useAppDispatch } from '../common/hooks';
import Select from '../common/Select';
import useToast from '../../hooks/useToast';

interface Props {
    authProps: AuthProps;
    setShowEditRegistration: React.Dispatch<SetStateAction<boolean>>;
    member: Member;
}

const EditRegistrationStatus: FC<Props> = ({ authProps,
    setShowEditRegistration, member }) => {

    const toast = useToast();
    const dispatch = useAppDispatch();

    const memberRegistrationError = useAppSelector(selectMemberRegistrationStatusError);
    const [registrationStatus, setRegistrationStatus] = useState<string>("");

    const statuses = [
        {'id':'REGISTERED', 'name':'REGISTERED'},
        {'id':'IN_PROGRESS', 'name':'IN PROGRESS'},
        {'id':'REJECTED', 'name':'REJECTED'},
        {'id':'UNREGISTERED', 'name':'UNREGISTERED'},
    ];

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowEditRegistration(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updated: MemberRegistrationStatus = {
            userId: authProps.auth.token.userId,
            memberId: member.id,
            registrationStatus: registrationStatus,
        }
        const params: MemberRegistrationParams = {
            authProps: authProps,
            memberRegistrationStatus: updated,
        }

        const result = await dispatch(editMemberRegistration(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            toast('success', 'Member registration changed successfully');
            setShowEditRegistration(false);
        }

    }

    const isInvalid =
        registrationStatus === "";

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                 {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">

                    <div className="sm:flex sm:items-start">
                        <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                            <div className="max-w-md w-full space-y-4">
                                <h2 className="text-center text-1xl font-normal text-gray-900">
                                    {`Change registration status for: ${member.surname}, ${member.firstName} ${member.middleName}`}
                                </h2>

                                <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                    <div className="rounded-md shadow-sm -space-y-px">

                                        <span
                                            className="text-red-500"
                                            style={{ display: memberRegistrationError ? "block" : "none" }}>
                                            {memberRegistrationError}
                                        </span>

                                        <Select
                                            id="registryStatusId"
                                            placeHolder="Select Registration Status"
                                            className="shadow-md border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            selectList={statuses?.map(status => {
                                                return { id: status.id, name: status.name }})}
                                            required={true}
                                            onChange={(e) => {
                                                dispatch(clearError(null));
                                                setRegistrationStatus(e.target.value);
                                            }} />
                                    </div>

                                    <div className="flex items-center justify-between">
                                        <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="flex items-center">
                                                    Change
                                                </span>
                                            </button>
                                        </div>
                                        <div className="text-sm">
                                            <button
                                                type="submit"
                                                onClick={cancel}
                                                className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>     
    );
};

export default EditRegistrationStatus;