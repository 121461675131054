import React, { FC, SetStateAction, useEffect, useState } from 'react';
import AuthProps from '../AuthProps';
import { useAppSelector, useAppDispatch } from '../hooks';
import Select from '../../common/Select';
import { selectMobileMoneyAccounts, fetchMobileMoneyAccounts } from '../../../store/mobileMoneyAccountSlice';


interface Props {
    authProps: AuthProps;
    setMobileMoneyAccountId: React.Dispatch<SetStateAction<string>>;
    mobileMoneyAccountId: string;
}

const MobileMoneyAccount: FC<Props> = ({ authProps, setMobileMoneyAccountId, mobileMoneyAccountId }) => {

    const dispatch = useAppDispatch();

    const preSelectMobileMoneyAccount = [{'id':'1111111111','name':'Select mobile account'}];
    const mobileMoneyAccounts = useAppSelector(selectMobileMoneyAccounts);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchMobileMoneyAccounts(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div className="grid grid-cols-3 w-full">

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="mobileMoneyAccountId">
                Account
            </label>
            
            <div className="col-span-2">
                <Select
                    id="mobileMoneyAccountId"
                    className="p-1"
                    placeHolder={"Select"}
                    selectList={
                        preSelectMobileMoneyAccount.map(select => {
                            return {id: select.id, name: select.name}
                        }).concat(
                            mobileMoneyAccounts.map(mobileMoneyAccount => {
                                return { id: mobileMoneyAccount.id, name: `${mobileMoneyAccount.mobileMoneyProvider} ${mobileMoneyAccount.areaCode} ${mobileMoneyAccount.phoneNumber}`}
                    }))}
                    required={true}
                    disabled={mobileMoneyAccountId === "1111111111"}
                    onChange={e => {
                        setMobileMoneyAccountId(e.target.value);
                    }} />
            </div>

        </div>
    );
}

export default MobileMoneyAccount;