import React, { FC, useEffect, useState, useMemo } from 'react';
import AuthProps from '../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { PrimaryAccountParams, selectPrimaryAccountError, selectPrimaryAccounts, 
    selectPrimaryAccountStatus, PrimaryAccount, fetchPrimaryAccountsByAccount,
    clearError, deletePrimaryAccount, clearPrimaryAccounts } from '../../store/primaryAccountSlice';
import { fetchAccounts, selectAccounts } from '../../store/accountSlice';
import { Column } from 'react-table';
import { WrappedTable } from '../common/Table/WrappedTable';
import IconButton from '../../shared/confirm/IconButton';
import AddEditPrimaryAccount from './AddEditPrimaryAccount';
import ConfirmDialog from '../../shared/confirm/Confirm';
import Select from '../common/Select';

const PrimaryAccountPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const status = useAppSelector(selectPrimaryAccountStatus);
    const primaryAccounts = useAppSelector(selectPrimaryAccounts);
    const primaryAccountError = useAppSelector(selectPrimaryAccountError);
    const accounts = useAppSelector(selectAccounts);

    const [accountId, setAccountId] = useState<string>("");
    const [primaryAccount, setPrimaryAccount] = useState<PrimaryAccount>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        if (authProps.auth.token.features?.map(a => a.id).includes('1pfs89uima')) {
            dispatch(fetchAccounts(authProps));
        }
        dispatch(clearPrimaryAccounts(null));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const onChangeAccount = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        setAccountId(e.target.value);
        const params: PrimaryAccountParams = {
            authProps: authProps,
            accountId: e.target.value
        }
        dispatch(fetchPrimaryAccountsByAccount(params));
    }

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setPrimaryAccount(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, primaryAccount: PrimaryAccount) => {
    	e.preventDefault();
        dispatch(clearError(null));
    	setPrimaryAccount(primaryAccount);
    	setShowAddEdit(true);
  	};

      const columns: Column<PrimaryAccount>[] = useMemo(() => [
        {
            Header: "Code",
            accessor: "code"
          },
        {
          Header: "Name",
          accessor: "name"
        },
        {
            Header: "Reporting Class",
            accessor: repClass => { return (repClass.reportingClass.name) }
        },
        {
          Header: () => (
              <div style={{ textAlign:"center" }}>
              </div>
          ),
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                     {authProps.auth.token.features?.map(a => a.id).includes('5l9uno1uhe') &&
                        <button
                            onClick={e => inLineEdit(e, row.original)}>
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                        </button>}
                  </div>
              );
          }

      },
      {
        Header: () => (
            <div style={{ textAlign:"center" }}>
            </div>
        ),
            accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('kesa6al9dd') &&
                            <IconButton aria-label="delete" 
                                onClick={() => {    
                                    setPrimaryAccount(row.original);
                                    setConfirmOpen(true);
                                }}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </IconButton>}
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[primaryAccounts]);

    const columnsSm: Column<PrimaryAccount>[] = useMemo(() => [
        {
            Header: "Account",
            Cell: ({ row }) => {
                return (
                    <React.Fragment>
                        {`${row.original.code} ${row.original.name} ${row.original.reportingClass.name}`}
                    </React.Fragment>
                );
            },
        },
        {
            Header: () => (
                <div style={{ textAlign:"center" }}></div>
            ),
            accessor: "edit",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                         {authProps.auth.token.features?.map(a => a.id).includes('5l9uno1uhe') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>}
                    </div>
              );
          }

      },
      {
        Header: () => (
            <div style={{ textAlign:"center" }}>
            </div>
          ),
        accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('kesa6al9dd') &&
                            <IconButton aria-label="delete" 
                                onClick={() => {    
                                    setPrimaryAccount(row.original)
                                    setConfirmOpen(true);
                                }}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </IconButton>}
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[primaryAccounts]);

    return (
        <div className="flex flex-col w-auto">
            <div className="flex items-center pt-1 pb-1">
                <div className="relative">
                    {authProps.auth.token.features?.map(a => a.id).includes('1pfs89uima') &&
                        <Select
                            id="accountId"
                            placeHolder="Select Account"
                            className="shadow-md border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            selectList={
                                accounts.map(account => {
                                    return {id: account.id, name: `${account.code} ${account.name}`}
                                })}
                            required={true}
                            onChange={onChangeAccount} />}
                </div>
            </div>
            {status === "loading" ? (<div className="pl-2">Loading...</div>) : (
                <React.Fragment>
                    {primaryAccountError &&
                    <span
                        className="text-red-500"
                        style={{ display: primaryAccountError ? "block" : "none" }}>
                        {primaryAccountError}
                    </span>}
                    <div>
                        {primaryAccounts &&
                            <React.Fragment>
                                <div className="hidden md:block">
                                    <WrappedTable<PrimaryAccount>
                                        name="Primary Accounts"
                                        columns={columns}
                                        data={primaryAccounts}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('pb4qien501')}
                                        customRowStyle='text-sm text-gray-900 font-normal px-2 py-0'
                                    /> 
                                </div>
                                <div className="md:hidden">
                                    <WrappedTable<PrimaryAccount>
                                        name="Primary Accounts"
                                        columns={columnsSm}
                                        data={primaryAccounts}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('pb4qien501')}
                                        customRowStyle='text-sm text-gray-900 font-normal px-2 py-0'
                                    />
                                </div>
                            </React.Fragment>}
                    </div>
                    <div>
                        {showAddEdit
                            && accountId &&
                            <AddEditPrimaryAccount 
                                authProps={authProps} 
                                setShowAddEdit={setShowAddEdit}
                                primaryAccount={primaryAccount}
                                account={accounts.find( ({ id }) => id === accountId)}
                            />}
                    </div>
                    <div className="text-sm">
                        <ConfirmDialog
                            title={`Are you sure you want to delete primary account ${primaryAccount?.name} ?`}
                            open={confirmOpen}
                            onClose={() => setConfirmOpen(false)}
                            onConfirm={() => {
                                    const params: PrimaryAccountParams = {
                                        authProps: authProps,
                                        primaryAccount: primaryAccount,
                                    };
                                    dispatch(deletePrimaryAccount(params));
                            }}>
                        </ConfirmDialog>
                    </div>
                </React.Fragment>)}
        </div>
    );

};

export default PrimaryAccountPage;