import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type Employer = {
    employer: string;
}

interface EmployerState {
    status: "loading" | "idle";
    error: string | null;
    list: Employer[];
}

interface FetchErrors {
    message: string;
}

const initialState: EmployerState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchEmployers = 
    createAsyncThunk<Employer[], AuthProps, { rejectValue: FetchErrors }>(
    "employer/fetch",
    async (params: AuthProps, thunkApi) => {
        const response = 
            await fetch(params.config.PUBLIC_EMPLOYERS_URL, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.defaultHeaders(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch employers",
            });
        }
        return response.json();
    }
)

export const employerSlice = createSlice({
    name: 'employer',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchEmployers.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchEmployers.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchEmployers.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectEmployerStatus = (state: RootState) => state.employers.status;
export const selectEmployers = (state: RootState) => state.employers.list;
export const selectEmployerError = (state: RootState) => state.employers.error;
export const { clearError } = employerSlice.actions;
export default employerSlice.reducer;