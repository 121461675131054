import React, { FC, useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { TransactionCodeType, fetchTransactionCodeTypes, selectTransactionCodeTypeError, 
    selectTransactionCodeTypes, selectTransactionCodeTypeStatus,
    clearError, deleteTransactionCodeType, TransactionCodeTypeParams } from '../../../store/transactionCodeTypeSlice';
import AuthProps from '../../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import AddEditTransactionCodeType from './AddEditTransactionCodeType';

interface Props {
    authProps: AuthProps;
    book: string;
}

const TransactionCodeTypePage: FC<Props> = ({ authProps, book }) => {

    const dispatch = useAppDispatch();

    const transactionCodeTypes = useAppSelector(selectTransactionCodeTypes);
    const status = useAppSelector(selectTransactionCodeTypeStatus);
    const transactionCodeTypeError = useAppSelector(selectTransactionCodeTypeError);

    const [transactionCodeType, setTransactionCodeType] = useState<TransactionCodeType>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        const params: TransactionCodeTypeParams = {
            authProps: authProps,
            book: "MEMBER",
        }
        dispatch(fetchTransactionCodeTypes(params));
        return () => controller.abort();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setTransactionCodeType(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, transactionCodeType: TransactionCodeType) => {
    	e.preventDefault();
        dispatch(clearError(null));
    	setTransactionCodeType(transactionCodeType);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, transactionCodeType: TransactionCodeType) => {
		e.preventDefault();
        dispatch(clearError(null));
    	setTransactionCodeType(transactionCodeType);
       
    	const params: TransactionCodeTypeParams = {
        	authProps: authProps,
        	transactionCodeType: transactionCodeType,
      	}
    	dispatch(deleteTransactionCodeType(params))
 	};

    const columns: Column<TransactionCodeType>[] = useMemo(() => [
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div className="items-center margin: auto">
                        {authProps.auth.token.features?.map(a => a.id).includes('u869zvrbam') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>}
                  </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('t84usd5gfh') &&
                            <button
                                onClick={e => inLineDelete(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </button>}
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[transactionCodeTypes]);

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
            <React.Fragment>
                {transactionCodeTypeError &&
                <span
                    className="text-red-500"
                    style={{ display: transactionCodeTypeError ? "block" : "none" }}>
                    {transactionCodeTypeError}
                </span>}
                <div>
                    {transactionCodeTypes &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<TransactionCodeType>
                                    name="Types"
                                    columns={columns}
                                    data={transactionCodeTypes}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('ct5ku8gw4e')}
                                /> 
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<TransactionCodeType>
                                    name="Types"
                                    columns={columns}
                                    data={transactionCodeTypes}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('ct5ku8gw4e')}
                                />
                            </div>
                        </React.Fragment>}
                </div>
                <div>
                    {showAddEdit &&
                        <AddEditTransactionCodeType 
                            authProps={authProps} 
                            setShowAddEdit={setShowAddEdit}
                            transactionCodeType={transactionCodeType}
                            book={book}
                        />}
                </div>
            </React.Fragment>
    );
}

export default TransactionCodeTypePage;