import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { EmailRecipient } from './emailRecipientSlice';

export type EmailMessageParams = {
    authProps: AuthProps;
    emailMessage?: EmailMessage;
    emailMessageId?: string;
}

export type EmailMessage = {
    id: string;
    emailType?: string;
    userId: string;
    emailAddress: string;
    subject: string;
    message: string
    disableResponse: boolean;
    emailStatus: string;
    creationDate?: string;
    recipients: EmailRecipient[];
    edit?: void;
    delete?: void;
}

interface EmailMessageState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: EmailMessage[];
}

interface FetchErrors {
    message: string;
}

const initialState: EmailMessageState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchEmailMessages = 
    createAsyncThunk<EmailMessage[], AuthProps, { rejectValue: FetchErrors }>(
    "emailMessages/fetchAll",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.NOTIFICATION_EMAILMESSAGE_URL +
                "/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch email messages",
            });
        }
        return response.json();
    }
);

export const addEmailMessage =
    createAsyncThunk<EmailMessage, EmailMessageParams, { rejectValue: FetchErrors }>(
    "emailMessage/add",
    async (params: EmailMessageParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.NOTIFICATION_EMAILMESSAGE_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.emailMessage),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editEmailMessage =
    createAsyncThunk<EmailMessage, EmailMessageParams, { rejectValue: FetchErrors }>(
    "emailMessage/edit",
    async (params: EmailMessageParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.NOTIFICATION_EMAILMESSAGE_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.emailMessage),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteEmailMessage =
    createAsyncThunk<EmailMessage, EmailMessageParams, { rejectValue: FetchErrors }>(
    "emailMessage/delete",
    async (params: EmailMessageParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.NOTIFICATION_EMAILMESSAGE_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.emailMessage),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const emailMessageSlice = createSlice({
    name: 'emailMessage',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchEmailMessages.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addEmailMessage.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editEmailMessage.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteEmailMessage.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchEmailMessages.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addEmailMessage.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editEmailMessage.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteEmailMessage.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchEmailMessages.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addEmailMessage.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editEmailMessage.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteEmailMessage.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectEmailMessageStatus = (state: RootState) => state.emailMessages.status;
export const selectEmailMessages = (state: RootState) => state.emailMessages.list;
export const selectEmailMessageError = (state: RootState) => state.emailMessages.error;
export const { clearList, clearError } = emailMessageSlice.actions;
export default emailMessageSlice.reducer;