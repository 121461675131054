import React, { FC, useState, SetStateAction } from 'react';
import { selectMemberPeerGroupError, MemberPeerGroup, MemberPeerGroupParams, 
    addMemberPeerGroup } from '../../../store/memberPeerGroupSlice';
import AuthProps from '../../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import useToast from '../../../hooks/useToast';
import { grayedOutButtonClass, enabledButtonClass } from '../../../shared/Utils';
import Select from '../../common/Select';

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    memberId: string;
}

const AddEditMemberPeerGroup: FC<Props> = ({ authProps, setShowAddEdit,
    memberId }) => {

    const toast = useToast();
    const dispatch = useAppDispatch();

    const [peerGroup, setPeerGroup] = useState<any>("");
    const peerGroups = [
        {'id':'A', 'name':'A Sasco executive committee, employees'},
        {'id':'B', 'name':'B, Directors, senior management'},
        {'id':'C', 'name':'C, Middle management, supervisory'},
        {'id':'D', 'name':'D, Junior employees'},
        {'id':'E', 'name':'E, Agents and Independent partners'},
    ];

    const memberPeerGroupError = useAppSelector(selectMemberPeerGroupError);

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updatedMemberPeerGroup: MemberPeerGroup = {
            memberId: memberId,
            peerGroup: peerGroup,
        }
        const params: MemberPeerGroupParams = {
            authProps: authProps,
            memberPeerGroup: updatedMemberPeerGroup,
        }

        const result = await dispatch(addMemberPeerGroup(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            toast('success', `Peer group added successfully`);

            setShowAddEdit(false);
        }

    }

    const isInvalid =
        memberId === ""
        || peerGroup === "";

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                    <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                                <div className="max-w-md w-full space-y-4">
                                    <h2 className="text-center text-2xl font-normal text-gray-900">
                                        {`Create member peer group`}
                                    </h2>

                                    <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                        <div className="rounded-md shadow-sm -space-y-px">

                                            <span
                                                className="text-red-500"
                                                style={{ display: memberPeerGroupError ? "block" : "none" }}>
                                                {memberPeerGroupError}
                                            </span>

                                            <label className="text-gray-700 text-sm font-bold" htmlFor="memberId">
                                                Member
                                            </label>
                                            <Select
                                                id="peerGroup"
                                                placeHolder="Select Peer Group"
                                                className="shadow-sm border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                selectList={
                                                    peerGroups.map(group => {
                                                        return {id: group.id, name: group.name}
                                                    })}
                                                required={true}
                                                onChange={e => {
                                                    setPeerGroup(e.target.value);
                                            }} />
                                            
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="flex items-center">
                                                    Create
                                                </span>
                                            </button>
                                            </div>
                                            <div className="text-sm">
                                                <button
                                                    type="submit"
                                                    onClick={cancel}
                                                    className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AddEditMemberPeerGroup;