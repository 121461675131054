import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { fetchLoanTypes, selectLoanTypes } from '../../../store/loanTypeSlice';
import AuthProps from '../AuthProps';
import { useAppSelector, useAppDispatch } from '../hooks';
import Select from '../../common/Select';
import { selectLoanStatus } from '../../../store/loanSlice';
import { fetchLoanStatusReasons, selectLoanStatusReasons, LoanStatusReasonParams } from '../../../store/loanStatusReasonSlice';
import { fetchLoanStatuses, selectLoanStatuses } from '../../../store/loanStatusSlice';


interface Props {
    authProps: AuthProps;
    setLoanStatusReasonId: React.Dispatch<SetStateAction<string>>;
    loanStatusReasonId: string;
}

const LoanStatus: FC<Props> = ({ authProps, setLoanStatusReasonId, loanStatusReasonId }) => {

    const dispatch = useAppDispatch();

    const preSelectLoanStatus = [{'id':'1111111111','name':'Select loan status'}];
    const loanStatuses = useAppSelector(selectLoanStatuses);
    const [loanStatusId, setLoanStatusId] = useState<string>("");

    const preSelectLoanStatusReason = [{'id':'1111111111','name':'Select reason'}];
    const loanStatusReasons = useAppSelector(selectLoanStatusReasons);


    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchLoanStatuses(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div className="grid grid-cols-3 w-full">

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="loanTypeId">
                Status
            </label>

            <div className="col-span-2">

                <Select
                    id="loanStatusId"
                    className="p-1"
                    placeHolder="Select"
                    selectList={
                        preSelectLoanStatus.map(select => {
                            return {id: select.id, name: select.name}
                        }).concat(
                            loanStatuses.map(loanStatus => {
                                return { id: loanStatus.id, name: loanStatus.name }
                    }))}
                    required={true}
                    onChange={e => {
                        setLoanStatusReasonId("");
                        setLoanStatusId(e.target.value);
                        const params: LoanStatusReasonParams = {
                            authProps: authProps,
                            loanStatusId: e.target.value,
                        }
                        dispatch(fetchLoanStatusReasons(params));
                    }} />

            </div>

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="categoryId">
                Reason
            </label>
            
            <div className="col-span-2">
                <Select
                    id="loanStatusReasonId"
                    className="p-1"
                    placeHolder={"Select"}
                    selectList={
                        preSelectLoanStatusReason.map(select => {
                            return {id: select.id, name: select.name}
                        }).concat(
                            loanStatusReasons.map(reason => {
                                return { id: reason.id, name: reason.reason }
                    }))}
                    required={true}
                    disabled={loanStatusId === "" || loanStatusReasonId === "1111111111"}
                    onChange={e => {
                        setLoanStatusReasonId(e.target.value);
                    }} />
            </div>

        </div>
    );
}

export default LoanStatus;