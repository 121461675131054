import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { GeneralLedgerTransaction } from './generalLedgerTransactionSlice';

export type GeneralLedgerEntryParams = {
    authProps: AuthProps;
    generalLedgerEntry?: GeneralLedgerEntry;
    transactionPeriodId?: string;
    transactionType?: string;
}

export type User = {
    id: string;
    userName: string;
}

export type GeneralLedgerEntry = {
    id?: string;
    date: string;
    ref?: string;
    reportingPeriodId?: string;
    makerId: string;
    memo?: string;
    accountId: string;
    creationDate?: string;
    transactions: GeneralLedgerTransaction[];
    total?: number;
    edit?: void;
    delete?: void;
    status?: void;
}

interface GeneralLedgerEntryState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: GeneralLedgerEntry[];
}

interface FetchErrors {
    message: string;
}

const initialState: GeneralLedgerEntryState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchCurrentSavedEntriesByMaker = 
    createAsyncThunk<GeneralLedgerEntry[], AuthProps, { rejectValue: FetchErrors }>(
    "generalLedgerEntries/fetchSavedByMaker",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.GENERALLEDGER_ENTRIES_URL +
                "/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch current saved general ledger entries",
            });
        }
        return response.json();
    }
);

export const addGeneralLedgerEntry =
    createAsyncThunk<GeneralLedgerEntry, GeneralLedgerEntryParams, { rejectValue: FetchErrors }>(
    "generalLedgerEntry/add",
    async (params: GeneralLedgerEntryParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.GENERALLEDGER_ENTRIES_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.generalLedgerEntry),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editGeneralLedgerEntry =
    createAsyncThunk<GeneralLedgerEntry, GeneralLedgerEntryParams, { rejectValue: FetchErrors }>(
    "generalLedgerEntry/edit",
    async (params: GeneralLedgerEntryParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.GENERALLEDGER_ENTRIES_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.generalLedgerEntry),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteGeneralLedgerEntry =
    createAsyncThunk<GeneralLedgerEntry, GeneralLedgerEntryParams, { rejectValue: FetchErrors }>(
    "generalLedgerEntry/delete",
    async (params: GeneralLedgerEntryParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.GENERALLEDGER_ENTRIES_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.generalLedgerEntry),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const generalLedgerEntrySlice = createSlice({
    name: 'generalLedgerEntry',
    initialState,
    reducers: {
        clearGeneralLedgerEntryList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearGeneralLedgerEntryError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchCurrentSavedEntriesByMaker.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addGeneralLedgerEntry.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editGeneralLedgerEntry.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteGeneralLedgerEntry.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchCurrentSavedEntriesByMaker.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addGeneralLedgerEntry.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editGeneralLedgerEntry.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteGeneralLedgerEntry.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchCurrentSavedEntriesByMaker.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addGeneralLedgerEntry.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editGeneralLedgerEntry.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteGeneralLedgerEntry.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectGeneralLedgerEntryStatus = (state: RootState) => state.generalLedgerEntries.status;
export const selectGeneralLedgerEntries = (state: RootState) => state.generalLedgerEntries.list;
export const selectGeneralLedgerEntryError = (state: RootState) => state.generalLedgerEntries.error;
export const { clearGeneralLedgerEntryList, clearGeneralLedgerEntryError } = generalLedgerEntrySlice.actions;
export default generalLedgerEntrySlice.reducer;