import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { Member } from './memberSlice';

export type GuardianParams = {
    authProps: AuthProps;
    guardian?: Guardian;
}

export type Guardian = {
    memberId: string;
    beneficiaryId: string;
    guardianId: string;
    guardian: Member;
    relationship: string;
    creationDate?: string;
    modifiedDate?: string;
    edit?: void;
    delete?: void;
}

interface GuardianState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: Guardian[];
}

interface FetchErrors {
    message: string;
}

const initialState: GuardianState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchGuardiansById =
    createAsyncThunk<Guardian[], AuthProps, { rejectValue: FetchErrors }>(
    "guardians/fetch",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.GUARDIAN_URL +
                "/" + params.auth.token.memberId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member guardians",
            });
        }
        return response.json();
    }
)

export const addGuardian =
    createAsyncThunk<Guardian, GuardianParams, { rejectValue: FetchErrors }>(
    "guardian/add",
    async (params: GuardianParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.GUARDIAN_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.guardian),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editGuardian =
    createAsyncThunk<Guardian, GuardianParams, { rejectValue: FetchErrors }>(
    "guardian/edit",
    async (params: GuardianParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.GUARDIAN_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.guardian),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteGuardian =
    createAsyncThunk<Guardian, GuardianParams, { rejectValue: FetchErrors }>(
    "guardian/delete",
    async (params: GuardianParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.GUARDIAN_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.guardian),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const guardianSlice = createSlice({
    name: 'guardian',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        addVirtualGuardian: (state, { payload }) => {
            state.list.unshift(payload);
            state.status = "idle";
        },
        editVirtualGuardian: (state, { payload }) => {
            var index = state.list.findIndex( ({ memberId, guardianId }) => memberId === payload.memberId
                && guardianId === payload.guardianId);
            state.list[index] = payload;
            state.status = "idle";
        },
        deleteVirtualGuardian: (state, { payload }) => {
            var index = state.list.findIndex( ({ memberId, guardianId }) => memberId === payload.memberId
                && guardianId === payload.guardianId);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchGuardiansById.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addGuardian.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editGuardian.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteGuardian.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchGuardiansById.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addGuardian.fulfilled, (state, { payload }) => {
            state.list.unshift(payload);
            state.status = "idle";
        });
        builder.addCase(editGuardian.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ memberId, guardianId }) => memberId === payload.memberId
                && guardianId === payload.guardianId);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteGuardian.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ memberId, guardianId }) => memberId === payload.memberId
                && guardianId === payload.guardianId);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchGuardiansById.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addGuardian.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editGuardian.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteGuardian.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectGuardianStatus = (state: RootState) => state.guardians.status;
export const selectGuardians = (state: RootState) => state.guardians.list;
export const selectGuardianCount = (state: RootState) => state.guardians.list.length;
export const selectGuardianError = (state: RootState) => state.guardians.error;
export const { clearError, clearList, addVirtualGuardian,
    editVirtualGuardian, deleteVirtualGuardian } = guardianSlice.actions;
export default guardianSlice.reducer;