import React, { FC, useEffect } from 'react';
import { fetchBeneficiariesById, selectBeneficiaries,
    BeneficiaryParams } from '../../../store/beneficiarySlice';
import AuthProps from '../../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { ccyFormat } from '../../../shared/Utils';

interface Props {
    authProps: AuthProps;
    memberId: string;
}

const Beneficiaries: FC<Props> = ({ authProps, memberId }) => {

    const dispatch = useAppDispatch();

    const beneficiaries = useAppSelector(selectBeneficiaries);

    useEffect(() => {
        const controller = new AbortController();
        const params: BeneficiaryParams = {
            authProps: authProps,
            memberId: memberId,
        };
        dispatch(fetchBeneficiariesById(params));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[memberId]);

    return (
        <div className="flex w-full md:w-1/4">
            <div className="text-xs flex items-center space-x-1 space-y-1">
                <div className="grid grid-cols-3 gap-1">
                    {beneficiaries.map((beneficiary, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="font-bold">
                                    {`Beneficiary ${index +1}:`}
                                </div>
                                <div className="font-normal col-span-2">
                                    {`${beneficiary.beneficiary.firstName} ${beneficiary.beneficiary.middleName} ${beneficiary.beneficiary.surname} ${beneficiary.relationship}
                                     DoB: ${beneficiary.beneficiary.dateOfBirth} Gender: ${beneficiary.beneficiary.gender.substring(0,1)}
                                     Shares: ${ccyFormat(beneficiary.percentShare, 0)}%`}
                                    {beneficiary.guardians &&
                                        <span className="p-2 font-extralight">
                                            {beneficiary.guardians.map((guardian, i) => {
                                                return (
                                                    <div key={i}
                                                        className="p-1">
                                                        {`Guardian ${i +1}: ${guardian.relationship} ${guardian.guardian.firstName} ${guardian.guardian.middleName} ${guardian.guardian.surname}`}
                                                    </div>
                                                );
                                            })}
                                        </span>}
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Beneficiaries;