import React, { FC, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { Process, fetchProcesses, selectProcessError, selectProcesses, 
    selectProcessStatus } from '../../../store/processSlice';
import AuthProps from '../../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';

const ProcessPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const processes = useAppSelector(selectProcesses);
    const status = useAppSelector(selectProcessStatus);
    const processError = useAppSelector(selectProcessError);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchProcesses(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const columns: Column<Process>[] = useMemo(() => [
        {
          Header: "Process",
          accessor: "process"
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[processes]);

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
            <React.Fragment>
                {processError &&
                <span
                    className="text-red-500"
                    style={{ display: processError ? "block" : "none" }}>
                    {processError}
                </span>}
                <div>
                    {processes &&
                        <React.Fragment>
                            <div>
                                <WrappedTable<Process>
                                    name="Processes"
                                    columns={columns}
                                    data={processes}
                                    addonHooks={[]}
                                /> 
                            </div>
                        </React.Fragment>}
                </div>
            </React.Fragment>
    );
}

export default ProcessPage;