import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { LoanType } from './loanTypeSlice';

export type CategoryParams = {
    authProps: AuthProps;
    category?: Category;
    loanTypeId?: string;
}

export type Category = {
    id: string;
    name: string;
    loanTypeId: string;
    loanType?: LoanType;
    edit?: void;
    delete?: void;
}

interface CategoryState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: Category[];
}

interface FetchErrors {
    message: string;
}

const initialState: CategoryState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchCategoriesByLoanType = 
    createAsyncThunk<Category[], CategoryParams, { rejectValue: FetchErrors }>(
    "categories/fetchByAccount",
    async (params: CategoryParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.CATEGORY_URL +
                "/" + params.loanTypeId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch categories",
            });
        }
        return response.json();
    }
);

export const addCategory =
    createAsyncThunk<Category, CategoryParams, { rejectValue: FetchErrors }>(
    "category/add",
    async (params: CategoryParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.CATEGORY_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.category),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editCategory =
    createAsyncThunk<Category, CategoryParams, { rejectValue: FetchErrors }>(
    "category/edit",
    async (params: CategoryParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.CATEGORY_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.category),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteCategory =
    createAsyncThunk<Category, CategoryParams, { rejectValue: FetchErrors }>(
    "category/delete",
    async (params: CategoryParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.CATEGORY_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.category),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchCategoriesByLoanType.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addCategory.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editCategory.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteCategory.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchCategoriesByLoanType.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addCategory.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editCategory.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteCategory.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchCategoriesByLoanType.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addCategory.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editCategory.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteCategory.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectCategoryStatus = (state: RootState) => state.categories.status;
export const selectCategories = (state: RootState) => state.categories.list;
export const selectCategoryError = (state: RootState) => state.categories.error;
export const { clearList, clearError } = categorySlice.actions;
export default categorySlice.reducer;