import React, { FC, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { FeatureProcessAction, fetchFeatureProcessActions, selectFeatureProcessActionError, 
    selectFeatureProcessActions, selectFeatureProcessActionStatus,
     FeatureProcessActionParams } from '../../../store/featureProcessActionSlice';
import AuthProps from '../../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import { selectFeatures, fetchFeatures } from '../../../store/featureSlice';
import Select from '../../common/Select';

const FeatureProcessActionPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const features = useAppSelector(selectFeatures);
    const featureProcessActions = useAppSelector(selectFeatureProcessActions);
    const status = useAppSelector(selectFeatureProcessActionStatus);
    const featureProcessActionError = useAppSelector(selectFeatureProcessActionError);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchFeatures(authProps));
        return () => controller.abort();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const columns: Column<FeatureProcessAction>[] = useMemo(() => [
        {
            Header: "Process",
            accessor: "process"
        },
        {
            Header: "Action",
            accessor: "action"
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[featureProcessActions]);

    return (
        <div className="flex flex-col w-auto">
            {
                <div className="flex relative p-1 w-auto">
                    <Select
                        id="feature"
                        placeHolder="Select Feature"
                        className="shadow-sm border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        selectList={
                            features?.map(feature => {
                                return {id: feature.feature, name: feature.feature}
                            })}
                        required={true}
                        onChange={e => {
                            e.preventDefault();
                            const params: FeatureProcessActionParams = {
                                authProps: authProps,
                                feature: e.target.value,
                            }
                            dispatch(fetchFeatureProcessActions(params));
                        }} />
                </div>
            }
            {status === "loading" ? (<div className="pl-2">Loading...</div>) : (
                <React.Fragment>
                    {featureProcessActionError &&
                    <span
                        className="text-red-500"
                        style={{ display: featureProcessActionError ? "block" : "none" }}>
                        {featureProcessActionError}
                    </span>}
                    <div>
                        {featureProcessActions &&
                            <React.Fragment>
                                <div className="hidden md:block">
                                    <WrappedTable<FeatureProcessAction>
                                        name="Feature Process Actions"
                                        columns={columns}
                                        data={featureProcessActions}
                                        addonHooks={[]}
                                    /> 
                                </div>
                            </React.Fragment>}
                    </div>
                </React.Fragment>)};
        </div>
    );
}

export default FeatureProcessActionPage;