import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type ReportingClassParams = {
    authProps: AuthProps;
};

export type ReportingClass = {
    id: string;
    name: string;
    accountId: string;
}

interface ReportingClassState {
    status: "loading" | "idle";
    error: string | null;
    list: ReportingClass[];
}

interface FetchErrors {
    message: string;
};

const initialState: ReportingClassState = {
    list: [],
    error: null,
    status: "idle",
};

export const fetchReportingClasses = 
    createAsyncThunk<ReportingClass[], AuthProps, { rejectValue: FetchErrors }>(
    "reportingClasses/fetchByAccount",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.REPORTINGCLASS_URL, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch primary accounts",
            });
        }
        return response.json();
    }
);

export const reportingClassSlice = createSlice({
    name: 'reportingClass',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchReportingClasses.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchReportingClasses.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchReportingClasses.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectReportingClassStatus = (state: RootState) => state.reportingClasses.status;
export const selectReportingClasses = (state: RootState) => state.reportingClasses.list;
export const selectReportingClassCount = (state: RootState) => state.reportingClasses.list.length;
export const selectReportingClassError = (state: RootState) => state.reportingClasses.error;
export const { clearError, clearList } = reportingClassSlice.actions;
export default reportingClassSlice.reducer;