import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type MemberPeerGroupParams = {
    authProps: AuthProps;
    memberPeerGroup?: MemberPeerGroup;
    memberId?: string;
}

export type MemberPeerGroup = {
    memberId: string;
    peerGroup: string;
    creationDate?: string;
    delete?: void;
}

interface MemberPeerGroupState {
    status: "loading" | "idle" | "adding" | "deleting";
    error: any;
    list: MemberPeerGroup[];
}

interface FetchErrors {
    message: string;
}

const initialState: MemberPeerGroupState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchMemberPeerGroups = 
    createAsyncThunk<MemberPeerGroup[], MemberPeerGroupParams, { rejectValue: FetchErrors }>(
    "memberPeerGroups/fetchByMemberId",
    async (params: MemberPeerGroupParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.MEMBER_PEERGROUP_URL +
                "/" + params.memberId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member peer groups",
            });
        }
        return response.json();
    }
);

export const addMemberPeerGroup =
    createAsyncThunk<MemberPeerGroup, MemberPeerGroupParams, { rejectValue: FetchErrors }>(
    "memberPeerGroup/add",
    async (params: MemberPeerGroupParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MEMBER_PEERGROUP_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberPeerGroup),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteMemberPeerGroup =
    createAsyncThunk<MemberPeerGroup, MemberPeerGroupParams, { rejectValue: FetchErrors }>(
    "memberPeerGroup/delete",
    async (params: MemberPeerGroupParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MEMBER_PEERGROUP_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberPeerGroup),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const memberPeerGroupSlice = createSlice({
    name: 'memberPeerGroup',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchMemberPeerGroups.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addMemberPeerGroup.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(deleteMemberPeerGroup.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchMemberPeerGroups.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addMemberPeerGroup.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(deleteMemberPeerGroup.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ memberId, peerGroup }) => memberId === payload.memberId
                && peerGroup === payload.peerGroup);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchMemberPeerGroups.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addMemberPeerGroup.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteMemberPeerGroup.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectMemberPeerGroupStatus = (state: RootState) => state.memberPeerGroups.status;
export const selectMemberPeerGroups = (state: RootState) => state.memberPeerGroups.list;
export const selectMemberPeerGroupError = (state: RootState) => state.memberPeerGroups.error;
export const { clearList, clearError } = memberPeerGroupSlice.actions;
export default memberPeerGroupSlice.reducer;