import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type LoanStatus = {
    id: string;
    name: string;
    process: number;
}

interface LoanStatusState {
    status: "loading" | "idle";
    error: string | null;
    list: LoanStatus[];
}

interface FetchErrors {
    message: string;
}

const initialState: LoanStatusState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchLoanStatuses = 
    createAsyncThunk<LoanStatus[], AuthProps, { rejectValue: FetchErrors }>(
    "loanStatuses/fetchByAccount",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.LOANS_URL +
                "/loanstatuses/" + params.config.ACCOUNT_ID, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch loan statuses",
            });
        }
        return response.json();
    }
)

export const loanStatusSlice = createSlice({
    name: 'loanStatus',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchLoanStatuses.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchLoanStatuses.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchLoanStatuses.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectLoanStatusStatus = (state: RootState) => state.loanStatuses.status;
export const selectLoanStatuses = (state: RootState) => state.loanStatuses.list;
export const selectLoanStatusError = (state: RootState) => state.loanStatuses.error;
export const { clearError } = loanStatusSlice.actions;
export default loanStatusSlice.reducer;