import React, { FC, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Select from './common/Select';
import CryptoJS from 'crypto-js';
import PhoneAuthentication from './PhoneAuthentication';
import useToast from "../hooks/useToast";

import { grayedOutButtonClass, enabledButtonClass,
    reverse } from '../shared/Utils';
import ChangePassword from './ChangePassword';

interface Props {
  uri: string;
  auth: any;
  config: any;
}

const Login: FC<Props> = ({ uri, auth, config }) => {

    const toast = useToast(5000);
    const navigate = useNavigate();

    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
    const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);

    const [areaCode, setAreaCode] = useState<string>("+254");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const app: string = "AdminWeb";
    const [userPassword, setUserPassword] = useState<string>("");
    const date = new Date().getTime();
    const [errMsg, setErrMsg] = useState<string>("");

    const [showAuthToken, setShowAuthToken] = useState<boolean>(false);
    const [userAuthToken, setUserAuthToken] = useState<any>("");
    const [authMessage, setAuthMessage] = useState<string>("");
    
    const cancel = () => {
        const l = navigate.length;
        l > 2 ? navigate(-1) : navigate("/");
    };

    const areaCodes = [{'id':'+254', 'name':'+254'}];
    const accountId = config.ACCOUNT_ID;

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const password = (CryptoJS.AES.encrypt(
            userPassword,
            reverse(areaCode.concat(
                phoneNumber.concat(date.toString()))
                .replace("+", "k"))).toString());
        
        let authToken = "";
        if (userAuthToken.trim() !== "") {
            authToken = (CryptoJS.AES.encrypt(
                userAuthToken,
                reverse(areaCode.concat(
                    phoneNumber.concat(date.toString()))
                    .replace("+", "x"))).toString());
        }

        const res = await auth.loginUser({
            authToken,
            areaCode,
            phoneNumber,
            app,
            password,
            date,
            accountId,
        });
        if (res && res.success) {
            setErrMsg("");
            navigate(uri ? uri : "/");
        } else {
            if (res.error.message.includes("token")) {
                setShowAuthToken(true);
                setAuthMessage(res.error.message);
                return;
            }
            setErrMsg(res.error.message);
        }
    };

    const forgotPassword = async(e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();

        const authToken = "";
        const password = "";
        const name = "";
        const role = "";

        const res = await auth.forgotPassword({
            authToken,
            name,
            areaCode,
            phoneNumber,
            role,
            password,
            date,
            accountId,
        });
        if (res && res.success) {
            setErrMsg("");
            setIsForgotPassword(true);
            setShowAuthToken(true);
            setAuthMessage(res.data.actionRequired);
        } else {
            setErrMsg(res.error.message);
        }

    };

    const handleSubmitForValidation = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const password = ""
        const name = "";
        const role = "";
        
        let authToken = "";
        if (userAuthToken.trim() !== "") {
            authToken = (CryptoJS.AES.encrypt(
                userAuthToken,
                reverse(areaCode.concat(
                    phoneNumber.concat(date.toString()))
                    .replace("+", "x"))).toString());
        }

        const res = await auth.validateAuthToken({
            authToken,
            name,
            areaCode,
            phoneNumber,
            role,
            password,
            date,
            accountId,
        });
        if (res && res.success) {
            setErrMsg("");
            setShowChangePassword(true);
            setAuthMessage(res.data.actionRequired);
        } else {
            setErrMsg(res.error.message);
        }
    };

    const handleSubmitForResetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const name = "";
        const role = "";

        const password = (CryptoJS.AES.encrypt(
            userPassword,
            reverse(areaCode.concat(
                phoneNumber.concat(date.toString()))
                .replace("+", "k"))).toString());

        const authToken = "";

        const res = await auth.resetPassword({
            authToken,
            name,
            areaCode,
            phoneNumber,
            role,
            password,
            date,
            accountId,
        });
        if (res && res.success) {
            setErrMsg("");
            setShowAuthToken(false);
            setShowChangePassword(false);
            setAuthMessage("");
            toast('success', `${res.data.actionRequired}`)
        } else {
            setErrMsg(res.error.message);
        }
    };

    const isInvalid = areaCode.trim() === ""
        || phoneNumber.trim() === ""
        || phoneNumber.length < 9
        || userPassword.trim() === "";

    const isInvalidForgotPassword = areaCode.trim() === ""
        || phoneNumber.trim() === ""
        || phoneNumber.length < 9;

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-2 px-2 pb-5 text-center sm:block sm:p-0">

                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                        
                        <div className="flex items-center md:px-2 py-2">
                            <div className="relative sm:w-full p-4 mx-auto bg-white rounded-md shadow-lg">
                        
                                <h2 className="text-center text-3xl font-normal text-gray-900 p-2">
                                    Sign in to Sasco Sacco
                                </h2>

                                <span
                                    className="text-red-500"
                                    style={{ display: errMsg ? "block" : "none" }}>
                                    {errMsg}
                                </span>

                                {showChangePassword && showChangePassword ? (
                                    <ChangePassword
                                        message={authMessage}
                                        userPassword={userPassword}
                                        setUserPassword={setUserPassword}
                                        handleSubmitForResetPassword={handleSubmitForResetPassword}
                                        cancel={cancel} />) :
                                        (<React.Fragment>
                                            {showAuthToken && showAuthToken ? (
                                                <PhoneAuthentication 
                                                    message={authMessage}
                                                    userAuthToken={userAuthToken}
                                                    setUserAuthToken={setUserAuthToken}
                                                    handleSubmitWithToken={isForgotPassword ? handleSubmitForValidation : handleSubmit}
                                                    cancel={cancel} />) :
                                                (<form className="mt-4 space-y-6" onSubmit={handleSubmit}>
                                                    <div className="rounded-md shadow-sm -space-y-px">
                                                        <div>

                                                            <div className="flex flex-row relative">
                                                                <Select
                                                                    id="areaCode"
                                                                    placeHolder="+254"
                                                                    className="flex-grow appearance-none rounded-none border relative block py-2 px-3 w-auto text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                    selectList={areaCodes.map(areaCode => {
                                                                        return { id: areaCode.id, name: areaCode.name }})}
                                                                    required={true}
                                                                    onChange={(e) => 
                                                                        setAreaCode(e.target.value)} />

                                                                <label htmlFor="phoneNumber" className="sr-only">
                                                                    PhoneNumber
                                                                </label>
                                                                <input
                                                                    id="phoneNumber"
                                                                    name="phoneNumber"
                                                                    maxLength={10}
                                                                    type="text"
                                                                    required
                                                                    className="flex-grow appearance-none rounded-none relative block px-3 py-2 border w-0 border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                                    placeholder="Phone Number"
                                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                                    autoFocus />
                                                            </div>
                                                        </div>
                                                            
                                                        <div className="w-full">
                                                            
                                                            <label htmlFor="userPassword" className="sr-only">
                                                                Password
                                                            </label>
                                                            <input
                                                                id="userPassword"
                                                                name="userPassword"
                                                                type="password"
                                                                maxLength={30}
                                                                autoComplete="current-userPassword"
                                                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                                placeholder="Password"
                                                                onChange={(e) => setUserPassword(e.target.value)} />
                                                        </div>

                                                        <div className="inline-block font-normal text-sm pl-2 pt-2">
                                                            <button
                                                                disabled={isInvalidForgotPassword}
                                                                type="submit"
                                                                onClick={forgotPassword}
                                                                className={isInvalidForgotPassword ? "text-gray-400" : "transition-colors text-red-800 hover:text-gray-400 duration-200"}>
                                                                Forgot password?
                                                            </button>
                                                        </div>
                                                            
                                                    </div>

                                                    <div className="flex items-center justify-between">
                                                        <div>
                                                            <button
                                                                type="submit"
                                                                disabled={isInvalid}
                                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                                                    <svg
                                                                    className="h-5 w-5 text-indigo-400 group-hover:text-indigo-300"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                    aria-hidden="true"
                                                                    >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                                                        clipRule="evenodd"
                                                                    />
                                                                    </svg>
                                                                </span>
                                                                <span className="flex items-center pl-6">
                                                                    Sign in
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="text-sm">
                                                            <button
                                                                type="submit"
                                                                onClick={cancel}
                                                                className="transition-colors hover:text-gray-400 font-medium duration-200">
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>)}
                                            </React.Fragment>)}
                                        
                                    
                                    

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
