import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type Process = {
    process: string;
}

interface ProcessState {
    status: "loading" | "idle";
    error: string | null;
    list: Process[];
}

interface FetchErrors {
    message: string;
}

const initialState: ProcessState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchProcesses = 
    createAsyncThunk<Process[], AuthProps, { rejectValue: FetchErrors }>(
    "process/fetch",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.SYSTEM_PROCESSES_URL, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch processes",
            });
        }
        return response.json();
    }
)

export const processSlice = createSlice({
    name: 'process',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchProcesses.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchProcesses.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchProcesses.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectProcessStatus = (state: RootState) => state.processes.status;
export const selectProcesses = (state: RootState) => state.processes.list;
export const selectProcessError = (state: RootState) => state.processes.error;
export const { clearError } = processSlice.actions;
export default processSlice.reducer;