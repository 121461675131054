import React, { FC, useEffect } from 'react';
import { fetchAddressesById, selectAddresses } from '../../../store/addressSlice';
import { fetchEmailsById, selectEmails } from '../../../store/emailSlice';
import { fetchPersonalPhonesById, PersonalPhoneParams, selectPersonalPhones } from '../../../store/personalPhoneSlice';
import AuthProps from '../../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../../common/hooks';

interface Props {
    authProps: AuthProps;
    memberId: string;
}

const Contacts: FC<Props> = ({ authProps, memberId }) => {

    const dispatch = useAppDispatch();

    const phones = useAppSelector(selectPersonalPhones);
    const emails = useAppSelector(selectEmails);
    const addresses = useAppSelector(selectAddresses);

    useEffect(() => {
        const controller = new AbortController();
        const params: PersonalPhoneParams = {
            authProps: authProps,
            memberId: memberId,
        }
        dispatch(fetchPersonalPhonesById(params));
        dispatch(fetchEmailsById(params));
        dispatch(fetchAddressesById(params));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[memberId]);


    return (
        <div className="flex w-full md:w-1/4">
            <div className="text-xs flex items-center space-x-1 space-y-1">
                <div className="flex flex-col m-1">
                    <h4 className="text-sm font-extralight pt-1 pb-1 bg-white">
                        PHONES
                    </h4>
                    <div className="grid grid-cols-3 gap-1">
                        {phones.map((phone, index) => {
                            return (
                                <React.Fragment>
                                    <div className="font-bold">
                                        {`Phone ${index + 1}:`}
                                    </div>
                                    <div className="font-normal col-span-2">
                                        {phone.phoneNumber}
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <h4 className="text-sm font-extralight pt-1 pb-1 bg-white">
                        EMAIL
                    </h4>
                    <div className="grid grid-cols-3 gap-1">
                        {emails.map((email, index) => {
                            return (
                                <React.Fragment>
                                    <div className="font-bold">
                                        {`Email ${index + 1}:`}
                                    </div>
                                    <div className="font-normal col-span-2">
                                        {email.email}
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <h4 className="text-sm font-extralight pt-1 pb-1 bg-white">
                        ADDRESS
                    </h4>
                    <div className="grid grid-cols-3 gap-1">
                        {addresses.map((address, index) => {
                            return (
                                <React.Fragment>
                                    <div className="font-bold">
                                        {`Address ${index + 1}:`}
                                    </div>
                                    <div className="font-normal col-span-2">
                                        {address.address}
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;