import React, { FC, useState } from 'react';
import AuthProps from '../common/AuthProps';
import { useAppSelector } from '../common/hooks';
import { selectMembers } from '../../store/memberSlice';
import Select from '../common/Select';
import Personal from './InfoType/Personal';
import Identities from './InfoType/Identities';
import Contacts from './InfoType/Contacts';
import Beneficiaries from './InfoType/Beneficiaries';
import NextOfKin from './InfoType/NextOfKin';
import MonthlyDeduction from './InfoType/MonthlyDeduction';
import Employer from './InfoType/Employer';
import { useQuery } from "react-query";
import useToast from '../../hooks/useToast';
import { RefreshIcon } from '@heroicons/react/solid';
import MemberPeerGroupPage from './InfoType/MemberPeerGroup';

const OtherInfo: FC<AuthProps> = (authProps) => {

    const toast = useToast(5000);

    const members = useAppSelector(selectMembers);
    const [memberId, setMemberId] = useState<string>("");

    const [showBeneficiaries, setShowBeneficiaries] = useState<boolean>(false);
    const [showContacts, setShowContacts] = useState<boolean>(false);
    const [showDeduction, setShowDeduction] = useState<boolean>(false);
    const [showEmployer, setShowEmployer] = useState<boolean>(false);
    const [showIdentities, setShowIdentities] = useState<boolean>(false);
    const [showNextOfKin, setShowNextOfKin] = useState<boolean>(false);
    const [showPersonal, setShowPersonal] = useState<boolean>(false);
    const [showPeerGroup, setShowPeerGroup] = useState<boolean>(false);

    async function fetchSections() {
        const response = await fetch(authProps.config.MEMBER_INFO_SECTIONS_URL +
            "/" + authProps.auth.token.userId, {
            method: "GET",
            mode: "cors",
            headers: {
                ...authProps.config.headersWithAuthorization(),
            },
        });
        if (response.status !== 200) {
            toast('failure', 'failed to fetch sections');
            throw new Error('failed to fetch sections');
        }
        return response.json();
    }

    const { isLoading, isError, data, error } = useQuery(['sections'], fetchSections);

    return (
        <div className="flex flex-col w-auto">
            {isLoading && <RefreshIcon className="w-10 h-5 animate-spin mx-auto"></RefreshIcon>}
            <div className="flex items-center pt-1 pb-1">
                <div className="flex flex-row space-x-1">
                    <div className="relative">
                        <Select
                            id="memberId"
                            placeHolder="Select Member"
                            className="shadow-sm border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            selectList={
                                members.map(member => {
                                    return {id: member.id, name: `${member.surname} ${member.firstName}, ${member.middleName} ${member.saccoId}`}
                                })}
                            required={true}
                            onChange={e => {
                                //setShowPersonal(false);
                                //setShowIdentities(false);
                                //setShowContacts(false);
                                //setShowBeneficiaries(false);
                                //setShowNextOfKin(false);
                                //setShowDeduction(false);
                                //setShowEmployer(false);
                                //setShowPeerGroup(false);
                                setMemberId(e.target.value);
                            }} />
                    </div>
                    {memberId &&
                        <div className="relative">
                            <Select
                                id="sectionId"
                                placeHolder="Select Info Type"
                                className="shadow-sm border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                selectList={
                                    data && data.map(section => {
                                        return {id: section.section, name: section.section}
                                    })}
                                required={true}
                                onChange={e => {
                                    e.preventDefault();
                                    setShowPersonal(false);
                                    setShowIdentities(false);
                                    setShowContacts(false);
                                    setShowBeneficiaries(false);
                                    setShowNextOfKin(false);
                                    setShowDeduction(false);
                                    setShowEmployer(false);
                                    switch(e.target.value) {
                                        case "PERSONAL":
                                            setShowPersonal(true);
                                            break;
                                        case "IDENTITIES":
                                            setShowIdentities(true);
                                            break;
                                        case "CONTACTS":
                                            setShowContacts(true);
                                            break;
                                        case "BENEFICIARIES":
                                            setShowBeneficiaries(true);
                                            break;
                                        case "NEXT-OF-KIN":
                                            setShowNextOfKin(true);
                                            break;
                                        case "MONTHLY DEDUCTION":
                                            setShowDeduction(true);
                                            break;
                                        case "EMPLOYER":
                                            setShowEmployer(true);
                                            break;
                                        case "PEER-GROUP":
                                            setShowPeerGroup(true);
                                    }
                                }} />
                    </div>}
                </div>
            </div>
            <div className="inset border p-1 bg-gray-50">
                {showBeneficiaries &&
                    <Beneficiaries authProps={authProps} memberId={memberId} />}
                {showContacts &&
                    <Contacts authProps={authProps} memberId={memberId} />}
                {showEmployer &&
                    <Employer authProps={authProps} memberId={memberId} />}
                {showDeduction &&
                    <MonthlyDeduction authProps={authProps} memberId={memberId} />}
                {showIdentities &&
                    <Identities authProps={authProps} memberId={memberId} />}
                {showNextOfKin &&
                    <NextOfKin authProps={authProps} memberId={memberId} />}
                {showPersonal &&
                    <Personal authProps={authProps} member={members.find( ({ id }) => id === memberId)} />}
                {showPeerGroup &&
                    <MemberPeerGroupPage authProps={authProps} memberId={memberId} />}
            </div>
        </div>
    );
};

export default OtherInfo;