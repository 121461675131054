import React, { FC, useState, SetStateAction } from 'react';
import { addSubsidiaryAccount, editSubsidiaryAccount, SubsidiaryAccount, SubsidiaryAccountParams,
     selectSubsidiaryAccountError, clearError } from '../../store/subsidiaryAccountSlice';
import AuthProps from '../common/AuthProps';
import { useAppSelector, useAppDispatch } from '../common/hooks';
import useToast from '../../hooks/useToast';
import { grayedOutButtonClass, enabledButtonClass } from '../../shared/Utils';
import { PrimaryAccount } from '../../store/primaryAccountSlice';
import Select from '../common/Select';

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    subsidiaryAccount?: SubsidiaryAccount | undefined;
    primaryAccount: PrimaryAccount;
}

const AddEditSubsidiaryAccount: FC<Props> = ({ authProps, setShowAddEdit, subsidiaryAccount,
    primaryAccount }) => {

    const toast = useToast();
    const dispatch = useAppDispatch();
    const subsidiaryAccountError = useAppSelector(selectSubsidiaryAccountError);

    const [editMode] = useState<boolean>(!!subsidiaryAccount);
    const [code, setCode] = useState<string>(subsidiaryAccount ? subsidiaryAccount.code : "");
    const [name, setName] = useState<string>(subsidiaryAccount ? subsidiaryAccount.name : "");

    const [subsidiaryAccountType, setSubsidiaryAccountType] = useState<string>(subsidiaryAccount ? subsidiaryAccount.subsidiaryAccountType : "NORMAL");
    const subsidiaryAccountTypes = [
        {'id':'CASHBOOK','name':'CASHBOOK'},
        {'id':'CONTROL','name':'CONTROL'},
        {'id':'NORMAL','name':'NORMAL'},
        {'id':'PETTYCASH','name':'PETTYCASH'},
    ];

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updateSubsidiaryAccount: SubsidiaryAccount = {
            id: subsidiaryAccount ? subsidiaryAccount.id : "",
            code: code,
            name: name,
            primaryAccountId: primaryAccount.id,
            subsidiaryAccountType: subsidiaryAccountType,
        };

        const params: SubsidiaryAccountParams = {
            authProps: authProps,
            subsidiaryAccount: updateSubsidiaryAccount,
        }

        const result = await dispatch(editMode ? editSubsidiaryAccount(params) : addSubsidiaryAccount(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            setShowAddEdit(false);
            toast('success', editMode ? 'Subsidiary account edited successfully' : 'Subsidiary account created successfully');
        }

    };

    const isInvalid = 
        code === ""
        || name === ""
        || primaryAccount === null;

        return (
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
    
                     {/* To trick the browser into centering the modal */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>
    
                    <div
                        className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline">
                        <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                                    <div className="max-w-md w-full space-y-4">
                                        <h2 className="text-center text-2xl font-normal text-gray-900">
                                            {editMode ? `Edit: ${subsidiaryAccount?.name} (${primaryAccount.name})` : 
                                                `Create new subsidiary account (${primaryAccount.name})`}
                                        </h2>
    
                                        <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                            <div className="rounded-md shadow-sm -space-y-px">
    
                                                <span
                                                    className="text-red-500"
                                                    style={{ display: subsidiaryAccountError ? "block" : "none" }}>
                                                    {subsidiaryAccountError}
                                                </span>

                                                <label className="text-gray-700 text-sm font-bold" htmlFor="code">
                                                    Code
                                                </label>
                                                <input
                                                    id="code"
                                                    name="code"
                                                    type="text"
                                                    value={code}
                                                    maxLength={3}
                                                    required
                                                    className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                    placeholder="Code"
                                                    onChange={(e) => {
                                                        dispatch(clearError(null));
                                                        setCode(e.target.value);
                                                    }}
                                                    autoFocus
                                                />

                                                <label className="text-gray-700 text-sm font-bold" htmlFor="name">
                                                    Name
                                                </label>
                                                <input
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    value={name}
                                                    maxLength={50}
                                                    required
                                                    className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                    placeholder="Name"
                                                    onChange={(e) => {
                                                        dispatch(clearError(null));
                                                        setName(e.target.value);
                                                    }}
                                                />

                                                <Select
                                                    id="subsidiaryAccountType"
                                                    placeHolder="Select account type"
                                                    className="shadow-md border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    value={subsidiaryAccountType}
                                                    selectList={
                                                        subsidiaryAccountTypes.map(subsidiaryAccountType => {
                                                            return {id: subsidiaryAccountType.id, name: subsidiaryAccountType.name}
                                                        })}
                                                    required={true}
                                                    onChange={e => {
                                                        dispatch(clearError(null));
                                                        setSubsidiaryAccountType(e.target.value);
                                                    }} />
                                                
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <div>
                                                <button
                                                    type="submit"
                                                    disabled={isInvalid}
                                                    className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                    <span className="flex items-center">
                                                        {editMode ? "Edit" : "Create"}
                                                    </span>
                                                </button>
                                                </div>
                                                <div className="text-sm">
                                                    <button
                                                        type="submit"
                                                        onClick={cancel}
                                                        className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
        );

};

export default AddEditSubsidiaryAccount;