import React, { FC, useEffect, useState, useMemo } from 'react';
import { MemberParams, deleteUnregisteredMember, fetchAllMembers, selectMemberError, selectMembers } from '../../store/memberSlice';
import AuthProps from '../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { Member } from '../../store/memberSlice';
import { Column } from 'react-table';
import { WrappedTable } from '../common/Table/WrappedTable';
import { selectMemberStatus } from '../../store/memberStatusSlice';
import EditRegistrationStatus from './EditRegistrationStatus';
import EditMemberStatus from './EditMemberStatus';
import IconButton from '../../shared/confirm/IconButton';
import ConfirmDialog from '../../shared/confirm/Confirm';

const Members:FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const members = useAppSelector(selectMembers);
    const status = useAppSelector(selectMemberStatus);
    const memberError = useAppSelector(selectMemberError);

    const [member, setMember] = useState<Member>();
    const [showEditRegistration, setShowEditRegistration] = useState<boolean>(false);
    const [showEditStatus, setShowEditStatus] = useState<boolean>(false);
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchAllMembers(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showEditRegistration,showEditStatus]);

    const columns: Column<Member>[] = useMemo(() => [
        {
            Header: "Created",
            accessor: "creationDate"
        },
        {
            Header: "Surname",
            accessor: "surname"
        },
        {
            Header: "Other Names",
            accessor: name => { return (`${name.firstName} ${name.middleName}`) }
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row">
                        {row.original.status}
                        {row.original.registrationStatus === 'REGISTERED' &&
                            authProps.auth.token.features?.map(a => a.id).includes('t24dcttm8t') &&
                            <button
                                onClick={(e) => {
                                    setMember(row.original);
                                    setShowEditStatus(true);
                                }}>
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                        </button>}
                    </div>
                );
            },
        },
        {
            Header: "Registration",
            accessor: "registrationStatus",
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row">
                        {row.original.registrationStatus}
                        {authProps.auth.token.features?.map(a => a.id).includes('qsb2d2863f') &&
                            <button
                                onClick={(e) => {
                                    setMember(row.original);
                                    setShowEditRegistration(true);
                                }}>
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                        </button>}
                    </div>
                );
            },
        },
        {
            Header: "",
            accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        {row.original.registrationStatus === 'UNREGISTERED' &&
                            <IconButton aria-label="delete" 
                                onClick={() => {    
                                    setMember(row.original);
                                    setConfirmOpen(true);
                                }}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </IconButton>}
                    </div>
                );
            },
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[members]);

    const columnsSm: Column<Member>[] = useMemo(() => [
        {
            Header: "Created",
            accessor: "creationDate"
        },
        {
            Header: "Member",
            accessor: mem => { return (`${mem.firstName} ${mem.middleName} ${mem.surname}`) }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[members]);

    return (
        <div className="flex flex-col w-auto">
            {status === "loading" ? (<div className="pl-2">Loading...</div>) : (
                <React.Fragment>
                    {memberError &&
                    <span
                        className="text-red-500"
                        style={{ display: memberError ? "block" : "none" }}>
                        {memberError}
                    </span>}
                    <div>
                        {members &&
                            <React.Fragment>
                                <div className="hidden md:block">
                                    <WrappedTable<Member>
                                        name="Members"
                                        columns={columns}
                                        data={members}
                                        addonHooks={[]}
                                    /> 
                                </div>
                                <div className="md:hidden">
                                    <WrappedTable<Member>
                                        name="Members"
                                        columns={columnsSm}
                                        data={members}
                                        addonHooks={[]}
                                    />
                                </div>
                            </React.Fragment>}
                        <div>
                            {showEditRegistration &&
                                member &&
                                <EditRegistrationStatus 
                                    authProps={authProps} 
                                    setShowEditRegistration={setShowEditRegistration}
                                    member={member}
                                /> 
                            }
                        </div>
                        <div>
                            {showEditStatus &&
                                member &&
                                <EditMemberStatus 
                                    authProps={authProps} 
                                    setShowEditStatus={setShowEditStatus}
                                    member={member}
                                /> 
                            }
                        </div>
                        <div className="text-sm">
                            <ConfirmDialog
                                title={`Are you sure you want to delete ${member?.firstName}
                                    ${member?.middleName} ${member?.surname} ?`}
                                open={confirmOpen}
                                onClose={() => setConfirmOpen(false)}
                                onConfirm={() => {
                                    const params: MemberParams = {
                                        authProps: authProps,
                                        member: member,
                                    };
                                    dispatch(deleteUnregisteredMember(params));
                                }}>
                                {`Please note: ALL records for this user will be deleted.`}
                            </ConfirmDialog>
                        </div>
                    </div>
                </React.Fragment>)}
        </div>
    );
};

export default Members;