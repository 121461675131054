import React, { FC, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { Feature, fetchFeatures, selectFeatureError, selectFeatures, 
    selectFeatureStatus } from '../../../store/featureSlice';
import AuthProps from '../../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';

const FeaturePage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const features = useAppSelector(selectFeatures);
    const status = useAppSelector(selectFeatureStatus);
    const featureError = useAppSelector(selectFeatureError);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchFeatures(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const columns: Column<Feature>[] = useMemo(() => [
        {
          Header: "Feature",
          accessor: "feature"
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[features]);

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
            <React.Fragment>
                {featureError &&
                <span
                    className="text-red-500"
                    style={{ display: featureError ? "block" : "none" }}>
                    {featureError}
                </span>}
                <div>
                    {features &&
                        <React.Fragment>
                            <div>
                                <WrappedTable<Feature>
                                    name="Features"
                                    columns={columns}
                                    data={features}
                                    addonHooks={[]}
                                /> 
                            </div>
                        </React.Fragment>}
                </div>
            </React.Fragment>
    );
}

export default FeaturePage;