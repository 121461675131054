import React, { FC, SetStateAction, useEffect, useState } from 'react';
import AuthProps from '../AuthProps';
import { useAppSelector, useAppDispatch } from '../hooks';
import Select from '../../common/Select';
import { selectCashAccounts, fetchCashAccounts } from '../../../store/cashAccountSlice';


interface Props {
    authProps: AuthProps;
    setCashAccountId: React.Dispatch<SetStateAction<string>>;
    cashAccountId: string;
    isInvalid: boolean;
}

const CashAccount: FC<Props> = ({ authProps, setCashAccountId, cashAccountId, isInvalid }) => {

    const dispatch = useAppDispatch();

    const preSelectCashAccount = [{'id':'1111111111','name':'Select account'}];
    const cashAccounts = useAppSelector(selectCashAccounts);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchCashAccounts(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div className="grid grid-cols-3 w-full">

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="cashAccountId">
                Cash Account
            </label>
            
            <div className="col-span-2">
                <Select
                    id="cashAccountId"
                    className="p-1"
                    placeHolder={"Select"}
                    selectList={
                        preSelectCashAccount.map(select => {
                            return {id: select.id, name: select.name}
                        }).concat(
                            cashAccounts.map(cashAccount => {
                                return { id: cashAccount.id, name: cashAccount.cashAccountType === 'BANK' ?
                                    `${cashAccount.cashAccountType} ${cashAccount.bankAccount.bankBranch.bank.name} ${cashAccount.bankAccount.bankBranch.name} ${cashAccount.bankAccount.accountNumber}` : 
                                    `${cashAccount.cashAccountType} ${cashAccount.mobileMoneyAccount.mobileMoneyProvider} ${cashAccount.mobileMoneyAccount.phoneNumber}`}
                    }))}
                    required={true}
                    disabled={isInvalid}
                    onChange={e => {
                        setCashAccountId(e.target.value);
                    }} />
            </div>

        </div>
    );
}

export default CashAccount;