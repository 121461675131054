import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type ContentActionParams = {
    authProps: AuthProps;
    contentAction?: ContentAction;
    contentHeadingId?: string;
}

export type ContentAction = {
    id: string;
    contentHeadingId: string;
    action: string;
    priority: any;
    showPublic: boolean;
    showPrivate: boolean;
    disabled: boolean;
    path: string;
    creationDate?: string;
    modifiedDate?: string;
    edit?: void;
    delete?: void;
}

interface ContentActionState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: ContentAction[];
}

interface FetchErrors {
    message: string;
}

const initialState: ContentActionState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchContentActions = 
    createAsyncThunk<ContentAction[], ContentActionParams, { rejectValue: FetchErrors }>(
    "contentAction/fetchByHeadingId",
    async (params: ContentActionParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.CONTENT_ACTION_URL +
                "/" + params.contentHeadingId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch content action",
            });
        }
        return response.json();
    }
);

export const addContentAction =
    createAsyncThunk<ContentAction, ContentActionParams, { rejectValue: FetchErrors }>(
    "contentAction/add",
    async (params: ContentActionParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.CONTENT_ACTION_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.contentAction),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editContentAction =
    createAsyncThunk<ContentAction, ContentActionParams, { rejectValue: FetchErrors }>(
    "contentAction/edit",
    async (params: ContentActionParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.CONTENT_ACTION_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.contentAction),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteContentAction =
    createAsyncThunk<ContentAction, ContentActionParams, { rejectValue: FetchErrors }>(
    "contentAction/delete",
    async (params: ContentActionParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.CONTENT_ACTION_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.contentAction),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const contentActionSlice = createSlice({
    name: 'contentAction',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchContentActions.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addContentAction.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editContentAction.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteContentAction.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchContentActions.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addContentAction.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editContentAction.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteContentAction.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchContentActions.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addContentAction.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editContentAction.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteContentAction.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectContentActionStatus = (state: RootState) => state.contentAction.status;
export const selectContentActions = (state: RootState) => state.contentAction.list;
export const selectContentActionError = (state: RootState) => state.contentAction.error;
export const { clearList, clearError } = contentActionSlice.actions;
export default contentActionSlice.reducer;