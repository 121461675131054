import { CashbookTransactionMember } from './cashbookTransactionMemberSlice';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { SubsidiaryAccount } from './subsidiaryAccountSlice';
import { TransactionCode } from './transactionCodeSlice';

export type CashbookTransaction = {
    id?: string;
    cashbookEntryId?: string;
    transactionDate: string;
    transactionCodeId: string;
    transactionCode?: TransactionCode; 
    ref: string;
    refDate: string;
    amount: number;
    comments?: string;
    details?: string;
    edit?: void;
    delete?: void;
    code?: string;
    name?: string;
    cashbookTransactionMembers: CashbookTransactionMember[];
}

interface CashbookTransactionState {
    status: "loading" | "idle";
    error: any;
    list: CashbookTransaction[];
}

interface FetchErrors {
    message: string;
}

const initialState: CashbookTransactionState = {
    list: [],
    error: null,
    status: "idle",
}

export const cashbookTransactionSlice = createSlice({
    name: 'cashbookTransaction',
    initialState,
    reducers: {
        clearCashbookTransactionList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearCashbookTransactionError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
        addVirtualCashbookTransaction: (state, { payload }) => {
            if (!state.list.some( ({ id }) => id === payload.id )) {
                state.list.unshift(payload);
            }
            state.status = "idle";
        },
        editVirtualCashbookTransaction: (state, { payload }) => {
            const index: number = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
        },
        deleteVirtualCashbookTransaction: (state, { payload }) => {
            const index: number = state.list.findIndex( ({ id }) => id === payload.id );
            state.list = [...state.list.slice(0, index),
                ...state.list.slice(index + 1)];
        }
     },
});

export const selectCashbookTransactionStatus = (state: RootState) => state.cashbookTransactions.status;
export const selectCashbookTransactions = (state: RootState) => state.cashbookTransactions.list;
export const selectCashbookTransactionError = (state: RootState) => state.cashbookTransactions.error;
export const { clearCashbookTransactionList, clearCashbookTransactionError,
    addVirtualCashbookTransaction, editVirtualCashbookTransaction,
    deleteVirtualCashbookTransaction } = cashbookTransactionSlice.actions;
export default cashbookTransactionSlice.reducer;