import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { BankBranch } from './bankBranchSlice';

export type BankAccountParams = {
    authProps: AuthProps;
    bankAccount?: BankAccount;
    bankAccountId?: string;
}

export type BankAccount = {
    id: string;
    accountId: string;
    bankBranchId: string;
    bankBranch?: BankBranch;
    accountNumber: string;
    creationDate?: string;
    edit?: void;
    delete?: void;
}

interface BankAccountState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: BankAccount[];
}

interface FetchErrors {
    message: string;
}

const initialState: BankAccountState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchBankAccounts = 
    createAsyncThunk<BankAccount[], AuthProps, { rejectValue: FetchErrors }>(
    "bankAccounts/fetchByAccount",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.BANKACCOUNT_URL +
                "/" + params.config.ACCOUNT_ID, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch bank accounts",
            });
        }
        return response.json();
    }
);

export const addBankAccount =
    createAsyncThunk<BankAccount, BankAccountParams, { rejectValue: FetchErrors }>(
    "bankAccount/add",
    async (params: BankAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.BANKACCOUNT_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.bankAccount),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editBankAccount =
    createAsyncThunk<BankAccount, BankAccountParams, { rejectValue: FetchErrors }>(
    "bankAccount/edit",
    async (params: BankAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.BANKACCOUNT_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.bankAccount),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteBankAccount =
    createAsyncThunk<BankAccount, BankAccountParams, { rejectValue: FetchErrors }>(
    "bankAccount/delete",
    async (params: BankAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.BANKACCOUNT_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.bankAccount),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const bankAccountSlice = createSlice({
    name: 'bankAccount',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchBankAccounts.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addBankAccount.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editBankAccount.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteBankAccount.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchBankAccounts.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addBankAccount.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editBankAccount.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteBankAccount.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchBankAccounts.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addBankAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editBankAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteBankAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectBankAccountStatus = (state: RootState) => state.bankAccounts.status;
export const selectBankAccounts = (state: RootState) => state.bankAccounts.list;
export const selectBankAccountError = (state: RootState) => state.bankAccounts.error;
export const { clearList, clearError } = bankAccountSlice.actions;
export default bankAccountSlice.reducer;