import React, { FC, useState, useEffect, SetStateAction } from 'react';
import { Account } from '../../store/accountSlice';
import { addPrimaryAccount, editPrimaryAccount, PrimaryAccount, PrimaryAccountParams,
     selectPrimaryAccountError, clearError } from '../../store/primaryAccountSlice';
import { fetchReportingClasses, selectReportingClasses } from '../../store/reportingClassSlice';
import AuthProps from '../common/AuthProps';
import { useAppSelector, useAppDispatch } from '../common/hooks';
import useToast from '../../hooks/useToast';
import Select from '../common/Select';
import { grayedOutButtonClass, enabledButtonClass } from '../../shared/Utils';

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    primaryAccount?: PrimaryAccount | undefined;
    account: Account;
}

const AddEditPrimaryAccount: FC<Props> = ({ authProps, setShowAddEdit, primaryAccount,
    account }) => {

    const toast = useToast();
    const dispatch = useAppDispatch();
    const primaryAccountError = useAppSelector(selectPrimaryAccountError);

    const [editMode] = useState<boolean>(!!primaryAccount);
    const [code, setCode] = useState<string>(primaryAccount ? primaryAccount.code : "");
    const [name, setName] = useState<string>(primaryAccount ? primaryAccount.name : "");
    const [reportingClassId, setReportingClassId] = useState<string>(primaryAccount ? primaryAccount.reportingClassId : "");

    const reportingClassIdes = useAppSelector(selectReportingClasses);

    useEffect(() => {
        const controller = new AbortController();
        if (authProps.auth.token.features?.map(a => a.id).includes('f6v5hoi425')) {
            dispatch(fetchReportingClasses(authProps));
        }
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updatePrimaryAccount: PrimaryAccount = {
            id: primaryAccount ? primaryAccount.id : "",
            code: code,
            name: name,
            accountId: account.id,
            reportingClassId: reportingClassId,
        };

        const params: PrimaryAccountParams = {
            authProps: authProps,
            primaryAccount: updatePrimaryAccount,
        }

        const result = await dispatch(editMode ? editPrimaryAccount(params) : addPrimaryAccount(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            setShowAddEdit(false);
            toast('success', editMode ? 'Primary account edited successfully' : 'Primary account created successfully');
        }

    };

    const isInvalid = 
        code === ""
        || name === ""
        || account === null
        || reportingClassId === null;

        return (
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
    
                     {/* To trick the browser into centering the modal */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>
    
                    <div
                        className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline">
                        <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                                    <div className="max-w-md w-full space-y-4">
                                        <h2 className="text-center text-2xl font-normal text-gray-900">
                                            {editMode ? `Edit: ${primaryAccount?.name} (${account.name})` : 
                                                `Create new primary account (${account.name})`}
                                        </h2>
    
                                        <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                            <div className="rounded-md shadow-sm -space-y-px">
    
                                                <span
                                                    className="text-red-500"
                                                    style={{ display: primaryAccountError ? "block" : "none" }}>
                                                    {primaryAccountError}
                                                </span>

                                                <label className="text-gray-700 text-sm font-bold" htmlFor="code">
                                                    Code
                                                </label>
                                                <input
                                                    id="code"
                                                    name="code"
                                                    type="text"
                                                    value={code}
                                                    maxLength={5}
                                                    required
                                                    className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                    placeholder="Code"
                                                    onChange={(e) => {
                                                        dispatch(clearError(null));
                                                        setCode(e.target.value);
                                                    }}
                                                    autoFocus
                                                />

                                                <label className="text-gray-700 text-sm font-bold" htmlFor="name">
                                                    Name
                                                </label>
                                                <input
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    value={name}
                                                    maxLength={40}
                                                    required
                                                    className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                    placeholder="Name"
                                                    onChange={(e) => {
                                                        dispatch(clearError(null));
                                                        setName(e.target.value);
                                                    }}
                                                />

                                                <label className="text-gray-700 text-sm font-bold" htmlFor="reportingClassId">
                                                    Reporting Class
                                                </label>
                                                <Select
                                                    id="reportingClassId"
                                                    placeHolder={editMode ? `${primaryAccount.reportingClass.name}` : "Select Reporting Class"}
                                                    className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    selectList={reportingClassIdes.map(reportingClass => {
                                                        return { id: reportingClass.id, name: reportingClass.name }})}
                                                    required={true}
                                                    onChange={(e) => {
                                                        dispatch(clearError(null));
                                                        setReportingClassId(e.target.value);
                                                }} />
                                                
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <div>
                                                <button
                                                    type="submit"
                                                    disabled={isInvalid}
                                                    className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                    <span className="flex items-center">
                                                        {editMode ? "Edit" : "Create"}
                                                    </span>
                                                </button>
                                                </div>
                                                <div className="text-sm">
                                                    <button
                                                        type="submit"
                                                        onClick={cancel}
                                                        className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
        );

};

export default AddEditPrimaryAccount;