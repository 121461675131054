import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { fetchLoanTypes, selectLoanTypes } from '../../../store/loanTypeSlice';
import AuthProps from '../AuthProps';
import { useAppSelector, useAppDispatch } from '../hooks';
import Select from '../../common/Select';
import { CategoryParams, fetchCategoriesByLoanType, selectCategories } from '../../../store/categorySlice';


interface Props {
    authProps: AuthProps;
    setCategoryId: React.Dispatch<SetStateAction<string>>;
    categoryId: string;
}

const LoanCategory: FC<Props> = ({ authProps, setCategoryId, categoryId }) => {

    const dispatch = useAppDispatch();

    const preSelectLoanType = [{'id':'1111111111','name':'Select loan type'}];
    const loanTypes = useAppSelector(selectLoanTypes);
    const [loanTypeId, setLoanTypeId] = useState<string>("");

    const preSelectCategory = [{'id':'1111111111','name':'Select category'}];
    const categories = useAppSelector(selectCategories);


    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchLoanTypes(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div className="grid grid-cols-3 w-full">

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="loanTypeId">
                Type
            </label>

            <div className="col-span-2">

                <Select
                    id="loanTypeId"
                    className="p-1"
                    placeHolder="Select"
                    selectList={
                        preSelectLoanType.map(select => {
                            return {id: select.id, name: select.name}
                        }).concat(
                            loanTypes.map(loanType => {
                                return { id: loanType.id, name: loanType.name }
                    }))}
                    required={true}
                    onChange={e => {
                        setCategoryId("");
                        setLoanTypeId(e.target.value);
                        const params: CategoryParams = {
                            authProps: authProps,
                            loanTypeId: e.target.value,
                        }
                        dispatch(fetchCategoriesByLoanType(params));
                    }} />

            </div>

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="categoryId">
                Category
            </label>
            
            <div className="col-span-2">
                <Select
                    id="categoryId"
                    className="p-1"
                    placeHolder={"Select"}
                    selectList={
                        preSelectCategory.map(select => {
                            return {id: select.id, name: select.name}
                        }).concat(
                            categories.map(category => {
                                return { id: category.id, name: category.name }
                    }))}
                    required={true}
                    disabled={loanTypeId === "" || categoryId === "1111111111"}
                    onChange={e => {
                        setCategoryId(e.target.value);
                    }} />
            </div>

        </div>
    );
}

export default LoanCategory;