import React, { FC, useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { SubsidiaryAccount, fetchSubsidiaryAccountsByType, SubsidiaryAccountParams,
    selectSubsidiaryAccounts, selectSubsidiaryAccountError,
    selectSubsidiaryAccountStatus } from '../../store/subsidiaryAccountSlice';
import AuthProps from '../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../common/Table/WrappedTable';

interface Props {
    authProps: AuthProps;
    subsidiaryAccountType: string;
}

const BookAccountPage: FC<Props> = ({ authProps, subsidiaryAccountType }) => {

    const dispatch = useAppDispatch();

    const subsidiaryAccounts = useAppSelector(selectSubsidiaryAccounts);
    const status = useAppSelector(selectSubsidiaryAccountStatus);
    const subsidiaryAccountError = useAppSelector(selectSubsidiaryAccountError);

    useEffect(() => {
        const controller = new AbortController();
        const params: SubsidiaryAccountParams = {
            authProps: authProps,
            subsidiaryAccountType: subsidiaryAccountType,
        }
        dispatch(fetchSubsidiaryAccountsByType(params));
        return () => controller.abort();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const columns: Column<SubsidiaryAccount>[] = useMemo(() => [
        {
          Header: "Account",
          accessor: acct => { return( `${acct.primaryAccount.code}-${acct.code} ${acct?.name} (${acct.primaryAccount.name})` ) }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[subsidiaryAccounts]);

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
            <React.Fragment>
                {subsidiaryAccountError &&
                <span
                    className="text-red-500"
                    style={{ display: subsidiaryAccountError ? "block" : "none" }}>
                    {subsidiaryAccountError}
                </span>}
                <div>
                    {subsidiaryAccounts &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<SubsidiaryAccount>
                                    name="Control Accounts"
                                    columns={columns}
                                    data={subsidiaryAccounts}
                                    addonHooks={[]} /> 
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<SubsidiaryAccount>
                                    name="Control Accounts"
                                    columns={columns}
                                    data={subsidiaryAccounts}
                                    addonHooks={[]} />
                            </div>
                        </React.Fragment>}
                </div>
            </React.Fragment>
    );
}

export default BookAccountPage;