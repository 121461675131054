import React, { FC, useState, SetStateAction, useMemo } from 'react';
import { selectMemberLoanEntryError, MemberLoanEntry, MemberLoanEntryParams, addMemberLoanEntry,
    editMemberLoanEntry, clearMemberLoanEntryError } from '../../../store/memberLoanEntrySlice';
import AuthProps from '../../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import useToast from '../../../hooks/useToast';
import { grayedOutButtonClass, enabledButtonClass, getRandomId } from '../../../shared/Utils';
import Date from '../../common/Transactions/Date';
import Memo from '../../common/Transactions/Memo';
import Amount from '../../common/Transactions/Amount';
import { addVirtualMemberLoanTransaction, clearMemberLoanTransactionError,
        deleteVirtualMemberLoanTransaction, MemberLoanTransaction,
        selectMemberLoanTransactions } from '../../../store/memberLoanTransactionSlice';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import { ccyFormat } from '../../../shared/Utils';
import TransactionCode from '../../common/Transactions/TransactionCode';
import MemberLoan from '../../common/Transactions/MemberLoan';
import validator from 'validator';

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    memberLoanEntry?: MemberLoanEntry | undefined;
}

const AddEditMemberLoanEntry: FC<Props> = ({ authProps, setShowAddEdit, memberLoanEntry }) => {

    const toast = useToast();
    const dispatch = useAppDispatch();

    const [editMode] = useState<boolean>(!!memberLoanEntry);
    const [date, setDate] = useState<string>(editMode ? memberLoanEntry.date : "");
    const [dateError, setDateError] = useState<string>("");
    const [transactionCodeId, setTransactionCodeId] = useState<string>(editMode ? memberLoanEntry.transactionCodeId : "")
    const [memo, setMemo] = useState<string>(editMode ? memberLoanEntry.memo : "");

    const memberLoanEntryError = useAppSelector(selectMemberLoanEntryError);

    const [memberId, setMemberId] = useState<string>("");
    const [memberName, setMemberName] = useState<string>("");

    const [loanId, setLoanId] = useState<string>("");
    const [loanRef, setLoanRef] = useState<string>("");
    const [loanName, setLoanName] = useState<string>("");

    const [amount, setAmount] = useState<string>("");
    const [amountError, setAmountError] = useState<string>("");

    const memberLoanTransactions = useAppSelector(selectMemberLoanTransactions);

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updatedMemberLoanEntry: MemberLoanEntry = {
            id: editMode ? memberLoanEntry.id : "",
            date: date,
            transactionCodeId: transactionCodeId,
            memo: memo,
            makerId: authProps.auth.token.userId,
            accountId: authProps.config.ACCOUNT_ID,
            transactions: memberLoanTransactions,
        }
        const params: MemberLoanEntryParams = {
            authProps: authProps,
            memberLoanEntry: updatedMemberLoanEntry,
        }

        const result = await dispatch(editMode ? editMemberLoanEntry(params) : addMemberLoanEntry(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            toast('success', editMode ? `General ledger entry edited successfully` : `General ledger entry saved successfully`);
        }

        if (!errorCondition) {
            setShowAddEdit(false);
        }
    }

    const addNewMemberLoanTransaction = (e: React.MouseEvent<HTMLButtonElement>,
        memberId: string, amount: string,
        memberName: string, loanRef: string,
        loanName: string) => {
        
        const newMemberLoanTransaction: MemberLoanTransaction = {
            id: getRandomId(10),
            memberId: memberId,
            loanId: loanId,
            amount: Number(amount),
            memberName: memberName,
            loanRef: loanRef,
            loanName: loanName,
        }
        dispatch(addVirtualMemberLoanTransaction(newMemberLoanTransaction));
        // clear fields
        setMemberId("1111111111");
        setAmount("");
    }

    const deleteSelectedMemberLoanTransaction = (e: React.MouseEvent<HTMLButtonElement>,
        memberLoanTransaction: MemberLoanTransaction) => {
        e.preventDefault();
        dispatch(clearMemberLoanTransactionError(null));
        dispatch(deleteVirtualMemberLoanTransaction(memberLoanTransaction));
    }

    const totalAmount: any = (items: MemberLoanTransaction[]) => {
        return items.map( ({ amount }) => Number(amount))
            .reduce((sum, i) => sum + i, 0) || 0;
    }

    const memberLoanTransactionColumns: Column<MemberLoanTransaction>[] = useMemo(() => [
        {
            Header: "Member",
            accessor: acct => { return( acct.memberName ) }
        },
        {
            Header: "Loan",
            accessor: lo => { return (`${lo.loanName} (Ref: ${lo.loanRef})`) }
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Amount
                </div>
            ),
            accessor: "amount",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        <div className="font-bold">
                            {ccyFormat(Number(row.original.amount), 2)}
                        </div>
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    {ccyFormat(Number(totalAmount(columnProps.data)), 2)}
                </div>
            ),
        },
        {
            Header: "",
            accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        <button
                            onClick={e => deleteSelectedMemberLoanTransaction(e, row.original)}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[memberLoanTransactions]);

    const isInvalid =
        date === ""
        || memberLoanTransactions.length === 0
        || transactionCodeId === ""
        || Number(totalAmount(memberLoanTransactions)) <= 0;

    const isInvalidItem = memberId === ""
        || memberId === "1111111111"
        || loanId === ""
        || loanId === "1111111111";

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">

                    <div className="flex items-center md:px-4 py-2">
                        <div className="relative sm:w-full p-2 mx-auto bg-white rounded-md shadow-lg">
                            
                            <div className="w-full block inset bg-[#fffbe6] rounded-md border-2">
                                <h2 className="text-center text-2xl font-normal text-gray-900">
                                    {editMode ? `Edit: ${memberLoanEntry.ref}` : `Create a new member loan entry`}
                                </h2>
                            </div>

                            <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                <div className="rounded-md shadow-sm -space-y-px">

                                    <span
                                        className="text-red-500"
                                        style={{ display: memberLoanEntryError ? "block" : "none" }}>
                                        {memberLoanEntryError}
                                    </span>

                                    <Date
                                        setDate={setDate}
                                        date={date}
                                        setDateError={setDateError}
                                        dateError={dateError}
                                        label="Date"
                                        isInvalid={false}
                                        defaultFocus={true} />

                                    <hr className="h-px p-1 my-8 border-0" />

                                    <TransactionCode
                                        authProps={authProps}
                                        setTransactionCodeId={setTransactionCodeId}
                                        transactionCodeId={transactionCodeId}
                                        book={'MEMBER'}
                                        transactionCodeTypeId="fa64ez75rc"
                                        isInvalid={date === "" || !validator.isDate(date)} />

                                    <Memo
                                        setMemo={setMemo}
                                        memo={memo}
                                        memoName={'[Memo]'}
                                        placeholder={'max 100 (optional)'}
                                        maxLength={100} />
                                    
                                </div>

                                <div className="block bg-slate-100 inset-1 p-2 mt-2">

                                    <MemberLoan
                                        authProps={authProps}
                                        setMemberId={setMemberId}
                                        memberId={memberId}
                                        setMemberName={setMemberName}
                                        setLoanId={setLoanId}
                                        loanId={loanId}
                                        setLoanRef={setLoanRef}
                                        setLoanName={setLoanName} />

                                    <Amount
                                        setAmount={setAmount}
                                        amount={amount}
                                        setAmountError={setAmountError}
                                        amountError={amountError}
                                        placeholderText="> 0"
                                        maxLengthValue={9} />

                                    <div className="p-1 w-auto">
                                        <button
                                            disabled={isInvalidItem} 
                                            className={isInvalidItem ? grayedOutButtonClass : enabledButtonClass}
                                            onClick={(e) => {
                                                clearMemberLoanTransactionError(null);
                                                addNewMemberLoanTransaction(e, 
                                                    memberId, amount,
                                                    memberName, loanRef,
                                                    loanName);
                                            }}>
                                            <span className="flex items-center">
                                                {memberId === "" ? "Add Transaction" : `Add`}
                                            </span>
                                        </button>
                                    </div>

                                    <div>
                                        {<WrappedTable<MemberLoanTransaction>
                                            name=""
                                            columns={memberLoanTransactionColumns}
                                            data={memberLoanTransactions}
                                            addonHooks={[]}
                                            includeFooter />}
                                    </div>

                                </div>

                                <div className="flex items-center justify-between">
                                    <div>
                                    <button
                                        type="submit"
                                        disabled={isInvalid}
                                        className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                        <span className="flex items-center">
                                            {editMode ? "Edit" : "Save"}
                                        </span>
                                    </button>
                                    </div>
                                    <div className="text-sm">
                                        <button
                                            type="submit"
                                            onClick={cancel}
                                            className="transition-colors hover:text-gray-900 font-medium duration-200">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                           
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AddEditMemberLoanEntry;