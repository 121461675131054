import React, { FC, useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { CashAccount, fetchCashAccountsByType, selectCashAccountError, selectCashAccounts, 
    selectCashAccountStatus, clearError, deleteCashAccount, CashAccountParams, clearCashAccountList } 
    from '../../store/cashAccountSlice';
import AuthProps from '../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../common/Table/WrappedTable';
import AddEditCashAccount from './AddEditCashAccount';

interface Props {
    authProps: AuthProps;
    cashAccountType: string;
}
const CashAccountPage: FC<Props> = ({ authProps, cashAccountType }) => {

    const dispatch = useAppDispatch();

    const cashAccounts = useAppSelector(selectCashAccounts);
    const status = useAppSelector(selectCashAccountStatus);
    const cashAccountError = useAppSelector(selectCashAccountError);

    const [cashAccount, setCashAccount] = useState<CashAccount>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(clearCashAccountList(null));
        const params: CashAccountParams = {
            authProps: authProps,
            cashAccountType: cashAccountType,
        }
        dispatch(fetchCashAccountsByType(params));
        return () => controller.abort();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setCashAccount(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, cashAccount: CashAccount) => {
    	e.preventDefault();
        dispatch(clearError(null));
    	setCashAccount(cashAccount);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, cashAccount: CashAccount) => {
		e.preventDefault();
        dispatch(clearError(null));
    	setCashAccount(cashAccount);
        console.log(`cashAccount ${JSON.stringify(cashAccount)}`);
    	const params: CashAccountParams = {
        	authProps: authProps,
        	cashAccount: cashAccount,
      	}
    	dispatch(deleteCashAccount(params))
 	};

    const bankAccounts: Column<CashAccount>[] = useMemo(() => [
        {
          Header: "Bank",
          accessor: bank => { return ( `${bank.bankAccount.bankBranch.bank.code} ${bank.bankAccount.bankBranch.bank.name}`) }
        },
        {
            Header: "Branch",
            accessor: branch => { return (`${branch.bankAccount.bankBranch.code} ${branch.bankAccount.bankBranch.name}`) }
        },
        {
            Header: "Account",
            accessor: bankAcc => { return (`${bankAcc.bankAccount.accountNumber}`) }
        },
        {
            Header: "G/L Account",
            accessor: acct => { return( `${acct.subsidiaryAccount?.primaryAccount.code}-${acct.subsidiaryAccount?.code} ${acct.subsidiaryAccount?.name} (${acct.subsidiaryAccount?.primaryAccount.name})` ) }
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div className="items-center margin: auto">
                        {authProps.auth.token.features?.map(a => a.id).includes('lde7b27piq') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>}
                  </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('ig2krpm231') &&
                            <button
                                onClick={e => inLineDelete(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </button>}
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[cashAccounts]);

    const mobileMoneyAccounts: Column<CashAccount>[] = useMemo(() => [
        {
          Header: "Provider",
          accessor: provider => { return (`${provider.mobileMoneyAccount.mobileMoneyProvider}`) }
        },
        {
            Header: "Phone Number",
            accessor: phone => { return (`${phone.mobileMoneyAccount.areaCode} ${phone.mobileMoneyAccount.phoneNumber}`) }
        },
        {
            Header: "Account",
            accessor: acct => { return( `${acct.subsidiaryAccount?.primaryAccount.code}-${acct.subsidiaryAccount?.code} ${acct.subsidiaryAccount?.name} (${acct.subsidiaryAccount?.primaryAccount.name})` ) }
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div className="items-center margin: auto">
                        {authProps.auth.token.features?.map(a => a.id).includes('lde7b27piq') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>}
                  </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('ig2krpm231') &&
                            <button
                                onClick={e => inLineDelete(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </button>}
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[cashAccounts]);

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
            <React.Fragment>
                {cashAccountError &&
                <span
                    className="text-red-500"
                    style={{ display: cashAccountError ? "block" : "none" }}>
                    {cashAccountError}
                </span>}
                <div>
                    {cashAccounts &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<CashAccount>
                                    name="Bank Accounts"
                                    columns={cashAccountType === 'BANK' ? bankAccounts : mobileMoneyAccounts}
                                    data={cashAccounts}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('p2n78me57t')}
                                /> 
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<CashAccount>
                                    name="Bank Accounts"
                                    columns={cashAccountType === 'BANK' ? bankAccounts : mobileMoneyAccounts}
                                    data={cashAccounts}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('p2n78me57t')}
                                />
                            </div>
                        </React.Fragment>}
                </div>
                <div>
                    {showAddEdit &&
                        <AddEditCashAccount 
                            authProps={authProps} 
                            setShowAddEdit={setShowAddEdit}
                            cashAccount={cashAccount}
                            cashAccountType={cashAccountType}
                        />}
                </div>
            </React.Fragment>
    );
}

export default CashAccountPage;