import { addBank } from './bankSlice';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { TransactionCode } from './transactionCodeSlice';
import { SubsidiaryAccount } from './subsidiaryAccountSlice';

export type ControlAccountParams = {
    authProps: AuthProps;
    controlAccount?: ControlAccount;
    book?: string;
    transactionCodeTypeId?: string;
}

export type ControlAccount = {
    id: string;
    transactionCodeId: string;
    transactionCode?: TransactionCode;
    subsidiaryAccountId: string;
    subsidiaryAccount?: SubsidiaryAccount;
    book: string;
    creationDate?: string;
    edit?: void;
    delete?: void;
}

interface ControlAccountState {
    status: "loading" | "adding" | "editing" | "deleting" | "idle";
    error: any;
    list: ControlAccount[];
}

interface FetchErrors {
    message: string;
}

const initialState: ControlAccountState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchControlAccounts = 
    createAsyncThunk<ControlAccount[], ControlAccountParams, { rejectValue: FetchErrors }>(
    "controlAccounts/fetchByBook",
    async (params: ControlAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.CONTROLACCOUNT_URL +
                "/" + params.book, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch control accounts",
            });
        }
        return response.json();
    }
);

export const fetchControlAccountsByType = 
    createAsyncThunk<ControlAccount[], ControlAccountParams, { rejectValue: FetchErrors }>(
    "controlAccounts/fetchByType",
    async (params: ControlAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.CONTROLACCOUNT_URL +
                "/type/" + params.transactionCodeTypeId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch control accounts",
            });
        }
        return response.json();
    }
);

export const addControlAccount =
    createAsyncThunk<ControlAccount, ControlAccountParams, { rejectValue: FetchErrors }>(
    "controlAccount/add",
    async (params: ControlAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.CONTROLACCOUNT_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.controlAccount),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editControlAccount =
    createAsyncThunk<ControlAccount, ControlAccountParams, { rejectValue: FetchErrors }>(
    "controlAccount/edit",
    async (params: ControlAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.CONTROLACCOUNT_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.controlAccount),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteControlAccount =
    createAsyncThunk<ControlAccount, ControlAccountParams, { rejectValue: FetchErrors }>(
    "controlAccount/delete",
    async (params: ControlAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.CONTROLACCOUNT_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.controlAccount),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const controlAccountSlice = createSlice({
    name: 'controlAccount',
    initialState,
    reducers: {
        clearControlAccountList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearControlAccountError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchControlAccounts.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(fetchControlAccountsByType.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addControlAccount.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editControlAccount.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteControlAccount.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchControlAccounts.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(fetchControlAccountsByType.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addControlAccount.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editControlAccount.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteControlAccount.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchControlAccounts.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(fetchControlAccountsByType.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addControlAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editControlAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteControlAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectControlAccountStatus = (state: RootState) => state.controlAccounts.status;
export const selectControlAccounts = (state: RootState) => state.controlAccounts.list;
export const selectControlAccountError = (state: RootState) => state.controlAccounts.error;
export const { clearControlAccountList, clearControlAccountError } = controlAccountSlice.actions;
export default controlAccountSlice.reducer;