import React, { FC, useState } from 'react';
import AuthProps from '../common/AuthProps';
import FeaturePage from './Features/FeaturePage';
import ProcessPage from './Processes/ProcessPage';
import FeatureProcessActionPage from './FeatureProcessActions/FeatureProcessActionPage';
import RoleFeaturePage from './RoleFeatures/RoleFeaturePage';
import AppMenuPage from './AppMenu/AppMenuPage';
import AuditPage from './Audit/AuditPage';

const SystemMain: FC<AuthProps> = (authProps) => {

    const [openTab, setOpenTab] = useState(1);

    return (

        <>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <ul
                        className="flex mb-0 list-none flex-wrap pt-1 pb-2 flex-row"
                        role="tablist"
                    >
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                            {authProps.auth.token.features?.map(a => a.id).includes('q52jalqjvb') &&
                                <a className={
                                    "text-xs font-bold uppercase px-5 py-1 shadow-sm rounded-xs block leading-normal " +
                                    (openTab === 1
                                        ? "text-white bg-[#A7CE3C]"
                                        : "text-gray-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(1);
                                    }}
                                    data-toggle="tab"
                                    href="#link1"
                                    role="tablist"
                                >
                                    Features
                                </a>}
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                            {authProps.auth.token.features?.map(a => a.id).includes('rndu1hcs6d') &&
                                <a
                                    className={
                                    "text-xs font-bold uppercase px-5 py-1 shadow-lg rounded-xs block leading-normal " +
                                    (openTab === 2
                                        ? "text-white bg-[#A7CE3C]"
                                        : "text-gray-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(2);
                                    }}
                                    data-toggle="tab"
                                    href="#link2"
                                    role="tablist"
                                >
                                    Processes
                                </a>}
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                            {authProps.auth.token.features?.map(a => a.id).includes('nymw0m2n0g') &&
                                <a
                                    className={
                                    "text-xs font-bold uppercase px-5 py-1 shadow-lg rounded-xs block leading-normal " +
                                    (openTab === 3
                                        ? "text-white bg-[#A7CE3C]"
                                        : "text-gray-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(3);
                                    }}
                                    data-toggle="tab"
                                    href="#link3"
                                    role="tablist"
                                >
                                    Feature Processes
                                </a>}
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                            {authProps.auth.token.features?.map(a => a.id).includes('c9pwksmns2') &&
                                <a
                                    className={
                                    "text-xs font-bold uppercase px-5 py-1 shadow-lg rounded-xs block leading-normal " +
                                    (openTab === 4
                                        ? "text-white bg-[#A7CE3C]"
                                        : "text-gray-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(4);
                                    }}
                                    data-toggle="tab"
                                    href="#link4"
                                    role="tablist"
                                >
                                    Role Features
                                </a>}
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                            {authProps.auth.token.features?.map(a => a.id).includes('86ie9b2fr1') &&
                                <a
                                    className={
                                    "text-xs font-bold uppercase px-5 py-1 shadow-lg rounded-xs block leading-normal " +
                                    (openTab === 5
                                        ? "text-white bg-[#A7CE3C]"
                                        : "text-gray-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(5);
                                    }}
                                    data-toggle="tab"
                                    href="#link5"
                                    role="tablist"
                                >
                                    App Menus
                                </a>}
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                            {authProps.auth.token.features?.map(a => a.id).includes('58nip33g03') &&
                                <a
                                    className={
                                    "text-xs font-bold uppercase px-5 py-1 shadow-lg rounded-xs block leading-normal " +
                                    (openTab === 6
                                        ? "text-white bg-[#A7CE3C]"
                                        : "text-gray-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(6);
                                    }}
                                    data-toggle="tab"
                                    href="#link6"
                                    role="tablist"
                                >
                                    Audit
                                </a>}
                        </li>
                    </ul>
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-sm">
                        <div className="px-0 py-0 flex-auto">
                            <div className="tab-content tab-space">
                                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                    <FeaturePage auth={authProps.auth} config={authProps.config} />
                                </div>
                                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                                    <ProcessPage auth={authProps.auth} config={authProps.config} />
                                </div>
                                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                                    <FeatureProcessActionPage auth={authProps.auth} config={authProps.config} />
                                </div>
                                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                                    <RoleFeaturePage auth={authProps.auth} config={authProps.config} />
                                </div>
                                <div className={openTab === 5 ? "block" : "hidden"} id="link5">
                                    <AppMenuPage auth={authProps.auth} config={authProps.config} />
                                </div>
                                <div className={openTab === 6 ? "block" : "hidden"} id="link5">
                                    <AuditPage auth={authProps.auth} config={authProps.config} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  );
};

export default SystemMain;