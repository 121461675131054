import { configureStore } from '@reduxjs/toolkit';
import feedbackSlice from './feedbackSlice';
import memberSlice from './memberSlice';
import memberIdentitySlice from './memberIdentitySlice';
import nextOfKinSlice from './nextOfKinSlice';
import beneficiarySlice from './beneficiarySlice';
import guardianSlice from './guardianSlice';
import personalPhoneSlice from './personalPhoneSlice';
import addressSlice from './addressSlice';
import memberRegistrationSlice from './memberRegistrationStatusSlice';
import memberStatusSlice from './memberStatusSlice';
import monthlyDeductionSlice from './monthlyDeductionSlice';
import employerSlice from './employerSlice';
import branchSlice from './branchSlice';
import memberBranchSlice from './memberBranchSlice';
import accountSlice from './accountSlice';
import primaryAccountSlice from './primaryAccountSlice';
import reportingClassSlice from './reportingClassSlice';
import subsidiaryAccountSlice from './subsidiaryAccountSlice';
import bankSlice from './bankSlice';
import bankBranchSlice from './bankBranchSlice';
import contentHeadingSlice from './contentHeadingSlice';
import contentBodySlice from './contentBodySlice';
import contentActionSlice from './contentActionSlice';
import featureSlice from './featureSlice';
import processSlice from './processSlice';
import featureProcessActionSlice from './featureProcessActionSlice';
import roleFeatureSlice from './roleFeatureSlice';
import roleSlice from './roleSlice';
import appMenuSlice from './appMenuSlice';
import auditSlice from './auditSlice';
import emailMessageSlice from './emailMessageSlice';
import userEmailAddressSlice from './userEmailAddressSlice';
import emailRecipientSlice from './emailRecipientSlice';
import loanSlice from './loanSlice';
import loanStatusSlice from './loanStatusSlice';
import loanStatusReasonSlice from './loanStatusReasonSlice';
import memberPeerGroupSlice from './memberPeerGroupSlice';
import financialYearSlice from './financialYearSlice';
import reportingPeriodSlice from './reportingPeriodSlice';
import transactionCodeSlice from './transactionCodeSlice';
import trialBalanceSlice from './trialBalanceSlice';
import transactionCodeAccountSlice from './transactionCodeAccountSlice';
import loanStatementSlice from './loanStatementSlice';
import generalLedgerEntrySlice from './generalLedgerEntrySlice';
import generalLedgerTransactionSlice from './generalLedgerTransactionSlice';
import controlAccountSlice from './controlAccountSlice';
import transactionCodeTypeSlice from './transactionCodeTypeSlice';
import loanTypeSlice from './loanTypeSlice';
import categorySlice from './categorySlice';
import memberLoanTransactionSlice from './memberLoanTransactionSlice';
import memberLoanEntrySlice from './memberLoanEntrySlice';
import emailSlice from './emailSlice';
import bankAccountSlice from './bankAccountSlice';
import mobileMoneyAccountSlice from './mobileMoneyAccountSlice';
import cashAccountSlice from './cashAccountSlice';
import cashbookEntrySlice from './cashbookEntrySlice';
import cashbookTransactionSlice from './cashbookTransactionSlice';
import cashbookTransactionMemberSlice from './cashbookTransactionMemberSlice';

export const store = configureStore({
    reducer: {
        feedback: feedbackSlice,
        members: memberSlice,
        memberIdentities: memberIdentitySlice,
        nextOfKin: nextOfKinSlice,
        beneficiaries: beneficiarySlice,
        guardians: guardianSlice,
        personalPhones: personalPhoneSlice,
        emails: emailSlice,
        address: addressSlice,
        memberRegistrationStatus: memberRegistrationSlice,
        memberStatus: memberStatusSlice,
        monthlyDeduction: monthlyDeductionSlice,
        employers: employerSlice,
        branches: branchSlice,
        memberBranch: memberBranchSlice,
        accounts: accountSlice,
        primaryAccounts: primaryAccountSlice,
        reportingClasses: reportingClassSlice,
        subsidiaryAccounts: subsidiaryAccountSlice,
        banks: bankSlice,
        bankBranches: bankBranchSlice,
        contentHeadings: contentHeadingSlice,
        contentBody: contentBodySlice,
        contentAction: contentActionSlice,
        features: featureSlice,
        processes: processSlice,
        featureProcessActions: featureProcessActionSlice,
        roleFeatures: roleFeatureSlice,
        roles: roleSlice,
        appMenus: appMenuSlice,
        audit: auditSlice,
        emailMessages: emailMessageSlice,
        userEmailAddresses: userEmailAddressSlice,
        emailRecipients: emailRecipientSlice,
        loans: loanSlice,
        loanStatuses: loanStatusSlice,
        loanStatusReasons: loanStatusReasonSlice,
        memberPeerGroups: memberPeerGroupSlice,
        financialYears: financialYearSlice,
        reportingPeriods: reportingPeriodSlice,
        transactionCodes: transactionCodeSlice,
        trialBalance: trialBalanceSlice,
        transactionCodeAccounts: transactionCodeAccountSlice,
        loanStatements: loanStatementSlice,
        generalLedgerTransactions: generalLedgerTransactionSlice,
        generalLedgerEntries: generalLedgerEntrySlice,
        transactionCodeTypes: transactionCodeTypeSlice,
        controlAccounts: controlAccountSlice,
        loanTypes: loanTypeSlice,
        categories: categorySlice,
        memberLoanTransactions: memberLoanTransactionSlice,
        memberLoanEntries: memberLoanEntrySlice,
        bankAccounts: bankAccountSlice,
        mobileMoneyAccounts: mobileMoneyAccountSlice,
        cashAccounts: cashAccountSlice,
        cashbookEntries: cashbookEntrySlice,
        cashbookTransactions: cashbookTransactionSlice,
        cashbookTransactionMembers: cashbookTransactionMemberSlice,
    }
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;