import React, { FC, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { AppMenu, fetchAppMenus, selectAppMenuError, 
    selectAppMenus, selectAppMenuStatus,
     AppMenuParams } from '../../../store/appMenuSlice';
import AuthProps from '../../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import Select from '../../common/Select';

const AppMenuPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const appMenus = useAppSelector(selectAppMenus);
    const status = useAppSelector(selectAppMenuStatus);
    const appMenuError = useAppSelector(selectAppMenuError);

    const apps = [
        {'id':'AdminWeb', 'name':'AdminWeb'},
        {'id':'MemberWeb', 'name':'MemberWeb'},
        {'id':'MemberAndroid', 'name':'MemberAndroid'}
    ];

    const columns: Column<AppMenu>[] = useMemo(() => [
        {
            Header: "Feature",
            accessor: "feature"
        },
        {
            Header: "Priority",
            accessor: "priority"
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[appMenus]);

    return (
        <div className="flex flex-col w-auto">
            {
                <div className="flex relative p-1 w-auto">
                    <Select
                        id="app"
                        placeHolder="Select App"
                        className="shadow-sm border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        selectList={
                            apps.map(app => {
                                return {id: app.id, name: app.name}
                            })}
                        required={true}
                        onChange={e => {
                            e.preventDefault();
                            const params: AppMenuParams = {
                                authProps: authProps,
                                app: e.target.value,
                            }
                            dispatch(fetchAppMenus(params));
                        }} />
                </div>
            }
            {status === "loading" ? (<div className="pl-2">Loading...</div>) : (
                <React.Fragment>
                    {appMenuError &&
                    <span
                        className="text-red-500"
                        style={{ display: appMenuError ? "block" : "none" }}>
                        {appMenuError}
                    </span>}
                    <div>
                        {appMenus &&
                            <React.Fragment>
                                <div className="hidden md:block">
                                    <WrappedTable<AppMenu>
                                        name="App Menu"
                                        columns={columns}
                                        data={appMenus}
                                        addonHooks={[]}
                                    /> 
                                </div>
                            </React.Fragment>}
                    </div>
                </React.Fragment>)};
        </div>
    );
}

export default AppMenuPage;