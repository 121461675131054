import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { fetchReportingClasses, selectReportingClasses } from '../../../store/reportingClassSlice';
import AuthProps from '../AuthProps';
import { useAppSelector, useAppDispatch } from '../hooks';
import Select from '../../common/Select';
import { fetchSubsidiaryAccountsByReportingClass, selectSubsidiaryAccounts, SubsidiaryAccountParams } from '../../../store/subsidiaryAccountSlice';


interface Props {
    authProps: AuthProps;
    setSubsidiaryAccountId: React.Dispatch<SetStateAction<string>>;
    subsidiaryAccountId: string;
    setSubsidiaryAccountCode?: React.Dispatch<SetStateAction<string>>;
    setSubsidiaryAccountName?: React.Dispatch<SetStateAction<string>>;
    setPrimaryAccountCode?: React.Dispatch<SetStateAction<string>>;
    setPrimaryAccountName?: React.Dispatch<SetStateAction<string>>;
}

const SubsidiaryAccount: FC<Props> = ({ authProps, setSubsidiaryAccountId, subsidiaryAccountId,
    setSubsidiaryAccountCode, setSubsidiaryAccountName,
    setPrimaryAccountCode, setPrimaryAccountName }) => {

    const dispatch = useAppDispatch();

    const preSelectClass = [{'id':'1111111111','name':'Select class'}];
    const reportingClasses = useAppSelector(selectReportingClasses);
    const [reportingClassId, setReportingClassId] = useState<string>("");

    const preSelectSubsidiaryAccount = [{'id':'1111111111','name':'Select account'}];
    const subsidiaryAccounts = useAppSelector(selectSubsidiaryAccounts);


    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchReportingClasses(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div className="grid grid-cols-3 w-full">

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="reportingClassId">
                Class
            </label>

            <div className="col-span-2">

                <Select
                    id="reportingClassId"
                    className="p-1"
                    placeHolder="Select"
                    selectList={
                        preSelectClass.map(select => {
                            return {id: select.id, name: select.name}
                        }).concat(
                            reportingClasses.map(reportingClass => {
                                return { id: reportingClass.id, name: reportingClass.name }
                    }))}
                    required={true}
                    onChange={e => {
                        setSubsidiaryAccountId("");
                        setReportingClassId(e.target.value);
                        const params: SubsidiaryAccountParams = {
                            authProps: authProps,
                            reportingClassId: e.target.value,
                        }
                        dispatch(fetchSubsidiaryAccountsByReportingClass(params));
                    }} />

            </div>

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="subsidiaryAccountId">
                Account
            </label>
            
            <div className="col-span-2">
                <Select
                    id="subsidiaryAccountId"
                    className="p-1"
                    placeHolder={"Select"}
                    selectList={
                        preSelectSubsidiaryAccount.map(select => {
                            return {id: select.id, name: select.name}
                        }).concat(
                            subsidiaryAccounts.map(subsidiaryAccount => {
                                return { id: subsidiaryAccount.id, name: `${subsidiaryAccount.primaryAccount.code}-${subsidiaryAccount.code} ${subsidiaryAccount.name} (${subsidiaryAccount.primaryAccount.name})` }
                    }))}
                    required={true}
                    disabled={reportingClassId === "" || subsidiaryAccountId === "1111111111"}
                    onChange={e => {
                        setSubsidiaryAccountId(e.target.value);
                        var subsidiaryAccount = subsidiaryAccounts.find( ({ id }) => id === e.target.value);
                        setSubsidiaryAccountCode(subsidiaryAccount.code);
                        setSubsidiaryAccountName(subsidiaryAccount.name);
                        setPrimaryAccountCode(subsidiaryAccount.primaryAccount.code);
                        setPrimaryAccountName(subsidiaryAccount.primaryAccount.name);
                    }} />
            </div>

        </div>
    );
}

export default SubsidiaryAccount;