import React, { FC, useState, SetStateAction } from 'react';
import { selectCashAccountError, CashAccount, CashAccountParams, addCashAccount,
    editCashAccount, clearError } from '../../store/cashAccountSlice';
import AuthProps from '../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import useToast from '../../hooks/useToast';
import { grayedOutButtonClass, enabledButtonClass } from '../../shared/Utils';
import CashAccountType from '../common/Transactions/CashAccountType';
import BankAccount from '../common/Transactions/BankAccount';
import MobileMoneyAccount from '../common/Transactions/MobileMoneyAccount';
import SubsidiaryAccount from '../common/Transactions/SubsidiaryAccount';

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    cashAccount?: CashAccount | undefined;
    cashAccountType: string;
}

const AddEditCashAccount: FC<Props> = ({ authProps, setShowAddEdit, cashAccount,
    cashAccountType }) => {

    const toast = useToast();
    const dispatch = useAppDispatch();

    const [editMode] = useState<boolean>(!!cashAccount);
    const [bankAccountId, setBankAccountId] = useState<string>(editMode ? cashAccount.bankAccountId : "");
    const [mobileMoneyAccountId, setMobileMoneyAccountId] = useState<string>(editMode ? cashAccount.mobileMoneyAccountId : "");
    const [subsidiaryAccountId, setSubsidiaryAccountId] = useState<string>(editMode ? cashAccount.subsidiaryAccountId : "");

    const cashAccountError = useAppSelector(selectCashAccountError);

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updatedCashAccount: CashAccount = {
            id: editMode ? cashAccount.id : "",
            cashAccountType: cashAccountType,
            bankAccountId: bankAccountId,
            mobileMoneyAccountId: mobileMoneyAccountId,
            subsidiaryAccountId: subsidiaryAccountId,
            accountId: authProps.config.ACCOUNT_ID,
        }
        const params: CashAccountParams = {
            authProps: authProps,
            cashAccount: updatedCashAccount,
        }

        const result = await dispatch(editMode ? editCashAccount(params) : addCashAccount(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            toast('success', editMode ? `CashAccount edited successfully` : `CashAccount added successfully`);
        }

        if (!errorCondition) {
            setShowAddEdit(false);
        }
    }

    const isInvalid =
        (cashAccountType === 'BANK' ? bankAccountId === ""
            : mobileMoneyAccountId === "");

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                    <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                                <div className="max-w-md w-full space-y-4">
                                    <h2 className="text-center text-2xl font-normal text-gray-900">
                                        {editMode ? `Edit: ${cashAccount.cashAccountType} ${cashAccount.cashAccountType === 'BANK' ? 
                                            cashAccount.bankAccount.bankBranch.name : cashAccount.mobileMoneyAccount.mobileMoneyProvider} ` : `Create a new cash account`}
                                    </h2>

                                    <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                        <div className="rounded-md shadow-sm -space-y-px">

                                            <span
                                                className="text-red-500"
                                                style={{ display: cashAccountError ? "block" : "none" }}>
                                                {cashAccountError}
                                            </span>

                                            <label>{cashAccountType}</label>

                                            {cashAccountType === 'BANK' &&
                                                <BankAccount
                                                    authProps={authProps}
                                                    setBankAccountId={setBankAccountId}
                                                    bankAccountId={bankAccountId} />}

                                            {cashAccountType === 'MOBILE' &&
                                                <MobileMoneyAccount
                                                    authProps={authProps}
                                                    setMobileMoneyAccountId={setMobileMoneyAccountId}
                                                    mobileMoneyAccountId={mobileMoneyAccountId} />}

                                            <SubsidiaryAccount
                                                authProps={authProps}
                                                setSubsidiaryAccountId={setSubsidiaryAccountId}
                                                subsidiaryAccountId={subsidiaryAccountId} />
                                            
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="flex items-center">
                                                    {editMode ? "Edit" : "Create"}
                                                </span>
                                            </button>
                                            </div>
                                            <div className="text-sm">
                                                <button
                                                    type="submit"
                                                    onClick={cancel}
                                                    className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AddEditCashAccount;