import React, { FC, SetStateAction } from 'react';
import validator from 'validator';

interface Props {
    setDate: React.Dispatch<SetStateAction<string>>;
    date: string;
    setDateError: React.Dispatch<SetStateAction<string>>;
    dateError: string;
    label: string;
    isInvalid: boolean;
    defaultFocus?: boolean;
}

const Date: FC<Props> = ({ setDate, date, setDateError, dateError, label,
    isInvalid, defaultFocus }) => {

    return (
        <div className="grid grid-cols-3 w-full">
            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="date">
                {label}
            </label>
            
            <div className="col-span-2">
                <input
                    id="date"
                    name="date"
                    type="date"
                    value={date}
                    maxLength={10}
                    disabled={isInvalid}
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="dd-mm-yyyy"
                    onChange={e => {
                        setDateError("");
                        if (validator.isDate(e.target.value)) {
                            setDate(e.target.value);
                        } else {
                            setDate("");
                        }
                    }}
                    autoFocus={defaultFocus}
                />
                <span
                    className="text-xs text-red-700"
                    style={{ display: dateError ? "block" : "none" }}>
                    {dateError}
                </span>
            </div>
        </div>
    );
}

export default Date;