import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { SubsidiaryAccount } from './subsidiaryAccountSlice';

export type GeneralLedgerTransaction = {
    id?: string;
    generalLedgerEntryId?: string;
    subsidiaryAccountId: string;
    subsidiaryAccount?: SubsidiaryAccount;
    amount: number;
    edit?: void;
    delete?: void;
    code?: string;
    name?: string;
    primaryCode?: string;
    primaryName?: string;
}

interface GeneralLedgerTransactionState {
    status: "loading" | "idle";
    error: any;
    list: GeneralLedgerTransaction[];
}

interface FetchErrors {
    message: string;
}

const initialState: GeneralLedgerTransactionState = {
    list: [],
    error: null,
    status: "idle",
}

export const generalLedgerTransactionSlice = createSlice({
    name: 'generalLedgerTransaction',
    initialState,
    reducers: {
        clearGeneralLedgerTransactionList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearGeneralLedgerTransactionError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
        addVirtualGeneralLedgerTransaction: (state, { payload }) => {
            if (!state.list.some( ({ id }) => id === payload.id )) {
                state.list.unshift(payload);
            }
            state.status = "idle";
        },
        editVirtualGeneralLedgerTransaction: (state, { payload }) => {
            const index: number = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
        },
        deleteVirtualGeneralLedgerTransaction: (state, { payload }) => {
            const index: number = state.list.findIndex( ({ id }) => id === payload.id );
            state.list = [...state.list.slice(0, index),
                ...state.list.slice(index + 1)];
        }
     },
});

export const selectGeneralLedgerTransactionStatus = (state: RootState) => state.generalLedgerTransactions.status;
export const selectGeneralLedgerTransactions = (state: RootState) => state.generalLedgerTransactions.list;
export const selectGeneralLedgerTransactionError = (state: RootState) => state.generalLedgerTransactions.error;
export const { clearGeneralLedgerTransactionList, clearGeneralLedgerTransactionError,
    addVirtualGeneralLedgerTransaction, editVirtualGeneralLedgerTransaction,
    deleteVirtualGeneralLedgerTransaction } = generalLedgerTransactionSlice.actions;
export default generalLedgerTransactionSlice.reducer;