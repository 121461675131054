import React, { FC, useEffect } from 'react';
import { fetchMemberIdentitiesById, MemberIdentityParams, selectMemberIdentities } from '../../../store/memberIdentitySlice';
import AuthProps from '../../common/AuthProps';
import { useAppSelector, useAppDispatch } from '../../common/hooks';

interface Props {
    authProps: AuthProps;
    memberId: string;
}

const Identities: FC<Props> = ({ authProps, memberId }) => {

    const dispatch = useAppDispatch();

    const identities = useAppSelector(selectMemberIdentities);

    useEffect(() => {
        const controller = new AbortController();
        const params: MemberIdentityParams = {
            authProps: authProps,
            memberId: memberId,
        }
        dispatch(fetchMemberIdentitiesById(params));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[memberId]);

    return (
        <div className="flex w-full md:w-1/4">
            <div className="text-xs flex items-center space-x-1 space-y-1">
                <div className="grid grid-cols-3 gap-1">
                    {identities.map(identity => {
                        return (
                            <React.Fragment>
                                <div className="font-bold">
                                    {`${identity.identityType}:`}
                                </div>
                                <div className="font-normal col-span-2">
                                    {identity.value}
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Identities;