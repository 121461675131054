import React, { FC, useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { BankBranch, fetchBankBranchesByBank, selectBankBranchError, 
    selectBankBranches, selectBankBranchStatus,
    clearError, deleteBankBranch, BankBranchParams, clearBankBranchList } from '../../store/bankBranchSlice';
import AuthProps from '../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../common/Table/WrappedTable';
import AddEditBankBranch from './AddEditBankBranch';
import { selectBanks, fetchBanks } from '../../store/bankSlice';
import Select from '../common/Select';

const BankBranchPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const banks = useAppSelector(selectBanks);
    const bankBranches = useAppSelector(selectBankBranches);
    const status = useAppSelector(selectBankBranchStatus);
    const bankBranchError = useAppSelector(selectBankBranchError);

    const [bankId, setBankId] = useState<string>("");
    const [bankBranch, setBankBranch] = useState<BankBranch>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(clearBankBranchList(null));
        dispatch(fetchBanks(authProps));
        return () => controller.abort();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setBankBranch(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, bankBranch: BankBranch) => {
    	e.preventDefault();
        dispatch(clearError(null));
    	setBankBranch(bankBranch);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, bankBranch: BankBranch) => {
		e.preventDefault();
        dispatch(clearError(null));
    	setBankBranch(bankBranch);
        console.log(`bankBranch ${JSON.stringify(bankBranch)}`);
    	const params: BankBranchParams = {
        	authProps: authProps,
        	bankBranch: bankBranch,
      	}
    	dispatch(deleteBankBranch(params))
 	};

    const columns: Column<BankBranch>[] = useMemo(() => [
        {
          Header: "Name",
          accessor: "name"
        },
        {
            Header: "Code",
            accessor: "code"
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div className="items-center margin: auto">
                        {authProps.auth.token.features?.map(a => a.id).includes('2ljsopeu09') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>}
                  </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('313qbh0g3d') &&
                            <button
                                onClick={e => inLineDelete(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </button>}
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[bankBranches]);

    return (
        <div className="flex flex-col w-auto">
            {
                <div className="flex relative p-1 w-auto">
                    <Select
                        id="bankId"
                        placeHolder="Select Bank"
                        className="shadow-sm border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        selectList={
                            banks.map(bank => {
                                return {id: bank.id, name: bank.name}
                            })}
                        required={true}
                        onChange={e => {
                            e.preventDefault();
                            setBankId(e.target.value);
                            const params: BankBranchParams = {
                                authProps: authProps,
                                bankId: e.target.value,
                            }
                            dispatch(fetchBankBranchesByBank(params));
                        }} />
                </div>
            }
            {status === "loading" ? (<div className="pl-2">Loading...</div>) : (
                <React.Fragment>
                    {bankBranchError &&
                    <span
                        className="text-red-500"
                        style={{ display: bankBranchError ? "block" : "none" }}>
                        {bankBranchError}
                    </span>}
                    <div>
                        {bankBranches &&
                            <React.Fragment>
                                <div className="hidden md:block">
                                    <WrappedTable<BankBranch>
                                        name="Branches"
                                        columns={columns}
                                        data={bankBranches}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('f3am8l6aaq')}
                                    /> 
                                </div>
                                <div className="md:hidden">
                                    <WrappedTable<BankBranch>
                                        name="Branches"
                                        columns={columns}
                                        data={bankBranches}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('f3am8l6aaq')}
                                    />
                                </div>
                            </React.Fragment>}
                    </div>
                    <div>
                        {showAddEdit &&
                            bankId &&
                                <AddEditBankBranch 
                                    authProps={authProps} 
                                    setShowAddEdit={setShowAddEdit}
                                    bank={banks.find( ({ id }) => id === bankId)}
                                    bankBranch={bankBranch}
                                />}
                    </div>
                </React.Fragment>)};
        </div>
    );
}

export default BankBranchPage;