import React, { FC, useEffect } from 'react';
import { fetchNextOfKinById, NextOfKinParams, selectNextOfKin } from '../../../store/nextOfKinSlice';
import AuthProps from '../../common/AuthProps';
import { useAppSelector, useAppDispatch } from '../../common/hooks';

interface Props {
    authProps: AuthProps;
    memberId: string;
}

const NextOfKin: FC<Props> = ({ authProps, memberId }) => {

    const dispatch = useAppDispatch();

    const nextOfKins = useAppSelector(selectNextOfKin);

    useEffect(() => {
        const controller = new AbortController();
        const params: NextOfKinParams = {
            authProps: authProps,
            memberId: memberId,
        }
        dispatch(fetchNextOfKinById(params));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[memberId]);

    return (
        <div className="flex w-full md:w-1/4">
            <div className="text-xs flex items-center space-x-1 space-y-1">
                <div className="grid grid-cols-3 gap-1">
                    {nextOfKins.map((nextOfKin, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="font-bold">
                                    {`Kin ${index +1}:`}
                                </div>
                                <div className="font-normal col-span-2">
                                    {`${nextOfKin.nextOfKin.firstName} ${nextOfKin.nextOfKin.middleName} ${nextOfKin.nextOfKin.surname} ${nextOfKin.relationship}
                                     DoB: ${nextOfKin.nextOfKin.dateOfBirth} Gender: ${nextOfKin.nextOfKin.gender.substring(0,1)}`}
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default NextOfKin;