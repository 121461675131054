import React, { FC } from 'react';

const LandingPage: FC = () => {

    return (
        <div className="w-full z-50 overflow-y-auto">
            <h1 className="text-center text-3xl font-base text-blue-900 p-2">
                Sasco Savings & Credit Cooperative Society Limited
            </h1>
        </div>
    );

}

export default LandingPage;