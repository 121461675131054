import React, { FC, useEffect, useState, useMemo } from 'react';
import AuthProps from '../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { SubsidiaryAccountParams, selectSubsidiaryAccountError, selectSubsidiaryAccounts, 
    selectSubsidiaryAccountStatus, SubsidiaryAccount, fetchSubsidiaryAccountsByPrimaryAccount,
    clearError, deleteSubsidiaryAccount, clearSubsidiaryAccounts } from '../../store/subsidiaryAccountSlice';
import { fetchAccounts, selectAccounts } from '../../store/accountSlice';
import { Column } from 'react-table';
import { WrappedTable } from '../common/Table/WrappedTable';
import IconButton from '../../shared/confirm/IconButton';
import AddEditSubsidiaryAccount from './AddEditSubsidiaryAccount';
import ConfirmDialog from '../../shared/confirm/Confirm';
import Select from '../common/Select';
import { selectPrimaryAccounts, PrimaryAccountParams,
    fetchPrimaryAccountsByAccount } from '../../store/primaryAccountSlice';

const SubsidiaryAccountPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const status = useAppSelector(selectSubsidiaryAccountStatus);
    const subsidiaryAccounts = useAppSelector(selectSubsidiaryAccounts);
    const subsidiaryAccountError = useAppSelector(selectSubsidiaryAccountError);
    const accounts = useAppSelector(selectAccounts);
    const primaryAccounts = useAppSelector(selectPrimaryAccounts);

    const [accountId, setAccountId] = useState<string>("");
    const [primaryAccountId, setPrimaryAccountId] = useState<string>("");
    const [subsidiaryAccount, setSubsidiaryAccount] = useState<SubsidiaryAccount>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

    const selectSelectPrimaryAccount = [{'id':'select','name':'Select Primary Account'}];

    useEffect(() => {
        const controller = new AbortController();
        if (authProps.auth.token.features?.map(a => a.id).includes('1pfs89uima')) {
            dispatch(fetchAccounts(authProps));
        }
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setSubsidiaryAccount(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, subsidiaryAccount: SubsidiaryAccount) => {
    	e.preventDefault();
        dispatch(clearError(null));
    	setSubsidiaryAccount(subsidiaryAccount);
    	setShowAddEdit(true);
  	};

      const columns: Column<SubsidiaryAccount>[] = useMemo(() => [
        {
            Header: "Code",
            accessor: "code"
        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Type",
            accessor: "subsidiaryAccountType"
        },
        {
          Header: () => (
              <div style={{ textAlign:"center" }}>
              </div>
          ),
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('v7iskukvln') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>}
                  </div>
              );
          }

      },
      {
        Header: () => (
            <div style={{ textAlign:"center" }}>
            </div>
        ),
            accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                         {authProps.auth.token.features?.map(a => a.id).includes('htqqfqj5de') &&
                            <IconButton aria-label="delete" 
                                onClick={() => {    
                                    setSubsidiaryAccount(row.original);
                                    setConfirmOpen(true);
                                }}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </IconButton>}
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[subsidiaryAccounts]);

    const columnsSm: Column<SubsidiaryAccount>[] = useMemo(() => [
        {
            Header: "Account",
            Cell: ({ row }) => {
                return (
                    <React.Fragment>
                        {`${row.original.code} ${row.original.name}`}
                    </React.Fragment>
                );
            },
        },
        {
            Header: () => (
                <div style={{ textAlign:"center" }}></div>
            ),
            accessor: "edit",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                         {authProps.auth.token.features?.map(a => a.id).includes('v7iskukvln') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>}
                    </div>
              );
          }

      },
      {
        Header: () => (
            <div style={{ textAlign:"center" }}>
            </div>
          ),
        accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('htqqfqj5de') &&
                            <IconButton aria-label="delete" 
                                onClick={() => {    
                                    setSubsidiaryAccount(row.original)
                                    setConfirmOpen(true);
                                }}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </IconButton>}
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[subsidiaryAccounts]);

    return (
        <div className="flex flex-col w-auto">
            <div className="flex items-center pt-1 pb-1">
                <div className="relative">
                    {authProps.auth.token.features?.map(a => a.id).includes('1pfs89uima') &&
                        <Select
                            id="accountId"
                            placeHolder="Select Account"
                            className="shadow-md border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            selectList={(
                                accounts.map(account => {
                                    return {id: account.id, name: `${account.code} ${account.name}`}
                                }))}
                            required={true}
                            onChange={e => {
                                e.preventDefault();
                                setPrimaryAccountId(null);
                                dispatch(clearSubsidiaryAccounts(null));
                                setAccountId(e.target.value);
                                const params: PrimaryAccountParams = {
                                    authProps: authProps,
                                    accountId: e.target.value
                                }
                                dispatch(fetchPrimaryAccountsByAccount(params));
                            }}
                            autoFocus />}
                </div>
                <div className="relative ml-1">
                    {authProps.auth.token.features?.map(a => a.id).includes('b58ua6asdg') &&
                        <Select
                            id="primaryAccountId"
                            disabled={accountId === ""}
                            placeHolder="Select Primary Account"
                            className="shadow-md border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            selectList={
                                selectSelectPrimaryAccount.map(select => {
                                    return {id: select.id, name: select.name}
                                }).concat(
                                    primaryAccounts.map(primaryAccount => {
                                        return {id: primaryAccount.id, name: `${primaryAccount.code} ${primaryAccount.name}`}
                                    }))}
                            required={true}
                            onChange={e => {
                                e.preventDefault();
                                if (e.target.value !== 'select') {
                                    setPrimaryAccountId(e.target.value);
                                    const params: SubsidiaryAccountParams = {
                                        authProps: authProps,
                                        primaryAccountId: e.target.value,
                                    }
                                    dispatch(fetchSubsidiaryAccountsByPrimaryAccount(params));
                                }
                            }} />}
                </div>
            </div>
            {status === "loading" ? (<div className="pl-2">Loading...</div>) : (
                <React.Fragment>
                    {subsidiaryAccountError &&
                    <span
                        className="text-red-500"
                        style={{ display: subsidiaryAccountError ? "block" : "none" }}>
                        {subsidiaryAccountError}
                    </span>}
                    <div>
                        {subsidiaryAccounts &&
                            <React.Fragment>
                                <div className="hidden md:block">
                                    <WrappedTable<SubsidiaryAccount>
                                        name="Subsidiary Accounts"
                                        columns={columns}
                                        data={subsidiaryAccounts}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('8i7kc21t9k')}
                                        customRowStyle='text-sm text-gray-900 font-normal px-2 py-0'
                                    /> 
                                </div>
                                <div className="md:hidden">
                                    <WrappedTable<SubsidiaryAccount>
                                        name="Subsidiary Accounts"
                                        columns={columnsSm}
                                        data={subsidiaryAccounts}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('8i7kc21t9k')}
                                        customRowStyle='text-sm text-gray-900 font-normal px-2 py-0'
                                    />
                                </div>
                            </React.Fragment>}
                    </div>
                    <div>
                        {showAddEdit
                            && primaryAccountId &&
                            <AddEditSubsidiaryAccount 
                                authProps={authProps} 
                                setShowAddEdit={setShowAddEdit}
                                subsidiaryAccount={subsidiaryAccount}
                                primaryAccount={primaryAccounts.find( ({ id }) => id === primaryAccountId)}
                            />}
                    </div>
                    <div className="text-sm">
                        <ConfirmDialog
                            title={`Are you sure you want to delete subsidiary account ${subsidiaryAccount?.name} ?`}
                            open={confirmOpen}
                            onClose={() => setConfirmOpen(false)}
                            onConfirm={() => {
                                    const params: SubsidiaryAccountParams = {
                                        authProps: authProps,
                                        subsidiaryAccount: subsidiaryAccount,
                                    };
                                    dispatch(deleteSubsidiaryAccount(params));
                            }}>
                        </ConfirmDialog>
                    </div>
                </React.Fragment>)}
        </div>
    );

};

export default SubsidiaryAccountPage;