import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { Member } from './memberSlice';

export type MemberRegistrationParams = {
    authProps: AuthProps;
    memberRegistrationStatus: MemberRegistrationStatus;
}

export type MemberRegistrationStatus = {
    userId: string;
    memberId: string;
    registrationStatus: string;
    modifiedDate?: string;
}

interface MemberRegistrationState {
    status: "loading" | "idle" | "editing";
    error: any;
    memberRegistrationStatus: MemberRegistrationStatus;
    list: Member[];
}

interface FetchErrors {
    message: string;
}

const initialState: MemberRegistrationState = {
    memberRegistrationStatus: null,
    list: [],
    error: null,
    status: "idle",
}

export const fetchMemberRegistrationStatus =
    createAsyncThunk<MemberRegistrationStatus, AuthProps, { rejectValue: FetchErrors }>(
    "registrationStatus/fetch",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.REGISTRATIONSTATUS_URL +
                "/" + params.auth.token.memberId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member registration status",
            });
        }
        return response.json();
    }
);

export const editMemberRegistration =
    createAsyncThunk<Member, MemberRegistrationParams, { rejectValue: FetchErrors }>(
    "registrationStatus/edit",
    async (params: MemberRegistrationParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.REGISTRATIONSTATUS_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberRegistrationStatus),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const memberRegistrationStatusSlice = createSlice({
    name: 'memberRegistrationStatus',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchMemberRegistrationStatus.pending, (state) => {
            state.memberRegistrationStatus = null;
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(editMemberRegistration.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchMemberRegistrationStatus.fulfilled, (state, { payload }) => {
            state.memberRegistrationStatus = null;
            state.memberRegistrationStatus = payload;
            state.status = "idle";
        });
        builder.addCase(editMemberRegistration.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchMemberRegistrationStatus.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editMemberRegistration.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectMemberRegistrationStatusStatus = (state: RootState) => state.memberRegistrationStatus.status;
export const selectMemberRegistrationStatus = (state: RootState) => state.memberRegistrationStatus.memberRegistrationStatus;
export const selectMemberRegistrationStatusError = (state: RootState) => state.memberRegistrationStatus.error;
export const { clearError } = memberRegistrationStatusSlice.actions;
export default memberRegistrationStatusSlice.reducer;
