import { MobileMoneyAccount } from './mobileMoneyAccountSlice';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { BankAccount } from './bankAccountSlice';
import { SubsidiaryAccount } from './subsidiaryAccountSlice';

export type CashAccountParams = {
    authProps: AuthProps;
    cashAccount?: CashAccount;
    cashAccountId?: string;
    cashAccountType?: string;
}

export type CashAccount = {
    id: string;
    cashAccountType: string;
    bankAccountId: string;
    bankAccount?: BankAccount;
    mobileMoneyAccountId: string;
    mobileMoneyAccount?: MobileMoneyAccount;
    subsidiaryAccountId: string;
    subsidiaryAccount?: SubsidiaryAccount;
    accountId: string;
    creationDate?: string;
    edit?: void;
    delete?: void;
}

interface CashAccountState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: CashAccount[];
}

interface FetchErrors {
    message: string;
}

const initialState: CashAccountState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchCashAccounts = 
    createAsyncThunk<CashAccount[], AuthProps, { rejectValue: FetchErrors }>(
    "cashAccounts/fetchByAccount",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.CASHACCOUNT_URL +
                "/" + params.config.ACCOUNT_ID, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch cash accounts",
            });
        }
        return response.json();
    }
);

export const fetchCashAccountsByType = 
    createAsyncThunk<CashAccount[], CashAccountParams, { rejectValue: FetchErrors }>(
    "cashAccounts/fetchByAccountAndType",
    async (params: CashAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.CASHACCOUNT_URL +
                "/type/" + params.authProps.config.ACCOUNT_ID +
                "/" + params.cashAccountType, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch cash accounts",
            });
        }
        return response.json();
    }
);

export const addCashAccount =
    createAsyncThunk<CashAccount, CashAccountParams, { rejectValue: FetchErrors }>(
    "cashAccount/add",
    async (params: CashAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.CASHACCOUNT_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.cashAccount),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editCashAccount =
    createAsyncThunk<CashAccount, CashAccountParams, { rejectValue: FetchErrors }>(
    "cashAccount/edit",
    async (params: CashAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.CASHACCOUNT_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.cashAccount),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteCashAccount =
    createAsyncThunk<CashAccount, CashAccountParams, { rejectValue: FetchErrors }>(
    "cashAccount/delete",
    async (params: CashAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.CASHACCOUNT_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.cashAccount),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const cashAccountSlice = createSlice({
    name: 'cashAccount',
    initialState,
    reducers: {
        clearCashAccountList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchCashAccounts.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(fetchCashAccountsByType.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addCashAccount.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editCashAccount.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteCashAccount.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchCashAccounts.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(fetchCashAccountsByType.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addCashAccount.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editCashAccount.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteCashAccount.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchCashAccounts.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(fetchCashAccountsByType.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addCashAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editCashAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteCashAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectCashAccountStatus = (state: RootState) => state.cashAccounts.status;
export const selectCashAccounts = (state: RootState) => state.cashAccounts.list;
export const selectCashAccountError = (state: RootState) => state.cashAccounts.error;
export const { clearCashAccountList, clearError } = cashAccountSlice.actions;
export default cashAccountSlice.reducer;