import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { Member } from './memberSlice';

export type CashbookTransactionMember = {
    cashbookTransactionId: string;
    memberId: string;
    member?: Member;
    cashbookPayeeType: string;
}

interface CashbookTransactionMemberState {
    status: "loading" | "idle";
    error: any;
    list: CashbookTransactionMember[];
}

interface FetchErrors {
    message: string;
}

const initialState: CashbookTransactionMemberState = {
    list: [],
    error: null,
    status: "idle",
}

export const cashbookTransactionMemberSlice = createSlice({
    name: 'cashbookTransactionMember',
    initialState,
    reducers: {
        clearCashbookTransactionMemberList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearCashbookTransactionMemberError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
        addVirtualCashbookTransactionMember: (state, { payload }) => {
            if (!state.list.some( ({ cashbookTransactionId, memberId }) => 
                cashbookTransactionId === payload.cashbookTransactionId
                && memberId === payload.memberId )) {
                state.list.unshift(payload);
            }
            state.status = "idle";
        },
        editVirtualCashbookTransactionMember: (state, { payload }) => {
            const index: number = state.list.findIndex( ({ cashbookTransactionId, memberId }) => 
                cashbookTransactionId === payload.cashbookTransactionId
                && memberId === payload.memberId);
            state.list[index] = payload;
        },
        deleteVirtualCashbookTransactionMember: (state, { payload }) => {
            const index: number = state.list.findIndex( ({ cashbookTransactionId, memberId }) => 
                cashbookTransactionId === payload.cashbookTransactionId
                && memberId === payload.memberId );
            state.list = [...state.list.slice(0, index),
                ...state.list.slice(index + 1)];
        }
     },
});

export const selectCashbookTransactionMemberStatus = (state: RootState) => state.cashbookTransactionMembers.status;
export const selectCashbookTransactionMembers = (state: RootState) => state.cashbookTransactionMembers.list;
export const selectCashbookTransactionMemberError = (state: RootState) => state.cashbookTransactionMembers.error;
export const { clearCashbookTransactionMemberList, clearCashbookTransactionMemberError,
    addVirtualCashbookTransactionMember, editVirtualCashbookTransactionMember,
    deleteVirtualCashbookTransactionMember } = cashbookTransactionMemberSlice.actions;
export default cashbookTransactionMemberSlice.reducer;