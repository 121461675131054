import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type TransactionCodeTypeParams = {
    authProps: AuthProps;
    transactionCodeType?: TransactionCodeType;
    book?: string;
}

export type TransactionCodeType = {
    id: string;
    name: string;
    book: string;
    accountId: string;
    creationDate?: string;
    edit?: void;
    delete?: void;
}

interface TransactionCodeTypeState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: TransactionCodeType[];
}

interface FetchErrors {
    message: string;
}

const initialState: TransactionCodeTypeState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchTransactionCodeTypes = 
    createAsyncThunk<TransactionCodeType[], TransactionCodeTypeParams, { rejectValue: FetchErrors }>(
    "transactionCodeTypes/fetchByAccountAndBook",
    async (params: TransactionCodeTypeParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.TRANSACTIONCODE_TYPE_URL +
                "/" + params.authProps.config.ACCOUNT_ID +
                "/" + params.book, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member account types",
            });
        }
        return response.json();
    }
);

export const addTransactionCodeType =
    createAsyncThunk<TransactionCodeType, TransactionCodeTypeParams, { rejectValue: FetchErrors }>(
    "transactionCodeType/add",
    async (params: TransactionCodeTypeParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.TRANSACTIONCODE_TYPE_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.transactionCodeType),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editTransactionCodeType =
    createAsyncThunk<TransactionCodeType, TransactionCodeTypeParams, { rejectValue: FetchErrors }>(
    "transactionCodeType/edit",
    async (params: TransactionCodeTypeParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.TRANSACTIONCODE_TYPE_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.transactionCodeType),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteTransactionCodeType =
    createAsyncThunk<TransactionCodeType, TransactionCodeTypeParams, { rejectValue: FetchErrors }>(
    "transactionCodeType/delete",
    async (params: TransactionCodeTypeParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.TRANSACTIONCODE_TYPE_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.transactionCodeType),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const transactionCodeTypeSlice = createSlice({
    name: 'transactionCodeType',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchTransactionCodeTypes.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addTransactionCodeType.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editTransactionCodeType.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteTransactionCodeType.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchTransactionCodeTypes.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addTransactionCodeType.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editTransactionCodeType.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteTransactionCodeType.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchTransactionCodeTypes.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addTransactionCodeType.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editTransactionCodeType.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteTransactionCodeType.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectTransactionCodeTypeStatus = (state: RootState) => state.transactionCodeTypes.status;
export const selectTransactionCodeTypes = (state: RootState) => state.transactionCodeTypes.list;
export const selectTransactionCodeTypeError = (state: RootState) => state.transactionCodeTypes.error;
export const { clearList, clearError } = transactionCodeTypeSlice.actions;
export default transactionCodeTypeSlice.reducer;