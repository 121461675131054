import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { Member } from './memberSlice';

export type MemberStatusParams = {
    authProps: AuthProps;
    memberStatus: MemberStatus;
}

export type MemberStatus = {
    memberId: string;
    memberStatus: string;
    modifiedDate?: string;
}

interface MemberState {
    status: "loading" | "idle" | "editing";
    error: any;
    memberStatus: MemberStatus;
    list: Member[];
}

interface FetchErrors {
    message: string;
}

const initialState: MemberState = {
    list: [],
    memberStatus: null,
    error: null,
    status: "idle",
}

export const fetchMemberStatus =
    createAsyncThunk<MemberStatus, AuthProps, { rejectValue: FetchErrors }>(
    "status/fetch",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.MEMBERSTATUS_URL +
                "/" + params.auth.token.memberId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member status",
            });
        }
        return response.json();
    }
);

export const editMemberStatus =
    createAsyncThunk<Member, MemberStatusParams, { rejectValue: FetchErrors }>(
    "memberStatus/edit",
    async (params: MemberStatusParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MEMBERSTATUS_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberStatus),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const memberStatusSlice = createSlice({
    name: 'memberStatus',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchMemberStatus.pending, (state) => {
            state.memberStatus = null;
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(editMemberStatus.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchMemberStatus.fulfilled, (state, { payload }) => {
            state.memberStatus = null;
            state.memberStatus = payload;
            state.status = "idle";
        });
        builder.addCase(editMemberStatus.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchMemberStatus.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editMemberStatus.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectMemberStatus = (state: RootState) => state.memberStatus.status;
export const selectMemberCurrentStatus = (state: RootState) => state.memberStatus.memberStatus;
export const selectMemberStatusError = (state: RootState) => state.memberStatus.error;
export const { clearError } = memberStatusSlice.actions;
export default memberStatusSlice.reducer;
