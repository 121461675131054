import React, { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { CashbookEntry, selectCashbookEntryError, selectCashbookEntries, 
    selectCashbookEntryStatus, clearCashbookEntryError, deleteCashbookEntry, 
    CashbookEntryParams, fetchCurrentSavedEntriesByMaker} 
    from '../../store/cashbookEntrySlice';
import { CashbookTransaction } from '../../store/cashbookTransactionSlice';
import AuthProps from '../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../common/Table/WrappedTable';
import AddEditCashbookEntry from './AddEditCashbookEntry';
import { ccyFormatFormal, grayedOutButtonClass } from '../../shared/Utils';
import CurrentPeriodDisplay from '../Transactions/CurrentPeriodDisplay';
import CashAccountType from '../common/Transactions/CashAccountType';

const CashbookEntries: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const cashbookEntries = useAppSelector(selectCashbookEntries);
    const status = useAppSelector(selectCashbookEntryStatus);
    const cashbookEntryError = useAppSelector(selectCashbookEntryError);

    const [cashbookEntry, setCashbookEntry] = useState<CashbookEntry>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        clearCashbookEntryError(null);
        dispatch(fetchCurrentSavedEntriesByMaker(authProps));
        return () => controller.abort();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearCashbookEntryError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setCashbookEntry(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, cashbookEntry: CashbookEntry) => {
    	e.preventDefault();
        dispatch(clearCashbookEntryError(null));
    	setCashbookEntry(cashbookEntry);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, cashbookEntry: CashbookEntry) => {
		e.preventDefault();
        dispatch(clearCashbookEntryError(null));
    	setCashbookEntry(cashbookEntry);
        console.log(`cashbookEntry ${JSON.stringify(cashbookEntry)}`);
    	const params: CashbookEntryParams = {
        	authProps: authProps,
        	cashbookEntry: cashbookEntry,
      	}
    	dispatch(deleteCashbookEntry(params));
 	};

    const transactionTotalAmount: any = (items: CashbookTransaction[]) => {
        return items.map( ({ amount }) => amount)
        .reduce((sum, i) => sum + i, 0) || 0;
    }

    const columns: Column<CashbookEntry>[] = useMemo(() => [
        {
            Header: () => null,
            accessor: "id",
            width: 3,
            Cell: ({ row }) => (
                row.original?.transactions &&
                    row.original.transactions.length > 0 ? (
                        <span
                            {...row.getToggleRowExpandedProps()}
                            className="flex flex-row">   
                            {row.isExpanded ? 
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                                </svg> : 
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                </svg>}
                        </span>) : (<div></div>)
            ),
        },
        {
            Header: "Date",
            accessor: "date"
        },
        {
            Header: "Cash Account",
            accessor: acct => { return (`${acct.cashAccount.cashAccountType}: ${acct.cashAccount.cashAccountType === 'BANK' ?
                `${acct.cashAccount.bankAccount.bankBranch.bank.name} ${acct.cashAccount.bankAccount.accountNumber}` :
                `${acct.cashAccount.mobileMoneyAccount.mobileMoneyProvider} ${acct.cashAccount.mobileMoneyAccount.phoneNumber}`}`) }
        },
        {
            Header: "Ref",
            accessor: "ref"
        },
        {
            Header: "[Memo]",
            accessor: "memo"
        },
        {
            Header: "Created",
            accessor: "creationDate"
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div className="items-center margin: auto">
                        {authProps.auth.token.features?.map(a => a.id).includes('zvpjwk876y') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>}
                  </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('cb3yf7kasu') &&
                            <button
                                onClick={e => inLineDelete(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </button>}
                  </div>
                );
            },
        },
        {
            Header: "",
            accessor: "status",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        <button
                            disabled
                            className={grayedOutButtonClass}
                            onClick={e => {

                            }}>
                            Submit
                        </button>
                    </div>
                  );
              },
          },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[cashbookEntries]);

    const transactionColumns: Column<CashbookTransaction>[] = useMemo(() => [
        {
            Header: "Date",
            accessor: "transactionDate"
        },
        {
            Header: "Code",
            accessor: code => { return (`${code.transactionCode.code} ${code.transactionCode.name}`) }
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Amount
                </div>
            ),
            accessor: "amount",
            Cell: ({ row }) => {
                return (
                    <div className="font-bold">
                        <div style={{ textAlign:"right" }}>
                            {ccyFormatFormal(Number(row.original.amount), 2)}
                        </div>
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    {ccyFormatFormal(transactionTotalAmount(columnProps.data), 2)}
                </div>
            ),
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[]);

    const subTable: any = useCallback(
        ({ row }) =>
            row.original.transactions.length > 0 ?
                (
                    <React.Fragment>
                        <div className="hidden md:block">
                            <WrappedTable<CashbookTransaction>
                                name=""
                                columns={transactionColumns}
                                data={row.original?.transactions}
                                addonHooks={[]}
                                includeFooter
                                dataClassName='text-xs text-gray-900 font-thin px-2 py-1'
                            />
                        </div>
                        <div className="md:hidden">
                            <WrappedTable<CashbookTransaction>
                                name=""
                                columns={transactionColumns}
                                data={row.original?.transactions}
                                addonHooks={[]}
                                includeFooter
                                dataClassName='text-xs text-gray-900 font-thin px-2 py-1'
                            />
                        </div>
                    </React.Fragment>
                ) : (<div></div>),
            [transactionColumns]
    );

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
            <React.Fragment>
                <CurrentPeriodDisplay />
                {cashbookEntryError &&
                <span
                    className="text-red-500"
                    style={{ display: cashbookEntryError ? "block" : "none" }}>
                    {cashbookEntryError}
                </span>}
                <React.Fragment>
                    {cashbookEntries &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<CashbookEntry>
                                    name="Cashbook Entries"
                                    columns={columns}
                                    data={cashbookEntries}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    renderRowSubComponent={subTable}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('gvm83heqx7')}
                                /> 
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<CashbookEntry>
                                    name="Cashbook Entries"
                                    columns={columns}
                                    data={cashbookEntries}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    renderRowSubComponent={subTable}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('gvm83heqx7')}
                                />
                            </div>
                        </React.Fragment>}
                </React.Fragment>
                <div>
                    {showAddEdit &&
                        <AddEditCashbookEntry 
                            authProps={authProps} 
                            setShowAddEdit={setShowAddEdit}
                            cashbookEntry={cashbookEntry}
                        />}
                </div>
            </React.Fragment>
    );
}

export default CashbookEntries;