import React, { FC, useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { ControlAccount, fetchControlAccounts, selectControlAccountError, selectControlAccounts, 
    selectControlAccountStatus, clearControlAccountError, deleteControlAccount, ControlAccountParams, clearControlAccountList } from '../../store/controlAccountSlice';
import AuthProps from '../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../common/Table/WrappedTable';
import AddEditControlAccount from './AddEditControlAccount';
import { selectTransactionCodeTypes, fetchTransactionCodeTypes,
    TransactionCodeTypeParams } from '../../store/transactionCodeTypeSlice';
import Select from '../common/Select';

interface Props {
    authProps: AuthProps;
    book: string;
}

const ControlAccountPage: FC<Props> = ({ authProps, book }) => {

    const dispatch = useAppDispatch();

    const controlAccounts = useAppSelector(selectControlAccounts);
    const status = useAppSelector(selectControlAccountStatus);
    const controlAccountError = useAppSelector(selectControlAccountError);

    const transactionCodeTypes = useAppSelector(selectTransactionCodeTypes);
    const preSelectTransactionCodeType = [{'id':'1111111111','name':'Select transaction code type'}];
    const [transactionCodeTypeId, setTransactionCodeTypeId] = useState<string>("");

    const [controlAccount, setControlAccount] = useState<ControlAccount>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(clearControlAccountList(null));
        const params: TransactionCodeTypeParams = {
            authProps: authProps,
            book: "MEMBER",
        }
        dispatch(fetchTransactionCodeTypes(params));
        return () => controller.abort();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearControlAccountError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setControlAccount(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, controlAccount: ControlAccount) => {
    	e.preventDefault();
        dispatch(clearControlAccountError(null));
    	setControlAccount(controlAccount);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, controlAccount: ControlAccount) => {
		e.preventDefault();
        dispatch(clearControlAccountError(null));
    	setControlAccount(controlAccount);
        console.log(`controlAccount ${JSON.stringify(controlAccount)}`);
    	const params: ControlAccountParams = {
        	authProps: authProps,
        	controlAccount: controlAccount,
      	}
    	dispatch(deleteControlAccount(params))
 	};

    const columns: Column<ControlAccount>[] = useMemo(() => [
        {
          Header: "Transaction Code",
          accessor: tranCode => { return(`${tranCode.transactionCode?.code} ${tranCode.transactionCode?.name}`) }
        },
        {
            Header: "Account",
            accessor: acct => { return( `${acct.subsidiaryAccount?.primaryAccount.code}-${acct.subsidiaryAccount?.code} ${acct.subsidiaryAccount?.name} (${acct.subsidiaryAccount?.primaryAccount.name})` ) }
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div className="items-center margin: auto">
                        {authProps.auth.token.features?.map(a => a.id).includes('kg62cxe8pj') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>}
                  </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('yv3wpb7r64') &&
                            <button
                                onClick={e => inLineDelete(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </button>}
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[controlAccounts]);

    return (
        <div className="flex flex-col w-auto">
            {
                <div className="flex relative p-1 w-auto">
                    <Select
                        id="bankId"
                        placeHolder="Select transaction code type"
                        className="shadow-sm border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        selectList={
                            preSelectTransactionCodeType.map(select => {
                                return {id: select.id, name: select.name}
                            }).concat(
                                transactionCodeTypes.map(transactionCodeType => {
                                    return { id: transactionCodeType.id, name: transactionCodeType.name }
                        }))}
                        required={true}
                        onChange={e => {
                            e.preventDefault();
                            setTransactionCodeTypeId(e.target.value);
                            const params: ControlAccountParams = {
                                authProps: authProps,
                                transactionCodeTypeId: e.target.value,
                            }
                            dispatch(fetchControlAccounts(params));
                        }} />
                </div>
            }
        {status === "loading" ? (<div className="pl-2">Loading...</div>) :
        (
            <React.Fragment>
                {controlAccountError &&
                <span
                    className="text-red-500"
                    style={{ display: controlAccountError ? "block" : "none" }}>
                    {controlAccountError}
                </span>}
                <div>
                    {controlAccounts &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<ControlAccount>
                                    name="Control Accounts"
                                    columns={columns}
                                    data={controlAccounts}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('zgws74xdtf')}
                                /> 
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<ControlAccount>
                                    name="Control Accounts"
                                    columns={columns}
                                    data={controlAccounts}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('zgws74xdtf')}
                                />
                            </div>
                        </React.Fragment>}
                </div>
                <div>
                    {showAddEdit &&
                        <AddEditControlAccount 
                            authProps={authProps} 
                            setShowAddEdit={setShowAddEdit}
                            controlAccount={controlAccount}
                            book={book}
                            transactionCodeTypeId={transactionCodeTypeId}
                        />}
                </div>
            </React.Fragment>)}
        </div>
    );
}

export default ControlAccountPage;