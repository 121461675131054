import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { BankBranch } from './bankBranchSlice';

export type MobileMoneyAccountParams = {
    authProps: AuthProps;
    mobileMoneyAccount?: MobileMoneyAccount;
    mobileMoneyAccountId?: string;
}

export type MobileMoneyAccount = {
    id: string;
    accountId: string;
    mobileMoneyProvider: string;
    areaCode: string;
    phoneNumber: string;
    creationDate?: string;
    edit?: void;
    delete?: void;
}

interface MobileMoneyAccountState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: MobileMoneyAccount[];
}

interface FetchErrors {
    message: string;
}

const initialState: MobileMoneyAccountState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchMobileMoneyAccounts = 
    createAsyncThunk<MobileMoneyAccount[], AuthProps, { rejectValue: FetchErrors }>(
    "mobileMoneyAccounts/fetchByAccount",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.MOBILEMONEYACCOUNT_URL +
                "/" + params.config.ACCOUNT_ID, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch mobile money accounts",
            });
        }
        return response.json();
    }
);

export const addMobileMoneyAccount =
    createAsyncThunk<MobileMoneyAccount, MobileMoneyAccountParams, { rejectValue: FetchErrors }>(
    "mobileMoneyAccount/add",
    async (params: MobileMoneyAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MOBILEMONEYACCOUNT_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.mobileMoneyAccount),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editMobileMoneyAccount =
    createAsyncThunk<MobileMoneyAccount, MobileMoneyAccountParams, { rejectValue: FetchErrors }>(
    "mobileMoneyAccount/edit",
    async (params: MobileMoneyAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MOBILEMONEYACCOUNT_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.mobileMoneyAccount),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteMobileMoneyAccount =
    createAsyncThunk<MobileMoneyAccount, MobileMoneyAccountParams, { rejectValue: FetchErrors }>(
    "mobileMoneyAccount/delete",
    async (params: MobileMoneyAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MOBILEMONEYACCOUNT_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.mobileMoneyAccount),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const mobileMoneyAccountSlice = createSlice({
    name: 'mobileMoneyAccount',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchMobileMoneyAccounts.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addMobileMoneyAccount.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editMobileMoneyAccount.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteMobileMoneyAccount.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchMobileMoneyAccounts.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addMobileMoneyAccount.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editMobileMoneyAccount.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteMobileMoneyAccount.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchMobileMoneyAccounts.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addMobileMoneyAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editMobileMoneyAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteMobileMoneyAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectMobileMoneyAccountStatus = (state: RootState) => state.mobileMoneyAccounts.status;
export const selectMobileMoneyAccounts = (state: RootState) => state.mobileMoneyAccounts.list;
export const selectMobileMoneyAccountError = (state: RootState) => state.mobileMoneyAccounts.error;
export const { clearList, clearError } = mobileMoneyAccountSlice.actions;
export default mobileMoneyAccountSlice.reducer;