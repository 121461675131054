import React, { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { MemberLoanEntry, selectMemberLoanEntryError, selectMemberLoanEntries, 
    selectMemberLoanEntryStatus, clearMemberLoanEntryError, deleteMemberLoanEntry, 
    MemberLoanEntryParams, 
    fetchCurrentSavedEntriesByMaker} from '../../../store/memberLoanEntrySlice';
import { MemberLoanTransaction } from '../../../store/memberLoanTransactionSlice';
import AuthProps from '../../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import AddEditMemberLoan from './AddEditMemberLoan';
import { ccyFormatFormal, grayedOutButtonClass } from '../../../shared/Utils';
import CurrentPeriodDisplay from '../CurrentPeriodDisplay';

const MemberLoanEntries: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const memberLoanEntries = useAppSelector(selectMemberLoanEntries);
    const status = useAppSelector(selectMemberLoanEntryStatus);
    const memberLoanEntryError = useAppSelector(selectMemberLoanEntryError);

    const [memberLoanEntry, setMemberLoanEntry] = useState<MemberLoanEntry>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        clearMemberLoanEntryError(null);
        dispatch(fetchCurrentSavedEntriesByMaker(authProps));
        return () => controller.abort();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearMemberLoanEntryError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setMemberLoanEntry(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, memberLoanEntry: MemberLoanEntry) => {
    	e.preventDefault();
        dispatch(clearMemberLoanEntryError(null));
    	setMemberLoanEntry(memberLoanEntry);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, memberLoanEntry: MemberLoanEntry) => {
		e.preventDefault();
        dispatch(clearMemberLoanEntryError(null));
    	setMemberLoanEntry(memberLoanEntry);
        console.log(`memberLoanEntry ${JSON.stringify(memberLoanEntry)}`);
    	const params: MemberLoanEntryParams = {
        	authProps: authProps,
        	memberLoanEntry: memberLoanEntry,
      	}
    	dispatch(deleteMemberLoanEntry(params));
 	};

    const transactionTotalAmount: any = (items: MemberLoanTransaction[]) => {
        return items.map( ({ amount }) => amount)
        .reduce((sum, i) => sum + i, 0) || 0;
    }

    const columns: Column<MemberLoanEntry>[] = useMemo(() => [
        {
            Header: () => null,
            accessor: "id",
            width: 3,
            Cell: ({ row }) => (
                row.original?.transactions &&
                    row.original.transactions.length > 0 ? (
                        <span
                            {...row.getToggleRowExpandedProps()}
                            className="flex flex-row">   
                            {row.isExpanded ? 
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                                </svg> : 
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                </svg>}
                        </span>) : (<div></div>)
            ),
        },
        {
          Header: "Date",
          accessor: "date"
        },
        {
            Header: "Ref",
            accessor: "ref"
        },
        {
            Header: "Transaction Code",
            accessor: tranCode => { return ( `${tranCode.transactionCode.code} ${tranCode.transactionCode.name}` ) }
        },
        {
            Header: "[Memo]",
            accessor: "memo"
        },
        {
            Header: "Created",
            accessor: "creationDate"
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div className="items-center margin: auto">
                        {authProps.auth.token.features?.map(a => a.id).includes('zvpjwk876y') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>}
                  </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('cb3yf7kasu') &&
                            <button
                                onClick={e => inLineDelete(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </button>}
                  </div>
                );
            },
        },
        {
            Header: "",
            accessor: "status",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        <button
                            disabled
                            className={grayedOutButtonClass}
                            onClick={e => {

                            }}>
                            Submit
                        </button>
                    </div>
                  );
              },
          },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[memberLoanEntries]);

    const transactionColumns: Column<MemberLoanTransaction>[] = useMemo(() => [
        {
            Header: "Member",
            accessor: mem => { return( `${mem.member.firstName} ${mem.member.middleName} ${mem.member.surname} (${mem.member.saccoId})` ) }
        },
        {
            Header: "Loan",
            accessor: loan => { return( `${loan.loan.ref}: ${loan.loan.name}`) }
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Amount
                </div>
            ),
            accessor: "amount",
            Cell: ({ row }) => {
                return (
                    <div className="font-bold">
                        <div style={{ textAlign:"right" }}>
                            {ccyFormatFormal(Number(row.original.amount), 2)}
                        </div>
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    {ccyFormatFormal(transactionTotalAmount(columnProps.data), 2)}
                </div>
            ),
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[]);

    const subTable: any = useCallback(
        ({ row }) =>
            row.original.transactions.length > 0 ?
                (
                    <React.Fragment>
                        <div className="hidden md:block">
                            <WrappedTable<MemberLoanTransaction>
                                name=""
                                columns={transactionColumns}
                                data={row.original?.transactions}
                                addonHooks={[]}
                                includeFooter
                                dataClassName='text-xs text-gray-900 font-thin px-2 py-1'
                            />
                        </div>
                        <div className="md:hidden">
                            <WrappedTable<MemberLoanTransaction>
                                name=""
                                columns={transactionColumns}
                                data={row.original?.transactions}
                                addonHooks={[]}
                                includeFooter
                                dataClassName='text-xs text-gray-900 font-thin px-2 py-1'
                            />
                        </div>
                    </React.Fragment>
                ) : (<div></div>),
            [transactionColumns]
    );

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
            <React.Fragment>
                {memberLoanEntryError &&
                <span
                    className="text-red-500"
                    style={{ display: memberLoanEntryError ? "block" : "none" }}>
                    {memberLoanEntryError}
                </span>}
                <React.Fragment>
                    {<CurrentPeriodDisplay />}
                    {memberLoanEntries &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<MemberLoanEntry>
                                    name="Member Loan Entries"
                                    columns={columns}
                                    data={memberLoanEntries}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    renderRowSubComponent={subTable}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('gvm83heqx7')}
                                /> 
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<MemberLoanEntry>
                                    name="Member Loan Entries"
                                    columns={columns}
                                    data={memberLoanEntries}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    renderRowSubComponent={subTable}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('gvm83heqx7')}
                                />
                            </div>
                        </React.Fragment>}
                </React.Fragment>
                <div>
                    {showAddEdit &&
                        <AddEditMemberLoan 
                            authProps={authProps} 
                            setShowAddEdit={setShowAddEdit}
                            memberLoanEntry={memberLoanEntry}
                        />}
                </div>
            </React.Fragment>
    );
}

export default MemberLoanEntries;