import React, { FC, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { Audit, fetchAuditByDates, selectAuditError, 
    selectAudit, selectAuditStatus,
     AuditParams } from '../../../store/auditSlice';
import AuthProps from '../../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';

const AuditPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");

    const auditRecords = useAppSelector(selectAudit);
    const status = useAppSelector(selectAuditStatus);
    const auditError = useAppSelector(selectAuditError);

    const columns: Column<Audit>[] = useMemo(() => [
        {
            Header: "Id",
            accessor: "id"
        },
        {
            Header: "Date",
            accessor: "date"
        },
        {
            Header: "User",
            accessor: "userName"
        },
        {
            Header: "App",
            accessor: "app"
        },
        {
            Header: "Role",
            accessor: "role"
        },
        {
            Header: "Feature",
            accessor: "feature"
        },
        {
            Header: "Process",
            accessor: "process"
        },
        {
            Header: "Status",
            accessor: "status"
        },
        {
            Header: "Method",
            accessor: "method"
        },
        {
            Header: "Request Id",
            accessor: "requestId"
        },
        {
            Header: "Outcome",
            accessor: "outcome"
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[auditRecords]);

    return (
        <div className="flex flex-col w-auto">
            {
                <div className="flex flex-row relative p-1 w-auto">
                    <div className="flex flex-column relative">
                        <React.Fragment>
                            <label className="block text-gray-700 text-sm font-normal w-auto p-1"
                                htmlFor="fromDate">
                                From Date:
                            </label>
                            <input
                                id="fromDate"
                                name="fromDate"
                                type="date"
                                maxLength={10}
                                required
                                className="appearance-none rounded-none relative block w-auto px-1 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="From date (dd-mm-yyyy)"
                                onChange={(e) => {
                                    e.preventDefault();
                                    setFromDate(e.target.value)
                                    if (toDate !== "") {
                                        const params: AuditParams = {
                                            authProps: authProps,
                                            fromDate: e.target.value,
                                            toDate: toDate,
                                        }
                                        dispatch(fetchAuditByDates(params));
                                    }
                                }}
                            />
                            <label className="block text-gray-700 text-sm font-normal w-auto p-1"
                                htmlFor="toDate">
                                To Date:
                            </label>
                            <input
                                id="toDate"
                                name="toDate"
                                type="date"
                                maxLength={10}
                                required
                                className="appearance-none rounded-none relative block w-auto px-1 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="To date"
                                onChange={(e) => {
                                    e.preventDefault();
                                    setToDate(e.target.value)
                                    if (fromDate !== "") {
                                        const params: AuditParams = {
                                            authProps: authProps,
                                            fromDate: fromDate,
                                            toDate: e.target.value,
                                        }
                                        dispatch(fetchAuditByDates(params));
                                    }
                                }}
                            />
                        </React.Fragment>
                    </div>
                </div>
            }
            {status === "loading" ? (<div className="pl-2">Loading...</div>) : (
                <React.Fragment>
                    {auditError &&
                    <span
                        className="text-red-500"
                        style={{ display: auditError ? "block" : "none" }}>
                        {auditError}
                    </span>}
                    <div>
                        {auditRecords &&
                            <React.Fragment>
                                <div className="hidden md:block">
                                    <WrappedTable<Audit>
                                        name="Audit"
                                        columns={columns}
                                        data={auditRecords}
                                        addonHooks={[]}
                                    /> 
                                </div>
                            </React.Fragment>}
                    </div>
                </React.Fragment>)};
        </div>
    );
}

export default AuditPage;