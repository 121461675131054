import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { ReportingPeriod } from './reportingPeriodSlice';

export type FinancialYearParams = {
    authProps: AuthProps;
    financialYear?: FinancialYear;
    financialYearId?: string;
}

export type FinancialYear = {
    id: string;
    reportingYear: number;
    yearEndDate: string;
    accountId: string;
    creationDate?: string;
    modifiedDate?: string;
    reportingPeriods?: ReportingPeriod[];
    edit?: void;
    delete?: void;
}

interface FinancialYearState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: FinancialYear[];
}

interface FetchErrors {
    message: string;
}

const initialState: FinancialYearState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchFinancialYears = 
    createAsyncThunk<FinancialYear[], AuthProps, { rejectValue: FetchErrors }>(
    "financialYears/fetchByAccount",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.FINANCIAL_YEAR_URL +
                "/list/" + params.config.ACCOUNT_ID, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch financial years",
            });
        }
        return response.json();
    }
);

export const addFinancialYear =
    createAsyncThunk<FinancialYear, FinancialYearParams, { rejectValue: FetchErrors }>(
    "financialYear/add",
    async (params: FinancialYearParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.FINANCIAL_YEAR_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.financialYear),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editFinancialYear =
    createAsyncThunk<FinancialYear, FinancialYearParams, { rejectValue: FetchErrors }>(
    "financialYear/edit",
    async (params: FinancialYearParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.FINANCIAL_YEAR_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.financialYear),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteFinancialYear =
    createAsyncThunk<FinancialYear, FinancialYearParams, { rejectValue: FetchErrors }>(
    "financialYear/delete",
    async (params: FinancialYearParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.FINANCIAL_YEAR_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.financialYear),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const financialYearSlice = createSlice({
    name: 'financialYear',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchFinancialYears.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addFinancialYear.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editFinancialYear.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteFinancialYear.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchFinancialYears.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addFinancialYear.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editFinancialYear.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteFinancialYear.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchFinancialYears.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addFinancialYear.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editFinancialYear.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteFinancialYear.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectFinancialYearStatus = (state: RootState) => state.financialYears.status;
export const selectFinancialYears = (state: RootState) => state.financialYears.list;
export const selectFinancialYearError = (state: RootState) => state.financialYears.error;
export const { clearList, clearError } = financialYearSlice.actions;
export default financialYearSlice.reducer;