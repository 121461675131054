import React, { FC, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Select from './common/Select';
import useToast from "../hooks/useToast";
import CryptoJS from 'crypto-js';

import { grayedOutButtonClass, enabledButtonClass, 
    reverse } from '../shared/Utils';
import PhoneAuthentication from './PhoneAuthentication';

interface SignUpProps {
    uri: string;
    auth: any;
    config: any;
  }

const SignUp: FC<SignUpProps> = ({ uri, auth, config }) => {

    const toast = useToast(7000);
    const navigate = useNavigate();

    const [name, setName] = useState<string>("");
    const [areaCode, setAreaCode] = useState<string>("+254");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const role: string = "basic";
    const [userPassword, setUserPassword] = useState<string>("");
    const date = new Date().getTime();
    const [confirmPassword, setConfirmPassword] = useState<string>("");

    const [errMsg, setErrMsg] = useState<string>("");

    const [showAuthToken, setShowAuthToken] = useState<boolean>(false);
    const [userAuthToken, setUserAuthToken] = useState<any>("");
    const [authMessage, setAuthMessage] = useState<string>("");

    const areaCodes = [{'id':'KE', 'name':'+254'}];
    const accountId = config.ACCOUNT_ID;

    const cancel = () => {
        const l = navigate.length;
        l > 2 ? navigate(-1) : navigate("/");
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrMsg("");

        const authToken = "";
        const name = "";

        if (userPassword !== confirmPassword) {
            setErrMsg("Password does not match with confirmation")
        }

        const password = (CryptoJS.AES.encrypt(
            userPassword,
            reverse(name.concat(date.toString())
                .replace(" ", "j"))).toString());

        const res = await auth.preSignUpUser({
            authToken,
            name,
            areaCode,
            phoneNumber,
            role,
            password,
            date,
            accountId,
        });
        if (res && res.success) {
            setAuthMessage(res.data.actionRequired);
            setShowAuthToken(true);
        } else {
            setErrMsg(res.error.message);
        }

    };

    const handleSubmitWithToken = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrMsg("");

        const password = (CryptoJS.AES.encrypt(
            userPassword,
            reverse(name.concat(date.toString())
                .replaceAll(" ", "j"))).toString());

        const authToken = (CryptoJS.AES.encrypt(
            userAuthToken,
            reverse(areaCode.concat(
                phoneNumber.concat(date.toString()))
                .replace("+", "x"))).toString());

        const res = await auth.signUpUser({
            authToken,
            name,
            areaCode,
            phoneNumber,
            role,
            password,
            date,
            accountId,
        });
        if (res && res.success) {
            navigate(uri ? uri : "/");
            toast('success', `Welcome to Sasco Sacco`)
        } else {
            setErrMsg(res.error.message);
        }

    };

    const isInvalid =
        name.trim() === ""
        || areaCode.trim() === ""
        || phoneNumber.trim() === ""
        || phoneNumber.length < 9
        || userPassword.trim() === "" || confirmPassword.trim() === ""
        || userPassword.trim().length < 5
        || userPassword !== confirmPassword;

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle max-w-lg w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                    <div className="flex items-center px-4 md:px-12 py-6">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                            
                            <h2 className="text-center text-3xl font-normal text-gray-900 p-2">
                                Sign up
                            </h2>

                            <span
                                className="text-red-500"
                                style={{ display: errMsg ? "block" : "none" }}>
                                {errMsg}
                            </span>

                            {showAuthToken ? (
                                <PhoneAuthentication 
                                    message={authMessage}
                                    userAuthToken={userAuthToken}
                                    setUserAuthToken={setUserAuthToken}
                                    handleSubmitWithToken={handleSubmitWithToken}
                                    cancel={cancel} />) :
                                (<form className="mt-2 space-y-3" onSubmit={handleSubmit}>
                                    <div className="rounded-md shadow-sm -space-y-px">
                                    <div>
                                            
                                            <label htmlFor="name" className="sr-only">
                                                Name
                                            </label>
                                            <input
                                                id="name"
                                                name="name"
                                                type="text"
                                                maxLength={30}
                                                autoComplete="name"
                                                required
                                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                placeholder="Name"
                                                onChange={(e) => setName(e.target.value)}
                                                autoFocus
                                            />
                                        </div>

                                        <div className="flex flex-row relative">
                                            <Select
                                                id="areaCode"
                                                placeHolder="+254"
                                                className="flex-grow appearance-none rounded-none border relative block py-2 px-3 w-auto text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                selectList={areaCodes.map(areaCode => {
                                                    return { id: areaCode.id, name: areaCode.name }})}
                                                required={true}
                                                onChange={(e) => 
                                                    setAreaCode(e.target.value)} />

                                            <label htmlFor="phoneNumber" className="sr-only">
                                                PhoneNumber
                                            </label>
                                            <input
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                maxLength={10}
                                                type="text"
                                                required
                                                className="flex-grow appearance-none rounded-none relative block px-3 py-2 border w-0 border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                placeholder="Phone Number"
                                                onChange={(e) => setPhoneNumber(e.target.value)} />
                                        </div>
                                        
                                        <div>
                                            <label htmlFor="userPassword" className="sr-only">
                                                Password
                                            </label>
                                            <input
                                                id="userPassword"
                                                name="userPassword"
                                                type="password"
                                                maxLength={30}
                                                autoComplete="current-userPassword"
                                                required
                                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                placeholder="Password"
                                                onChange={(e) => setUserPassword(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="confirmPassword" className="sr-only">
                                                Confirm Password
                                            </label>
                                            <input
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                type="password"
                                                maxLength={30}
                                                autoComplete="current-confirmPassword"
                                                required
                                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                placeholder="Confirm Password"
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between">
                                        <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                                    <svg
                                                        className="h-5 w-5 text-indigo-400 group-hover:text-indigo-300"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        aria-hidden="true">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                                        clipRule="evenodd"
                                                    />
                                                    </svg>
                                                </span>
                                                <span className="flex items-center pl-6">
                                                    Sign up
                                                </span>
                                            </button>
                                        </div>
                                        <div className="text-sm">
                                            <button
                                                type="submit"
                                                onClick={cancel}
                                                className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>

                                </form>)}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;