import React, { FC, useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import AuthProps from '../common/AuthProps';
import JournalEntries from './GeneralLedger/JournalEntries';
import FundsControlMain from './FundsControlAccounts/FundsControlMain';
import MemberLedgerMain from './MemberLedgerMain';
import { fetchCurrentPeriod, selectCurrentReportingPeriod } from '../../store/reportingPeriodSlice';

const TransactionMain: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const currentPeriod = useAppSelector(selectCurrentReportingPeriod);
    const [reportingPeriodId, setReportingPeriodId] = useState<string>("");

    const [openTab, setOpenTab] = useState(1);

    const openFunction = (process:string) => {
        switch (process) {
            case "Funds Control Accounts":
                return <FundsControlMain auth={authProps.auth} config={authProps.config} />
            case "Members Ledger":
                return <MemberLedgerMain auth={authProps.auth} config={authProps.config} />
            case "General Ledger Accounts":
                return <JournalEntries auth={authProps.auth} config={authProps.config} />
            default: return ""
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        if (authProps.auth.token.features?.map(a => a.id).includes('vxjr69bg54')) {
            reportingPeriod();
        }
        return () => controller.abort();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const reportingPeriod = async() => {
        await dispatch(fetchCurrentPeriod(authProps));
    }

    useEffect(() => {
        if (currentPeriod !== null) {
            setReportingPeriodId(currentPeriod.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentPeriod]);

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <ul
                        className="flex mb-2 list-none flex-wrap pt-1 pb-2 flex-row"
                        role="tablist">
                        {
                            authProps.auth.token.userMenu.find( ({ id }) => id === 'gsrvx643tn')
                                .subMenus?.map((subMenu:any, index:number) =>
                                <li key={index}
                                    className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                                    
                                    <a className={
                                        "text-xs font-bold uppercase px-5 py-2 shadow-sm rounded-xs block leading-normal " +
                                        (openTab === (index + 1)
                                            ? "text-grey-300 bg-[#FFDB89]"
                                            : "text-[#850000] bg-white")
                                        }
                                        onClick={e => {
                                            e.preventDefault();
                                            setOpenTab(index + 1);
                                        }}
                                        data-toggle="tab"
                                        href="#link"
                                        role="tablist">
                                        {subMenu.process}
                                    </a>

                                </li>
                            )
                        }
                    </ul>
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-sm">
                        <div className="px-0 py-0 flex-auto">
                            <div className="tab-content tab-space">
                                {
                                    authProps.auth.token.userMenu.find( ({ id }) => id === 'gsrvx643tn')
                                    .subMenus?.map((subMenu:any, index:number) =>

                                        <div key={index}>
                                            {openTab === (index + 1) &&
                                                <div className={openTab === (index + 1) ? "block" : "hidden"}>
                                                    {openFunction(subMenu.process)}
                                                </div>}
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TransactionMain;