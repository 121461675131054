import React, { FC, useState, SetStateAction, useMemo, useEffect } from 'react';
import { selectCashbookEntryError, CashbookEntry, CashbookEntryParams, addCashbookEntry,
    editCashbookEntry, clearCashbookEntryError } from '../../store/cashbookEntrySlice';
import AuthProps from '../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import useToast from '../../hooks/useToast';
import { grayedOutButtonClass, enabledButtonClass, getRandomId } from '../../shared/Utils';
import Date from '../common/Transactions/Date';
import Memo from '../common/Transactions/Memo';
import Amount from '../common/Transactions/Amount';
import { addVirtualCashbookTransaction, clearCashbookTransactionError,
     deleteVirtualCashbookTransaction, CashbookTransaction,
      selectCashbookTransactions, 
      clearCashbookTransactionList} from '../../store/cashbookTransactionSlice';
import { Column } from 'react-table';
import { WrappedTable } from '../common/Table/WrappedTable';
import { ccyFormat } from '../../shared/Utils';
import TransactionCode from '../common/Transactions/TransactionCode';
import CashAccount from '../common/Transactions/CashAccount';
import Name from '../common/Transactions/Name';
import { CashbookTransactionMember, selectCashbookTransactionMembers } from '../../store/cashbookTransactionMemberSlice';
import validator from 'validator';

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    cashbookEntry?: CashbookEntry | undefined;
}

const AddEditCashbookEntry: FC<Props> = ({ authProps, setShowAddEdit, cashbookEntry }) => {

    const toast = useToast();
    const dispatch = useAppDispatch();

    const [editMode] = useState<boolean>(!!cashbookEntry);
    const [date, setDate] = useState<string>(editMode ? cashbookEntry.date : "");
    const [dateError, setDateError] = useState<string>("");
    const [cashAccountId, setCashAccountId] = useState<string>(editMode ? cashbookEntry.cashAccountId : "")
    const [memo, setMemo] = useState<string>(editMode ? cashbookEntry.memo : "");

    const cashbookEntryError = useAppSelector(selectCashbookEntryError);

    const [transactionDate, setTransactionDate] = useState<string>("");
    const [transactionDateError, setTransactionDateError] = useState<string>("");

    const [transactionCodeId, setTransactionCodeId] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [typeName, setTypeName] = useState<string>("");

    const [ref, setRef] = useState<string>("");
    const [refError, setRefError] = useState<string>("");

    const [refDate, setRefDate] = useState<string>("");
    const [refDateError, setRefDateError] = useState<string>("");

    const [comments, setComments] = useState<string>("");
    const [details, setDetails] = useState<string>("");

    const [amount, setAmount] = useState<string>("");
    const [amountError, setAmountError] = useState<string>("");

    const cashbookTransactions = useAppSelector(selectCashbookTransactions);

    const [memberId, setMemberId] = useState<string>("");
    const [memberName, setMemberName] = useState<string>("");

    const [cashbookPayeeType, setCashbookPayeeType] = useState<string>("");

    const cashbookTransactionMembers = useAppSelector(selectCashbookTransactionMembers);

    useEffect(() => {
        dispatch(clearCashbookTransactionList(null));
    },[]);

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updatedCashbookEntry: CashbookEntry = {
            id: editMode ? cashbookEntry.id : "",
            date: date,
            cashAccountId: cashAccountId,
            memo: memo,
            makerId: authProps.auth.token.userId,
            accountId: authProps.config.ACCOUNT_ID,
            transactions: cashbookTransactions,
        }
        const params: CashbookEntryParams = {
            authProps: authProps,
            cashbookEntry: updatedCashbookEntry,
        }

        const result = await dispatch(editMode ? editCashbookEntry(params) : addCashbookEntry(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        console.log(`..entry: ${JSON.stringify(params.cashbookEntry)}`);

        if (!errorCondition) {
            toast('success', editMode ? `Cashbook entry edited successfully` : `Cashbook entry saved successfully`);
        }

        if (!errorCondition) {
            setShowAddEdit(false);
        }
    }

    const addNewCashbookTransaction = (e: React.MouseEvent<HTMLButtonElement>,
        transactionDate: string,
        transactionCodeId: string,
        code: string, 
        name: string,
        ref: string, 
        refDate: string,
        amount: string,
        comments: string,
        details: string) => {
        
        const newCashbookTransaction: CashbookTransaction = {
            id: getRandomId(10),
            transactionDate: transactionDate,
            transactionCodeId: transactionCodeId,
            code: code,
            name: name,
            ref: ref,
            refDate: refDate,
            amount: Number(amount),
            comments: comments,
            details: details,
            cashbookTransactionMembers: cashbookTransactionMembers,
        }
        dispatch(addVirtualCashbookTransaction(newCashbookTransaction));
        // clear fields
        setTransactionCodeId("1111111111");
        setAmount("");
    }

    const deleteSelectedCashbookTransaction = (e: React.MouseEvent<HTMLButtonElement>,
        cashbookTransaction: CashbookTransaction) => {
        e.preventDefault();
        dispatch(clearCashbookTransactionError(null));
        dispatch(deleteVirtualCashbookTransaction(cashbookTransaction));
    }

    const totalAmount: any = (items: CashbookTransaction[]) => {
        return items.map( ({ amount }) => Number(amount))
            .reduce((sum, i) => sum + i, 0) || 0;
    }

    const cashbookTransactionColumns: Column<CashbookTransaction>[] = useMemo(() => [
        {
            Header: "Date",
            accessor: "transactionDate"
        },
        {
            Header: "Code",
            accessor: code => { return (`${code.code} ${code.name}`) }
        },
        {
            Header: "Ref",
            accessor: "ref"
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Amount
                </div>
            ),
            accessor: "amount",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        <div className="font-bold">
                            {ccyFormat(Number(row.original.amount), 2)}
                        </div>
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    {ccyFormat(Number(totalAmount(columnProps.data)), 2)}
                </div>
            ),
        },
        {
            Header: "[Comments]",
            accessor: "comments"
        },
        {
            Header: "[Details]",
            accessor: "details"
        },
        {
            Header: "",
            accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        <button
                            onClick={e => deleteSelectedCashbookTransaction(e, row.original)}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[cashbookTransactions]);

    const isInvalid =
        date === ""
        || cashbookTransactions.length === 0
        || cashAccountId === ""
        || Number(totalAmount(cashbookTransactions)) <= 0;

    const isInvalidItem = 
        transactionDate === ""
        || transactionCodeId === ""
        || transactionCodeId === "1111111111";

    return (
        <div className="fixed z-50 inset-0 overflow-y-auto w-full">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">

                    <div className="flex items-center md:px-2 py-2">
                        <div className="relative sm:w-full pl-2 pr-2 pb-2 mx-auto bg-white rounded-md shadow-lg">
                            
                            <div className="w-full block inset bg-[#fffbe6] rounded-md border-2">
                                <h2 className="text-center text-2xl font-normal text-gray-900">
                                    {editMode ? `Edit: ${cashbookEntry.ref}` : `Create a new cashbook entry`}
                                </h2>
                            </div>

                            <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                <div className="rounded-md shadow-sm -space-y-px">

                                    <span
                                        className="text-red-500"
                                        style={{ display: cashbookEntryError ? "block" : "none" }}>
                                        {cashbookEntryError}
                                    </span>

                                    <Date
                                        setDate={setDate}
                                        date={date}
                                        setDateError={setDateError}
                                        dateError={dateError}
                                        label="Date"
                                        isInvalid={false}
                                        defaultFocus={true} />

                                    <hr className="h-px p-1 my-8 border-0" />

                                    <CashAccount
                                        authProps={authProps}
                                        setCashAccountId={setCashAccountId}
                                        cashAccountId={cashAccountId}
                                        isInvalid={date === "" || !validator.isDate(date)} />

                                    <Memo
                                        setMemo={setMemo}
                                        memo={memo}
                                        memoName={'[Memo]'}
                                        placeholder={'max 100 (optional)'}
                                        maxLength={100} />
                                    
                                </div>

                                <div className="block bg-slate-100 inset-1 p-2 mt-2 border-2">

                                    <Date
                                        setDate={setTransactionDate}
                                        date={transactionDate}
                                        setDateError={setTransactionDateError}
                                        dateError={transactionDateError}
                                        isInvalid={date === "" || !validator.isDate(date) || cashAccountId === ""}
                                        label="Transaction Date" />

                                    <TransactionCode
                                        authProps={authProps}
                                        setTransactionCodeId={setTransactionCodeId}
                                        transactionCodeId={transactionCodeId}
                                        book={'CASHBOOK'}
                                        setCode={setCode}
                                        setName={setName}
                                        setTypeName={setTypeName}
                                        isInvalid={transactionDate === "" || !validator.isDate(transactionDate)} />
                                    
                                    {transactionCodeId && typeName && typeName.toLowerCase().includes("out") &&
                                        <Name
                                            setName={setRef}
                                            name={ref}
                                            setNameError={setRefError}
                                            nameError={refError}
                                            maxLength={6} />
                                    }

                                    <Date
                                        setDate={setRefDate}
                                        date={refDate}
                                        setDateError={setRefDateError}
                                        dateError={dateError}
                                        isInvalid={transactionCodeId === ""}
                                        label="Receipt Date" />

                                    <Amount
                                        setAmount={setAmount}
                                        amount={amount}
                                        setAmountError={setAmountError}
                                        amountError={amountError}
                                        placeholderText="> 0"
                                        maxLengthValue={9} />

                                    <Memo
                                        setMemo={setComments}
                                        memo={comments}
                                        memoName={'[Comments]'}
                                        placeholder={'max 100 (optional)'}
                                        maxLength={100} />

                                    <Memo
                                        setMemo={setDetails}
                                        memo={details}
                                        memoName={'[Details]'}
                                        placeholder={'max 100 (optional)'}
                                        maxLength={512} />

                                    <div className="p-1 w-auto">
                                        <button
                                            disabled={isInvalidItem} 
                                            className={isInvalidItem ? grayedOutButtonClass : enabledButtonClass}
                                            onClick={(e) => {
                                                clearCashbookTransactionError(null);
                                                addNewCashbookTransaction(e, 
                                                    transactionDate,
                                                    transactionCodeId,
                                                    code, 
                                                    name,
                                                    ref, 
                                                    refDate,
                                                    amount,
                                                    comments,
                                                    details);
                                            }}>
                                            <span className="flex items-center">
                                                {memberId === "" ? "Add Transaction" : `Add`}
                                            </span>
                                        </button>
                                    </div>

                                    <div>
                                        {<WrappedTable<CashbookTransaction>
                                            name=""
                                            columns={cashbookTransactionColumns}
                                            data={cashbookTransactions}
                                            addonHooks={[]}
                                            includeFooter />}
                                    </div>

                                </div>

                                <div className="flex items-center justify-between">
                                    <div>
                                    <button
                                        type="submit"
                                        disabled={isInvalid}
                                        className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                        <span className="flex items-center">
                                            {editMode ? "Edit" : "Save"}
                                        </span>
                                    </button>
                                    </div>
                                    <div className="text-sm">
                                        <button
                                            type="submit"
                                            onClick={cancel}
                                            className="transition-colors hover:text-gray-900 font-medium duration-200">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                           
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AddEditCashbookEntry;