import React, { FC, useState, SetStateAction } from 'react';
import { selectContentBodyError, ContentBody, ContentBodyParams, addContentBody,
    editContentBody, clearError } from '../../../store/contentBodySlice';
import AuthProps from '../../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import useToast from '../../../hooks/useToast';
import { grayedOutButtonClass, enabledButtonClass } from '../../../shared/Utils';
import { ContentHeading } from '../../../store/contentHeadingSlice';

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    contentHeading: ContentHeading;
    contentBody?: ContentBody | undefined;
}

const AddEditContentBody: FC<Props> = ({ authProps, setShowAddEdit, 
    contentHeading, contentBody }) => {

    const toast = useToast();
    const dispatch = useAppDispatch();

    const [editMode] = useState<boolean>(!!contentBody);
    const [body, setBody] = useState<string>(editMode ? contentBody.body : "");
    const [priority, setPriority] = useState<any>(editMode ? contentBody.priority : "");
    const [showPublic, setShowPublic] = useState<boolean>(editMode ? contentBody.showPublic : false);
    const [showPrivate, setShowPrivate] = useState<boolean>(editMode ? contentBody.showPrivate : false);

    const contentBodyError = useAppSelector(selectContentBodyError);

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updatedContentBody: ContentBody = {
            id: editMode ? contentBody.id : "",
            contentHeadingId: contentHeading.id,
            body: body,
            priority: priority,
            showPublic: showPublic,
            showPrivate: showPrivate,
        }
        const params: ContentBodyParams = {
            authProps: authProps,
            contentBody: updatedContentBody,
        }

        const result = await dispatch(editMode ? editContentBody(params) : addContentBody(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            toast('success', editMode ? `Content body edited successfully` : `Content body added successfully`);

            // clear fields
            setBody("");
            setPriority("");
            setShowPublic(false);
            setShowPrivate(false);
        }

        if (editMode && !errorCondition) {
            setShowAddEdit(false);
        }
    }

    const isInvalid =
        body === ""
        || priority === ""
            || Number(priority) === 0;

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                    <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                                <div className="max-w-md w-full space-y-4">
                                    <h2 className="text-center text-2xl font-normal text-gray-900">
                                        {editMode ? `Edit: ${contentHeading.heading} - ${contentBody.priority}` : `Create a new Body for ${contentHeading.heading}`}
                                    </h2>

                                    <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                        <div className="rounded-md shadow-sm -space-y-px">

                                            <span
                                                className="text-red-500"
                                                style={{ display: contentBodyError ? "block" : "none" }}>
                                                {contentBodyError}
                                            </span>

                                            <label className="text-gray-700 text-sm font-bold" htmlFor="body">
                                                Body
                                            </label>
                                            <textarea
                                                id="body"
                                                name="body"
                                                value={body}
                                                maxLength={2048}
                                                required
                                                className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                placeholder="Body (max 2048)"
                                                onChange={(e) => {
                                                    dispatch(clearError(null));
                                                    setBody(e.target.value);
                                                }}
                                                autoFocus
                                            />

                                            <label className="text-gray-700 text-sm font-bold" htmlFor="priority">
                                                Priority
                                            </label>
                                            <input
                                                id="priority"
                                                name="priority"
                                                type="text"
                                                value={priority}
                                                maxLength={3}
                                                required
                                                className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                placeholder="Priority (1 - 999)"
                                                onChange={(e) => {
                                                    dispatch(clearError(null));
                                                    setPriority(e.target.value);
                                                }}
                                            />

                                            <div className="block">
                                                <div className="mt-2">
                                                    <label className="inline-flex items-center">
                                                        <input 
                                                            type="checkbox" 
                                                            checked={showPublic}
                                                            className="w-4 h-4 rounded"
                                                            onChange={(e) => setShowPublic(e.target.checked)} />
                                                            <span className="ml-2 text-sm font-normal">
                                                                Show Public
                                                            </span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="block">
                                                <div className="mt-2">
                                                    <label className="inline-flex items-center">
                                                        <input 
                                                            type="checkbox" 
                                                            checked={showPrivate}
                                                            className="w-4 h-4 rounded"
                                                            onChange={(e) => setShowPrivate(e.target.checked)} />
                                                            <span className="ml-2 text-sm font-normal">
                                                                Show Private
                                                            </span>
                                                    </label>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="flex items-center">
                                                    {editMode ? "Edit" : "Create"}
                                                </span>
                                            </button>
                                            </div>
                                            <div className="text-sm">
                                                <button
                                                    type="submit"
                                                    onClick={cancel}
                                                    className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AddEditContentBody;