import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type MemberParams = {
    authProps: AuthProps;
    member?: Member;
    memberId?: string;
}

export type Member = {
    id: string;
    saccoId: string;
    firstName: string;
    middleName?: string;
    surname: string;
    dateOfBirth: string;
    memberType: string;
    gender: string;
    maritalStatus: string;
    role: string;
    authenticated?: boolean;
    password?: string;
    creationDate: string;
    status?: string;
    registrationStatus?: string;
    edit?: void;
    delete?: void;
}

interface MemberState {
    status: "loading" | "idle" | "registering" | "editing" | "deleting";
    error: string | null;
    list: Member[];
    member: Member;
}

interface FetchErrors {
    message: string;
}

const initialState: MemberState = {
    list: [],
    member: null,
    error: null,
    status: "idle",
}

export const fetchAllMembers = 
    createAsyncThunk<Member[], AuthProps, { rejectValue: FetchErrors }>(
    "members/fetchByAccount",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.MEMBERS_URL, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization()
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch members",
            });
        }
        return response.json();
    }
);

export const fetchAllMembers2 = 
    createAsyncThunk<Member[], AuthProps, { rejectValue: FetchErrors }>(
    "members/fetchAll2",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.MEMBERS2_URL, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization()
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch members",
            });
        }
        return response.json();
    }
);

export const fetchMemberById = 
    createAsyncThunk<Member, MemberParams, { rejectValue: FetchErrors }>(
    "member/fetchById",
    async (params: MemberParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.MEMBERS_URL +
                "/otherinfo/personal/" + params.memberId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization()
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member",
            });
        }
        return response.json();
    }
);

export const editMember =
    createAsyncThunk<Member, MemberParams, { rejectValue: FetchErrors }>(
    "member/edit",
    async (params: MemberParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MEMBERS_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.member),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteMember =
    createAsyncThunk<Member, MemberParams, { rejectValue: FetchErrors }>(
    "member/delete",
    async (params: MemberParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MEMBERS_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.member),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteUnregisteredMember =
    createAsyncThunk<Member, MemberParams, { rejectValue: FetchErrors }>(
    "member/deleteUnregistered",
    async (params: MemberParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.DELETE_UNREGISTERED_MEMBER_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.member),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchAllMembers.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(fetchAllMembers2.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(fetchMemberById.pending, (state) => {
            state.member = null;
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(editMember.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteMember.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        builder.addCase(deleteUnregisteredMember.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchAllMembers.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(fetchAllMembers2.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(fetchMemberById.fulfilled, (state, { payload }) => {
            state.member = payload;
            state.status = "idle";
        });
        builder.addCase(editMember.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteMember.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        builder.addCase(deleteUnregisteredMember.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchAllMembers.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(fetchAllMembers2.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(fetchMemberById.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editMember.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteMember.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteUnregisteredMember.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectMemberStatus = (state: RootState) => state.members.status;
export const selectMembers = (state: RootState) => state.members.list;
export const selectMember = (state: RootState) => state.members.member;
export const selectMemberError = (state: RootState) => state.members.error;
export const { clearError } = memberSlice.actions;
export default memberSlice.reducer;