import spreadLogo from "../assets/logo192.png";

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
};

export const ccyFormat = (num: number, fraction: number) => {
    return num.toLocaleString('en-US', { minimumFractionDigits: fraction });
};

export const ccyFormatFormal = (num: number, fraction: number) => {
    const result = num.toLocaleString('en-US', { minimumFractionDigits: fraction });
    return result === '0.00' ? '-' : result;
};

export const ccyFormatPercent = (num: number, fraction: number) => {
  return num.toLocaleString('en-US', { minimumFractionDigits: fraction }).concat('%');
};

export function reverse(s: string){
  return s.split("").reverse().join("");
}

export const notifyUser = (message: string) => {
    const notifTitle = "Suppa Notification";
    const notifImage = spreadLogo;
    const options = {
      body: message,
      icon: notifImage,
    }
    new Notification(notifTitle, options);
}

// courtesy Will Vincent: https://wsvincent.com/javascript-remove-duplicates-array/
export function removeDups(names) {
  let unique = {};
  names.forEach(function(i) {
    if(!unique[i]) {
      unique[i] = true;
    }
  });
  return Object.keys(unique);
}

export const getRandomId = (length: number) => {
  var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var result = '';
  for ( var i = 0; i < length; i++ ) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return result.toLowerCase();
}

export const grayedOutButtonClass = "bg-gray-300 text-gray-500 m-1 focus:outline-none group relative w-full flex justify-center rounded-md font-medium text-sm px-4 py-2";
export const enabledButtonClass = "hover:bg-green-200 hover:text-green-700 bg-green-500 m-1 text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400 group relative w-full flex justify-center rounded-md font-medium text-sm px-4 py-2";

export const grayedOutWizardClass = "bg-gray-300 px-4 py-1 mt-1 text-sm font-medium leading-5 text-center text-white";
export const enabledWizardClass = "px-4 py-1 mt-1 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg hover:bg-blue-700  hover:underline focus:outline-none";