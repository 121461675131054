import { useToastDispatchContext } from '../shared/toast/ToastContext';

// courtesy Adam Richardson: https://www.adamrichardson.dev/blog/custom-tailwind-toast-component
export default function useToast(delay?:number) {
    const dispatch = useToastDispatchContext();

    function toast(type:any, message:any) {
        const id = Math.random().toString(36).substr(2, 9);
        dispatch({
            type: 'ADD_TOAST',
            toast: {
              type,
              message,
              id,
            },
        });

        setTimeout(() => {
            dispatch({ type: 'DELETE_TOAST', id });
        }, delay ? delay : 1000);
    }

    return toast;
}