import React, { FC, useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { TransactionCode, fetchTransactionCodesByBookAndType, selectTransactionCodeError, 
    selectTransactionCodes, selectTransactionCodeStatus, clearTransactionCodeError, 
    deleteTransactionCode, TransactionCodeParams, clearTransactionCodeList } from '../../../store/transactionCodeSlice';
import AuthProps from '../../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import AddEditTransactionCode from './AddEditTransactionCode';
import { fetchTransactionCodeTypes, TransactionCodeTypeParams } from '../../../store/transactionCodeTypeSlice';
import TransactionCodeType from '../../common/Transactions/TransactionCodeType';

interface Props {
    authProps: AuthProps;
    book: string;
}

const TransactionCodePage: FC<Props> = ({ authProps, book }) => {

    const dispatch = useAppDispatch();

    const transactionCodes = useAppSelector(selectTransactionCodes);
    const status = useAppSelector(selectTransactionCodeStatus);
    const transactionCodeError = useAppSelector(selectTransactionCodeError);

    const [transactionCodeTypeId, setTransactionCodeTypeId] = useState<string>("");

    const [transactionCode, setTransactionCode] = useState<TransactionCode>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(clearTransactionCodeList(null));
        const params: TransactionCodeTypeParams = {
            authProps: authProps,
            book: book,
        }
        dispatch(fetchTransactionCodeTypes(params));
        return () => controller.abort();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearTransactionCodeError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setTransactionCode(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, transactionCode: TransactionCode) => {
    	e.preventDefault();
        dispatch(clearTransactionCodeError(null));
    	setTransactionCode(transactionCode);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, transactionCode: TransactionCode) => {
		e.preventDefault();
        dispatch(clearTransactionCodeError(null));
    	setTransactionCode(transactionCode);
        console.log(`transactionCode ${JSON.stringify(transactionCode)}`);
    	const params: TransactionCodeParams = {
        	authProps: authProps,
        	transactionCode: transactionCode,
      	}
    	dispatch(deleteTransactionCode(params))
 	};

    const columns: Column<TransactionCode>[] = useMemo(() => [
        {
          Header: "Name",
          accessor: "name"
        },
        {
            Header: "Code",
            accessor: "code"
        },
        {
            Header: "Type",
            accessor: type => { return (`${type.transactionCodeType.name}`) }
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div className="items-center margin: auto">
                        {authProps.auth.token.features?.map(a => a.id).includes('r3w87mftb9') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>}
                  </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('qceh5dw8p7') &&
                            <button
                                onClick={e => inLineDelete(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </button>}
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[transactionCodes]);

    return (
        <div className="flex flex-col w-auto">
            <div className="flex relative p-1 w-auto md:w-1/3">
                <TransactionCodeType
                    authProps={authProps}
                    book={book}
                    setTransactionCodeTypeId={setTransactionCodeTypeId}
                    transactionCodeTypeId={transactionCodeTypeId}
                    loadTransactionCodes={true} />
            </div>
        {status === "loading" ? (<div className="pl-2">Loading...</div>) :
        (
            <React.Fragment>
                {transactionCodeError &&
                <span
                    className="text-red-500"
                    style={{ display: transactionCodeError ? "block" : "none" }}>
                    {transactionCodeError}
                </span>}
                <div>
                    {transactionCodes &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<TransactionCode>
                                    name="Codes"
                                    columns={columns}
                                    data={transactionCodes}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('b6g9y4kufw')}
                                /> 
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<TransactionCode>
                                    name="Codes"
                                    columns={columns}
                                    data={transactionCodes}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('b6g9y4kufw')}
                                />
                            </div>
                        </React.Fragment>}
                </div>
                <div>
                    {showAddEdit &&
                        <AddEditTransactionCode 
                            authProps={authProps} 
                            setShowAddEdit={setShowAddEdit}
                            transactionCode={transactionCode}
                            book={book}
                            setTransactionCodeTypeId={setTransactionCodeTypeId}
                            transactionCodeTypeId={transactionCodeTypeId}
                        />}
                </div>
            </React.Fragment>)}
        </div>
    );
}

export default TransactionCodePage;