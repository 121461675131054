import React, { FC, useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { MemberPeerGroup, fetchMemberPeerGroups, selectMemberPeerGroupError, selectMemberPeerGroups, selectMemberPeerGroupStatus,
    clearError, deleteMemberPeerGroup, MemberPeerGroupParams } from '../../../store/memberPeerGroupSlice';
import AuthProps from '../../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import AddEditMemberPeerGroup from './AddMemberPeerGroup';

interface Props {
    authProps: AuthProps;
    memberId: string;
}

const MemberPeerGroupPage: FC<Props> = ({ authProps, memberId }) => {

    const dispatch = useAppDispatch();

    const memberPeerGroups = useAppSelector(selectMemberPeerGroups);
    const status = useAppSelector(selectMemberPeerGroupStatus);
    const memberPeerGroupError = useAppSelector(selectMemberPeerGroupError);

    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        const params: MemberPeerGroupParams = {
            authProps: authProps,
            memberId: memberId,
        }
        dispatch(fetchMemberPeerGroups(params));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[memberId]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
    };

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, memberPeerGroup: MemberPeerGroup) => {
		e.preventDefault();
        dispatch(clearError(null));
    	const params: MemberPeerGroupParams = {
        	authProps: authProps,
        	memberPeerGroup: memberPeerGroup,
      	}
    	dispatch(deleteMemberPeerGroup(params))
 	};

    const columns: Column<MemberPeerGroup>[] = useMemo(() => [
        {
          Header: "Peer Group",
          accessor: "peerGroup"
        },
        {
            Header: "",
            accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('lt7fhhb3h8') &&
                        <button
                            onClick={e => inLineDelete(e, row.original)}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                            </svg>
                        </button>}
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[memberPeerGroups,authProps]);

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
            <React.Fragment>
                {memberPeerGroupError &&
                <span
                    className="text-red-500"
                    style={{ display: memberPeerGroupError ? "block" : "none" }}>
                    {memberPeerGroupError}
                </span>}
                <div>
                    {memberPeerGroups &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<MemberPeerGroup>
                                    name="Peer Groups"
                                    columns={columns}
                                    data={memberPeerGroups}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('cshk637vg6')}
                                /> 
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<MemberPeerGroup>
                                    name="Peer Groups"
                                    columns={columns}
                                    data={memberPeerGroups}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('cshk637vg6')}
                                />
                            </div>
                        </React.Fragment>}
                </div>
                <div>
                    {showAddEdit &&
                        memberId &&
                        <AddEditMemberPeerGroup 
                            authProps={authProps} 
                            setShowAddEdit={setShowAddEdit}
                            memberId={memberId}
                        />}
                </div>
            </React.Fragment>
    );
}

export default MemberPeerGroupPage;