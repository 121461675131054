import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

export type EmailRecipient = {
    emailMessageId: string;
    userId: string;
    emailRecipientType: string;
    emailAddress: string;
}

interface EmailRecipientState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: EmailRecipient[];
}

const initialState: EmailRecipientState = {
    list: [],
    error: null,
    status: "idle",
}

export const emailRecipientSlice = createSlice({
    name: 'emailRecipient',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        addVirtualRecipient: (state, { payload }) => {
            state.list.unshift(payload);
            state.status = "idle";
        },
        deleteVirtualRecipient: (state, { payload }) => {
            var index = state.list.findIndex( ({ emailMessageId, userId }) => emailMessageId === payload.emailMessageId
                && userId === payload.userId);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        },
     },
});

export const selectEmailRecipientStatus = (state: RootState) => state.emailRecipients.status;
export const selectEmailRecipients = (state: RootState) => state.emailRecipients.list;
export const { clearList, addVirtualRecipient, deleteVirtualRecipient } = emailRecipientSlice.actions;
export default emailRecipientSlice.reducer;