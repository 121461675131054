import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { FinancialYear } from './financialYearSlice';

export type ReportingPeriodParams = {
    authProps: AuthProps;
    reportingPeriod?: ReportingPeriod;
    financialYearId?: string;
}

export type ReportingPeriod = {
    id: string;
    financialYearId: string;
    financialYear?: FinancialYear;
    reportingPeriod: number;
    reportingPeriodMonth?: string;
    currentPeriod: number;
    accountId: string;
    creationDate?: string;
    edit?: void;
    delete?: void;
}

interface ReportingPeriodState {
    status: "loading" | "idle" | "adding" | "editing";
    error: any;
    list: ReportingPeriod[];
    reportingPeriod: ReportingPeriod;
}

interface FetchErrors {
    message: string;
}

const initialState: ReportingPeriodState = {
    list: [],
    error: null,
    status: "idle",
    reportingPeriod: null,
}

export const fetchReportingPeriods = 
    createAsyncThunk<ReportingPeriod[], ReportingPeriodParams, { rejectValue: FetchErrors }>(
    "reportingPeriods/fetchByFinancialYear",
    async (params: ReportingPeriodParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.FINANCIAL_YEAR_URL +
                "/period/" + params.financialYearId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch reporting periods",
            });
        }
        return response.json();
    }
);

export const fetchCurrentPeriod = 
    createAsyncThunk<ReportingPeriod, AuthProps, { rejectValue: FetchErrors }>(
    "reportingPeriod/current",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.CURRENT_PERIOD_URL, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch current reporting period",
            });
        }
        return response.json();
    }
);

export const addReportingPeriod =
    createAsyncThunk<ReportingPeriod, ReportingPeriodParams, { rejectValue: FetchErrors }>(
    "reportingPeriod/add",
    async (params: ReportingPeriodParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.FINANCIAL_YEAR_URL +
                "/period", {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.reportingPeriod),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editReportingPeriod =
    createAsyncThunk<ReportingPeriod, ReportingPeriodParams, { rejectValue: FetchErrors }>(
    "reportingPeriod/edit",
    async (params: ReportingPeriodParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.FINANCIAL_YEAR_URL +
                "/period", {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.reportingPeriod),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const reportingPeriodSlice = createSlice({
    name: 'reportingPeriod',
    initialState,
    reducers: {
        clearReportingPeriodList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearReportingPeriodError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchReportingPeriods.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(fetchCurrentPeriod.pending, (state) => {
            state.reportingPeriod = null;
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addReportingPeriod.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editReportingPeriod.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchReportingPeriods.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(fetchCurrentPeriod.fulfilled, (state, { payload }) => {
            state.reportingPeriod = payload;
            state.status = "idle";
        });
        builder.addCase(addReportingPeriod.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editReportingPeriod.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchReportingPeriods.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addReportingPeriod.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editReportingPeriod.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectReportingPeriodStatus = (state: RootState) => state.reportingPeriods.status;
export const selectReportingPeriods = (state: RootState) => state.reportingPeriods.list;
export const selectReportingPeriodError = (state: RootState) => state.reportingPeriods.error;
export const selectCurrentReportingPeriod = (state: RootState) => state.reportingPeriods.reportingPeriod;
export const { clearReportingPeriodList, clearReportingPeriodError } = reportingPeriodSlice.actions;
export default reportingPeriodSlice.reducer;