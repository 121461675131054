import React, { FC, SetStateAction } from 'react';
import validator from 'validator';

interface Props {
    setName: React.Dispatch<SetStateAction<string>>;
    name: string;
    setNameError: React.Dispatch<SetStateAction<string>>;
    nameError: string;
    maxLength: number;
}

const Name: FC<Props> = ({ setName, name, setNameError, nameError, maxLength }) => {

    return (
        <div className="grid grid-cols-3 w-full">

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="name">
                Name
            </label>
            
            <div className="col-span-2">
                <input
                    id="name"
                    name="name"
                    type="text"
                    value={name}
                    maxLength={maxLength}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder={"name (".concat(`max ${maxLength})`)}
                    onChange={e => {
                        setNameError("");
                        setName(e.target.value);
                    }}
                />
                <span
                    className="text-xs text-red-700"
                    style={{ display: nameError ? "block" : "none" }}>
                    {nameError}
                </span>
            </div>
        </div>
    );

}

export default Name;