import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { Bank } from './bankSlice';

export type BankBranchParams = {
    authProps: AuthProps;
    bankBranch?: BankBranch;
    bankId?: string;
}

export type BankBranch = {
    id: string;
    bankId: string;
    bank?: Bank;
    name: string;
    code: string;
    edit?: void;
    delete?: void;
}

interface BankBranchState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: BankBranch[];
}

interface FetchErrors {
    message: string;
}

const initialState: BankBranchState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchBankBranchesByBank = 
    createAsyncThunk<BankBranch[], BankBranchParams, { rejectValue: FetchErrors }>(
    "bankBranches/fetchByBank",
    async (params: BankBranchParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.BANKBRANCHES_URL +
                "/" + params.bankId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch bank branches",
            });
        }
        return response.json();
    }
);

export const addBankBranch =
    createAsyncThunk<BankBranch, BankBranchParams, { rejectValue: FetchErrors }>(
    "bankBranch/add",
    async (params: BankBranchParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.BANKBRANCH_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.bankBranch),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editBankBranch =
    createAsyncThunk<BankBranch, BankBranchParams, { rejectValue: FetchErrors }>(
    "bankBranch/edit",
    async (params: BankBranchParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.BANKBRANCH_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.bankBranch),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteBankBranch =
    createAsyncThunk<BankBranch, BankBranchParams, { rejectValue: FetchErrors }>(
    "bankBranch/delete",
    async (params: BankBranchParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.BANKBRANCH_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.bankBranch),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const bankBranchSlice = createSlice({
    name: 'bankBranch',
    initialState,
    reducers: {
        clearBankBranchList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchBankBranchesByBank.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addBankBranch.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editBankBranch.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteBankBranch.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchBankBranchesByBank.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addBankBranch.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editBankBranch.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteBankBranch.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchBankBranchesByBank.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addBankBranch.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editBankBranch.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteBankBranch.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectBankBranchStatus = (state: RootState) => state.bankBranches.status;
export const selectBankBranches = (state: RootState) => state.bankBranches.list;
export const selectBankBranchError = (state: RootState) => state.bankBranches.error;
export const { clearBankBranchList, clearError } = bankBranchSlice.actions;
export default bankBranchSlice.reducer;