import React, { FC, SetStateAction } from 'react';
import validator from 'validator';

interface Props {
    setMemo: React.Dispatch<SetStateAction<string>>;
    memo: string;
    setMemoError?: React.Dispatch<SetStateAction<string>>;
    memoError?: string;
    memoName: string;
    placeholder: string;
    maxLength: number;
}

const Memo: FC<Props> = ({ setMemo, memo, setMemoError, memoError, memoName,
    placeholder, maxLength }) => {

    return (
        <div className="grid grid-cols-3 w-full">

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="memo">
                {memoName}
            </label>
            
            <div className="col-span-2">
                <textarea
                    id="memo"
                    name="memo"
                    value={memo}
                    maxLength={maxLength}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder={placeholder}
                    onChange={e => {
                        setMemoError("");
                        if (validator.isAlphanumeric(e.target.value)) {
                            setMemo(e.target.value);
                        } else {
                            setMemo("");
                        }
                    }}
                />
                <span
                    className="text-xs text-red-700"
                    style={{ display: memoError ? "block" : "none" }}>
                    {memoError}
                </span>
            </div>
        </div>
    );

}

export default Memo;