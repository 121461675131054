import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { TransactionCodeType } from './transactionCodeTypeSlice';

export type TransactionCodeParams = {
    authProps: AuthProps;
    transactionCode?: TransactionCode;
    book?: string;
    transactionCodeTypeId?: string;
}

export type TransactionCode = {
    id: string;
    code: string;
    name: string;
    transactionCodeTypeId: string;
    transactionCodeType?: TransactionCodeType;
    accountId: string;
    creationDate?: string;
    edit?: void;
    delete?: void;
}

interface TransactionCodeState {
    status: "loading" | "adding" | "editing" | "deleting" | "idle";
    error: any;
    list: TransactionCode[];
}

interface FetchErrors {
    message: string;
}

const initialState: TransactionCodeState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchTransactionCodesByBook = 
    createAsyncThunk<TransactionCode[], TransactionCodeParams, { rejectValue: FetchErrors }>(
    "transactionCodes/fetchByBook",
    async (params: TransactionCodeParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.TRANSACTIONCODE_URL +
                "/" + params.book, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch transaction codes",
            });
        }
        return response.json();
    }
);

export const fetchTransactionCodesByBookAndType = 
    createAsyncThunk<TransactionCode[], TransactionCodeParams, { rejectValue: FetchErrors }>(
    "transactionCodes/fetchByBookAndType",
    async (params: TransactionCodeParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.TRANSACTIONCODE_URL +
                "/" + params.book + 
                "/" + params.transactionCodeTypeId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch transaction codes",
            });
        }
        return response.json();
    }
);

export const addTransactionCode =
    createAsyncThunk<TransactionCode, TransactionCodeParams, { rejectValue: FetchErrors }>(
    "transactionCode/add",
    async (params: TransactionCodeParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.TRANSACTIONCODE_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.transactionCode),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editTransactionCode =
    createAsyncThunk<TransactionCode, TransactionCodeParams, { rejectValue: FetchErrors }>(
    "transactionCode/edit",
    async (params: TransactionCodeParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.TRANSACTIONCODE_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.transactionCode),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteTransactionCode =
    createAsyncThunk<TransactionCode, TransactionCodeParams, { rejectValue: FetchErrors }>(
    "transactionCode/delete",
    async (params: TransactionCodeParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.TRANSACTIONCODE_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.transactionCode),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const transactionCodeSlice = createSlice({
    name: 'transactionCode',
    initialState,
    reducers: {
        clearTransactionCodeList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearTransactionCodeError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchTransactionCodesByBook.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(fetchTransactionCodesByBookAndType.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addTransactionCode.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editTransactionCode.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteTransactionCode.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchTransactionCodesByBook.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(fetchTransactionCodesByBookAndType.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addTransactionCode.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editTransactionCode.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteTransactionCode.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchTransactionCodesByBook.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(fetchTransactionCodesByBookAndType.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addTransactionCode.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editTransactionCode.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteTransactionCode.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectTransactionCodeStatus = (state: RootState) => state.transactionCodes.status;
export const selectTransactionCodes = (state: RootState) => state.transactionCodes.list;
export const selectTransactionCodeError = (state: RootState) => state.transactionCodes.error;
export const { clearTransactionCodeList, clearTransactionCodeError } = transactionCodeSlice.actions;
export default transactionCodeSlice.reducer;