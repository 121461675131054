import React, { FC, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { RoleFeature, fetchRoleFeatures, selectRoleFeatureError, 
    selectRoleFeatures, selectRoleFeatureStatus,
     RoleFeatureParams } from '../../../store/roleFeatureSlice';
import AuthProps from '../../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import { selectRoles, fetchRoles } from '../../../store/roleSlice';
import Select from '../../common/Select';

const RoleFeaturePage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const roles = useAppSelector(selectRoles);
    const roleFeatures = useAppSelector(selectRoleFeatures);
    const status = useAppSelector(selectRoleFeatureStatus);
    const roleFeatureError = useAppSelector(selectRoleFeatureError);

    useEffect(() => {
        const controller = new AbortController();
        if (authProps.auth.token.features?.map(a => a.id).includes('45kwmn7ujx')) {
            dispatch(fetchRoles(authProps));
        }
        return () => controller.abort();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const columns: Column<RoleFeature>[] = useMemo(() => [
        {
            Header: "Feature",
            accessor: "feature"
        },
        {
            Header: "Process",
            accessor: "process"
        },
        {
            Header: "Action",
            accessor: "action"
        },
        {
            Header: "App",
            accessor: "app"
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[roleFeatures]);

    return (
        <div className="flex flex-col w-auto">
            {
                <div className="flex relative p-1 w-auto">
                    {authProps.auth.token.features?.map(a => a.id).includes('45kwmn7ujx') &&
                        <Select
                            id="role"
                            placeHolder="Select Role"
                            className="shadow-sm border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            selectList={
                                roles?.map(role => {
                                    return {id: role.role, name: role.role}
                                })}
                            required={true}
                            onChange={e => {
                                e.preventDefault();
                                const params: RoleFeatureParams = {
                                    authProps: authProps,
                                    role: e.target.value,
                                }
                                dispatch(fetchRoleFeatures(params));
                            }} />}
                </div>
            }
            {status === "loading" ? (<div className="pl-2">Loading...</div>) : (
                <React.Fragment>
                    {roleFeatureError &&
                    <span
                        className="text-red-500"
                        style={{ display: roleFeatureError ? "block" : "none" }}>
                        {roleFeatureError}
                    </span>}
                    <div>
                        {roleFeatures &&
                            <React.Fragment>
                                <div className="hidden md:block">
                                    <WrappedTable<RoleFeature>
                                        name="Role Feature"
                                        columns={columns}
                                        data={roleFeatures}
                                        addonHooks={[]}
                                    /> 
                                </div>
                            </React.Fragment>}
                    </div>
                </React.Fragment>)};
        </div>
    );
}

export default RoleFeaturePage;