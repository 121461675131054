import React, { FC, useState } from 'react';
import AuthProps from '../common/AuthProps';
import reportImage from "../../assets/reportimage.jpeg";
import Select from '../common/Select';
import ParamMemberId from './ParamMemberId';
import { enabledButtonClass, grayedOutButtonClass } from '../../shared/Utils';

const ReportPage: FC<AuthProps> = (authProps) => {

    const [reportId, setReportId] = useState<string>("");
    const [memberId, setMemberId] = useState<string>("");
    
    const reports = [
        {'id':'RM003','url':`https://admin.sascosacco.co.ke/memberreports/reports/rm003/${memberId}`,'name':'Member Registration Certificate'},
        {'id':'RM005','url':'https://admin.sascosacco.co.ke/memberreports/reports/rm005','name':'All Members List'},
        {'id':'RM010','url':'https://admin.sascosacco.co.ke/memberreports/reports/rm010','name':'Member Detailed List'},
    ];
    
    /*
    const reports = [
        {'id':'RM003','url':`http://localhost:8725/public/members/reports/RM003/?memberId=${memberId}`,'name':'Member Registration Certificate'},
        {'id':'RM005','url':'http://localhost:8725/public/members/reports/RM005','name':'All Members List'},
        {'id':'RM010','url':'http://localhost:8725/public/members/reports/RM010','name':'Member Detailed List'},
    ]; */

    const isInvalidWithMemberParam =
        reportId === ""
        || (reportId === 'RM003' && memberId === "");

    return (
        <div className="flex items-center min-h-screen bg-gray-50">
            <div className="flex-1 h-full max-w-4xl mx-auto bg-white rounded-lg shadow-xl">

                <div className="flex flex-col md:flex-row">
                    <div className="h-32 md:h-auto md:w-1/2">
                        <img
                            className="object-cover w-full h-full"
                            src={reportImage}
                            alt="reports"
                        />
                    </div>

                    <div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
                        <div className="w-full">
                            <h3 className="mb-4 text-xl font-bold text-blue-600">
                                Reports
                            </h3>
                            <div className="space-y-2">
                                <div>
                                    <Select
                                        id="sectionId"
                                        placeHolder="Select Report"
                                        className="shadow-sm border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        selectList={
                                            reports.map(report => {
                                                return {id: report.id, name: report.name}
                                            })}
                                        required={true}
                                        onChange={e => {
                                            setReportId(e.target.value);
                                        }} />
                                </div>

                                <div>
                                    {reportId &&
                                        <React.Fragment>
                                            {(reportId === "RM003" 
                                                || reportId === "RM010") &&
                                                <ParamMemberId 
                                                    authProps={authProps} 
                                                    setMemberId={setMemberId} />
                                            }
                                            <button
                                                disabled={isInvalidWithMemberParam}
                                                className={isInvalidWithMemberParam ? grayedOutButtonClass
                                                    : enabledButtonClass}
                                                onClick={e => {
                                                    window.open(
                                                        reports.find( ({ id }) => id === reportId).url, 
                                                        '_blank', 
                                                        'noreferrer');
                                                }}>
                                                Print PDF
                                            </button>
                                        </React.Fragment>
                                    }
                                </div>

                            </div>
                                
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
    );
};

export default ReportPage;