import React, { FC, useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { EmailMessage, fetchEmailMessages, selectEmailMessageError, selectEmailMessages, selectEmailMessageStatus,
    clearError, deleteEmailMessage, EmailMessageParams } from '../../store/emailMessageSlice';
import AuthProps from '../common/AuthProps';
import { Column } from 'react-table';
import { WrappedTable } from '../common/Table/WrappedTable';
import AddEditEmailMessage from './AddEditEmailMessage';

const EmailMessagePage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const emailMessages = useAppSelector(selectEmailMessages);
    const status = useAppSelector(selectEmailMessageStatus);
    const emailMessageError = useAppSelector(selectEmailMessageError);

    const [emailMessage, setEmailMessage] = useState<EmailMessage>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchEmailMessages(authProps));
        return () => controller.abort();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setEmailMessage(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, emailMessage: EmailMessage) => {
    	e.preventDefault();
        dispatch(clearError(null));
    	setEmailMessage(emailMessage);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, emailMessage: EmailMessage) => {
		e.preventDefault();
        dispatch(clearError(null));
    	setEmailMessage(emailMessage);
        console.log(`emailMessage ${JSON.stringify(emailMessage)}`);
    	const params: EmailMessageParams = {
        	authProps: authProps,
        	emailMessage: emailMessage,
      	}
    	dispatch(deleteEmailMessage(params))
 	};

    const columns: Column<EmailMessage>[] = useMemo(() => [
        {
          Header: "Address",
          accessor: "emailAddress"
        },
        {
            Header: "Subject",
            accessor: "subject"
        },
        {
            Header: "Message",
            accessor: "message"
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div className="items-center margin: auto">
                      <button
                          onClick={e => inLineEdit(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg" 
                              className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                      </button>
                  </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                      <button
                          onClick={e => inLineDelete(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5" viewBox="0 0 18 18" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                          </svg>
                      </button>
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[emailMessages]);

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
            <React.Fragment>
                {emailMessageError &&
                <span
                    className="text-red-500"
                    style={{ display: emailMessageError ? "block" : "none" }}>
                    {emailMessageError}
                </span>}
                <div>
                    {emailMessages &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<EmailMessage>
                                    name="Messages"
                                    columns={columns}
                                    data={emailMessages}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    customAddText="Compose"
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('62nk0prvm1')}
                                /> 
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<EmailMessage>
                                    name="Messages"
                                    columns={columns}
                                    data={emailMessages}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    customAddText="Compose"
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('62nk0prvm1')}
                                />
                            </div>
                        </React.Fragment>}
                </div>
                <div>
                    {showAddEdit &&
                        <AddEditEmailMessage 
                            authProps={authProps} 
                            setShowAddEdit={setShowAddEdit}
                            emailMessage={emailMessage}
                        />}
                </div>
            </React.Fragment>
    );
}

export default EmailMessagePage;