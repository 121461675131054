import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type ContentBodyParams = {
    authProps: AuthProps;
    contentBody?: ContentBody;
    contentHeadingId?: string;
}

export type ContentBody = {
    id: string;
    contentHeadingId: string;
    body: string;
    priority: any;
    showPublic: boolean;
    showPrivate: boolean;
    creationDate?: string;
    modifiedDate?: string;
    edit?: void;
    delete?: void;
}

interface ContentBodyState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: ContentBody[];
}

interface FetchErrors {
    message: string;
}

const initialState: ContentBodyState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchContentBodies = 
    createAsyncThunk<ContentBody[], ContentBodyParams, { rejectValue: FetchErrors }>(
    "contentBody/fetchByHeadingId",
    async (params: ContentBodyParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.CONTENT_BODY_URL +
                "/" + params.contentHeadingId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch content body",
            });
        }
        return response.json();
    }
);

export const addContentBody =
    createAsyncThunk<ContentBody, ContentBodyParams, { rejectValue: FetchErrors }>(
    "contentBody/add",
    async (params: ContentBodyParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.CONTENT_BODY_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.contentBody),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editContentBody =
    createAsyncThunk<ContentBody, ContentBodyParams, { rejectValue: FetchErrors }>(
    "contentBody/edit",
    async (params: ContentBodyParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.CONTENT_BODY_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.contentBody),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteContentBody =
    createAsyncThunk<ContentBody, ContentBodyParams, { rejectValue: FetchErrors }>(
    "contentBody/delete",
    async (params: ContentBodyParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.CONTENT_BODY_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.contentBody),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const contentBodySlice = createSlice({
    name: 'contentBody',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchContentBodies.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addContentBody.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editContentBody.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteContentBody.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchContentBodies.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addContentBody.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editContentBody.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteContentBody.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchContentBodies.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addContentBody.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editContentBody.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteContentBody.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectContentBodyStatus = (state: RootState) => state.contentBody.status;
export const selectContentBodies = (state: RootState) => state.contentBody.list;
export const selectContentBodyError = (state: RootState) => state.contentBody.error;
export const { clearList, clearError } = contentBodySlice.actions;
export default contentBodySlice.reducer;