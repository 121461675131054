import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { Member } from './memberSlice';
import { TransactionCode } from './transactionCodeSlice';

export type LoanStatementParams = {
    authProps: AuthProps;
    loanStatement?: LoanStatement;
}

export type LoanStatement = {
    id: string;
    member: Member;
    date: string;
    transactionCodeId: string;
    transactionCode: TransactionCode;
    interestRate: number;
    amount: number;
    calculatedInterest: number;
    balance: number;
    interestDays: number;
    transactionClosing: number;
    accruedInterest: number;
}

interface LoanStatementState {
    status: "loading" | "idle";
    error: any;
    list: LoanStatement[];
}

interface FetchErrors {
    message: string;
}

const initialState: LoanStatementState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchLoanStatements = 
    createAsyncThunk<LoanStatement[], AuthProps, { rejectValue: FetchErrors }>(
    "loanStatements/fetchByAccount",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.LOANSTATEMENTS_URL +
                "/" + params.config.ACCOUNT_ID, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch loan statements",
            });
        }
        return response.json();
    }
);

export const loanStatementSlice = createSlice({
    name: 'loanStatement',
    initialState,
    reducers: {
        clearLoanStatementList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearLoanStatementError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchLoanStatements.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchLoanStatements.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchLoanStatements.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectLoanStatementStatus = (state: RootState) => state.loanStatements.status;
export const selectLoanStatements = (state: RootState) => state.loanStatements.list;
export const selectLoanStatementError = (state: RootState) => state.loanStatements.error;
export const { clearLoanStatementList, clearLoanStatementError } = loanStatementSlice.actions;
export default loanStatementSlice.reducer;