import React, { FC, useState } from 'react';
import AuthProps from '../../common/AuthProps';

const SavingsAccountsMain: FC<AuthProps> = (authProps) => {

    const [openTab, setOpenTab] = useState(1);

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <ul
                        className="flex mb-3 list-none flex-wrap pt-1 pb-2 flex-row"
                        role="tablist">
                        {authProps.auth.token.features?.map(a => a.id).includes('j8465r3qzn') &&
                            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                                
                                <a className={
                                    "text-xs font-bold px-5 py-2 shadow-sm rounded-xs block leading-normal " +
                                    (openTab === 1
                                        ? "text-grey-300 bg-[#fff0cc]"
                                        : "text-[#850000] bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(1);
                                    }}
                                    data-toggle="tab"
                                    href="#link1"
                                    role="tablist"
                                >
                                    Non-Interest Earning
                                </a>
                            </li>}
                        {authProps.auth.token.features?.map(a => a.id).includes('j8465r3qzn') &&
                            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                                
                                <a className={
                                    "text-xs font-bold px-5 py-2 shadow-sm rounded-xs block leading-normal " +
                                    (openTab === 2
                                        ? "text-grey-300 bg-[#fff0cc]"
                                        : "text-[#850000] bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(2);
                                    }}
                                    data-toggle="tab"
                                    href="#link2"
                                    role="tablist"
                                >
                                    Interest Earning
                                </a>
                            </li>}
                        {authProps.auth.token.features?.map(a => a.id).includes('j8465r3qzn') &&
                            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center h-5">
                                
                                <a className={
                                    "text-xs font-bold px-5 py-2 shadow-sm rounded-xs block leading-normal " +
                                    (openTab === 3
                                        ? "text-grey-300 bg-[#fff0cc]"
                                        : "text-[#850000] bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(3);
                                    }}
                                    data-toggle="tab"
                                    href="#link3"
                                    role="tablist"
                                >
                                    Vikundi Savings Wallet
                                </a>
                            </li>}
                            
                    </ul>
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-sm">
                        <div className="px-0 py-0 flex-auto">
                            <div className="tab-content tab-space">
                                <div className={openTab === 1
                                    && authProps.auth.token.features?.map(a => a.id).includes('j8465r3qzn') ? "block" : "hidden"} id="link1">
                                   
                                </div>
                                <div className={openTab === 2
                                    && authProps.auth.token.features?.map(a => a.id).includes('gjw64rp3u5') ? "block" : "hidden"} id="link2">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default SavingsAccountsMain;