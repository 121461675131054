import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { LoanStatus } from './loanStatusSlice';

export type LoanStatusReasonParams = {
    authProps: AuthProps;
    loanStatusReason?: string;
    loanStatusId?: string;
}

export type LoanStatusReason = {
    id: string;
    loanStatusId: string;
    loanStatus?: LoanStatus; 
    reason: string;
}

interface LoanStatusReasonState {
    status: "loading" | "idle";
    error: string | null;
    list: LoanStatusReason[];
}

interface FetchErrors {
    message: string;
}

const initialState: LoanStatusReasonState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchLoanStatusReasons = 
    createAsyncThunk<LoanStatusReason[], LoanStatusReasonParams, { rejectValue: FetchErrors }>(
    "loanStatusReasons/fetchByLoanStatus",
    async (params: LoanStatusReasonParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.LOANS_URL +
                "/4/loanstatusreasons/" + params.loanStatusId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch loan status reasons",
            });
        }
        return response.json();
    }
)

export const loanStatusReasonSlice = createSlice({
    name: 'loanStatusReason',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchLoanStatusReasons.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchLoanStatusReasons.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchLoanStatusReasons.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectLoanStatusReasonStatus = (state: RootState) => state.loanStatusReasons.status;
export const selectLoanStatusReasons = (state: RootState) => state.loanStatusReasons.list;
export const selectLoanStatusReasonError = (state: RootState) => state.loanStatusReasons.error;
export const { clearError } = loanStatusReasonSlice.actions;
export default loanStatusReasonSlice.reducer;