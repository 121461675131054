import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { MemberLoanTransaction } from './memberLoanTransactionSlice';
import { TransactionCode } from './transactionCodeSlice';

export type MemberLoanEntryParams = {
    authProps: AuthProps;
    memberLoanEntry?: MemberLoanEntry;
    transactionPeriodId?: string;
    transactionType?: string;
}

export type User = {
    id: string;
    userName: string;
}

export type MemberLoanEntry = {
    id?: string;
    date: string;
    ref?: string;
    transactionCodeId: string;
    transactionCode?: TransactionCode;
    reportingPeriodId?: string;
    makerId: string;
    memo?: string;
    accountId: string;
    creationDate?: string;
    transactions: MemberLoanTransaction[];
    total?: number;
    edit?: void;
    delete?: void;
    status?: void;
}

interface MemberLoanEntryState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: MemberLoanEntry[];
}

interface FetchErrors {
    message: string;
}

const initialState: MemberLoanEntryState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchCurrentSavedEntriesByMaker = 
    createAsyncThunk<MemberLoanEntry[], AuthProps, { rejectValue: FetchErrors }>(
    "memberLoanEntries/fetchSavedByMaker",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.MEMBERLOAN_ENTRIES_URL +
                "/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch current saved member loan entries",
            });
        }
        return response.json();
    }
);

export const addMemberLoanEntry =
    createAsyncThunk<MemberLoanEntry, MemberLoanEntryParams, { rejectValue: FetchErrors }>(
    "memberLoanEntry/add",
    async (params: MemberLoanEntryParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MEMBERLOAN_ENTRIES_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberLoanEntry),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editMemberLoanEntry =
    createAsyncThunk<MemberLoanEntry, MemberLoanEntryParams, { rejectValue: FetchErrors }>(
    "memberLoanEntry/edit",
    async (params: MemberLoanEntryParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MEMBERLOAN_ENTRIES_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberLoanEntry),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteMemberLoanEntry =
    createAsyncThunk<MemberLoanEntry, MemberLoanEntryParams, { rejectValue: FetchErrors }>(
    "memberLoanEntry/delete",
    async (params: MemberLoanEntryParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MEMBERLOAN_ENTRIES_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberLoanEntry),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const memberLoanEntrySlice = createSlice({
    name: 'memberLoanEntry',
    initialState,
    reducers: {
        clearMemberLoanEntryList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearMemberLoanEntryError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchCurrentSavedEntriesByMaker.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addMemberLoanEntry.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editMemberLoanEntry.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteMemberLoanEntry.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchCurrentSavedEntriesByMaker.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addMemberLoanEntry.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editMemberLoanEntry.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteMemberLoanEntry.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchCurrentSavedEntriesByMaker.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addMemberLoanEntry.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editMemberLoanEntry.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteMemberLoanEntry.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectMemberLoanEntryStatus = (state: RootState) => state.memberLoanEntries.status;
export const selectMemberLoanEntries = (state: RootState) => state.memberLoanEntries.list;
export const selectMemberLoanEntryError = (state: RootState) => state.memberLoanEntries.error;
export const { clearMemberLoanEntryList, clearMemberLoanEntryError } = memberLoanEntrySlice.actions;
export default memberLoanEntrySlice.reducer;