import React, { FC, SetStateAction } from 'react';
import Select from '../../common/Select';

interface Props {
    setRepaymentPeriod: React.Dispatch<SetStateAction<number>>;
    repaymentPeriod: number;
    setRepaymentPeriodError: React.Dispatch<SetStateAction<string>>;
    repaymentPeriodError: string;
}

const RepaymentPeriod: FC<Props> = ({ setRepaymentPeriod, repaymentPeriod, setRepaymentPeriodError,
    repaymentPeriodError }) => {

    const selectNumberMonths: any = () => {
        let list = [];
        for (let i = 0; i <= 36; i++) {
            list.push(i);
        }
        return list;
    }

    return (
        <div className="grid grid-cols-3 w-full mb-1">
            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="nationalId">
                Months:
            </label>

            <div className="col-span-2">
                <Select
                    id="repaymentPeriod"
                    placeHolder="Select repayment period"
                    value={repaymentPeriod.toString()}
                    className="shadow-md border rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    selectList={
                        selectNumberMonths().map(month => {
                            return {id: month, name: month}
                        })}
                    required={true}
                    onChange={e => {
                        setRepaymentPeriodError("");
                        setRepaymentPeriod(Number(e.target.value));
                    }} />
                <span
                    className="text-xs text-red-700"
                    style={{ display: repaymentPeriodError ? "block" : "none" }}>
                    {repaymentPeriodError}
                </span>
            </div>
        </div>
    );

};

export default RepaymentPeriod;

