import React, { FC, SetStateAction, useEffect, useState } from 'react';
import AuthProps from '../AuthProps';
import { useAppSelector, useAppDispatch } from '../hooks';
import Select from '../../common/Select';
import { selectTransactionCodeTypes, fetchTransactionCodeTypes,
    TransactionCodeTypeParams } from '../../../store/transactionCodeTypeSlice';
import { TransactionCodeParams, fetchTransactionCodesByBookAndType } from '../../../store/transactionCodeSlice';


interface Props {
    authProps: AuthProps;
    book: string;
    setTransactionCodeTypeId: React.Dispatch<SetStateAction<string>>;
    transactionCodeTypeId: string;
    loadTransactionCodes?: boolean;
}

const TransactionCodeType: FC<Props> = ({ authProps, book,
    setTransactionCodeTypeId, transactionCodeTypeId, loadTransactionCodes }) => {

    const dispatch = useAppDispatch();

    const preSelectTransactionCodeType = [{'id':'1111111111','name':'Select type'}];
    const transactionCodeTypes = useAppSelector(selectTransactionCodeTypes);

    useEffect(() => {
        const controller = new AbortController();
        const params: TransactionCodeTypeParams = {
            authProps: authProps,
            book: book,
        }
        dispatch(fetchTransactionCodeTypes(params));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div className="grid grid-cols-3 w-full">

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="transactionCodeTypeId">
                Type
            </label>
            
            <div className="col-span-2">
                <Select
                    id="transactionCodeTypeId"
                    className="p-1"
                    placeHolder={'Select'}
                    value={transactionCodeTypeId}
                    selectList={
                        preSelectTransactionCodeType.map(select => {
                            return {id: select.id, name: select.name}
                        }).concat(
                            transactionCodeTypes.map(transactionCodeType => {
                                return { id: transactionCodeType.id, name: transactionCodeType.name}
                    }))}
                    required={true}
                    onChange={e => {
                        setTransactionCodeTypeId(e.target.value);
                        if (loadTransactionCodes) {
                            const params: TransactionCodeParams = {
                                authProps: authProps,
                                book: book,
                                transactionCodeTypeId: e.target.value,
                            };
                            dispatch(fetchTransactionCodesByBookAndType(params));
                        }
                    }} />
            </div>

        </div>
    );
}

export default TransactionCodeType;